
const router = require('@/router');
const store = require('@/store');
const axios = require('axios');
const DEBUG_ENABLED = process.env.VUE_APP_DEBUG_MODE;

const myRound = function (value, num) {
    var str = value.toString();
    var array = str.split(".");
    var decimal = array[1];
    var len = decimal.length;
    if (parseInt(num) >= len) {
        return value;
    } else {
        var ctrl = 0;
        for (var i = parseInt(num); i < len; i++) {
            if (parseInt(decimal.charAt(i)) < 5) {
                ctrl = -1;
                break;
            } else if (parseInt(decimal.charAt(i)) > 5) {
                ctrl = 1;
                break;
            }
        }
        var x = "";
        for (var i = 0; i < parseInt(num); i++) {
            x += decimal.charAt(i);
        }

        var y = parseFloat("0." + x);
        if (ctrl === 1) {
            y += Math.pow(10, num * -1);
        }
        //return parseFloat(array[0]) + parseFloat(y);
        return parseFloat(array[0] + "." + x);
    }
};

const formatPrice = function (value) {
    if (value !== undefined) {
        var prov = value;
        var str = checkPriceLength(prov);
        if (str === "LengthError") {
            var provStr = myRound(prov, 2);
            str = checkPriceLength(provStr);
        }
        return str;
    }
    return "-";
};

const checkPriceLength = function (value) {
    var array = value.toString().split(".");
    var len = array.length;
    var str = "";
    if (len === 1) {
        str += array[0] + ".00";
    } else if (array[1].length === 1) {
        str += array[0] + "." + array[1] + "0";
    } else if (array[1].length === 2) {
        str += array[0] + "." + array[1];
    } else if (array[1].length > 2) {
        str += "LengthError";
    }
    return str;
};

const loadScript = async (url) => {
    return await new Promise((resolve, reject) => {
        let script = document.createElement("script");

        script.onload = () => resolve(script);
        script.onerror = () => {
            if (url && url !== undefined) reject(new Error(`Errore di caricamento dello script per: ${url}`));
            else reject(new Error(`Errore di caricamento dello script`));

        }
        script.src = url;
        document.getElementsByTagName("head")[0].appendChild(script);
    });
};

const errorPush = (store, errorType, params) => {
    let error = {
        error_label: "errors." + errorType + ".label",
        error_desc: "errors." + errorType + ".errorDesc",
        error_count: 1,
        error_code: "errors." + errorType + ".errorCode",
        error_params: (params && params !== undefined && params !== '') ? JSON.parse(params) : ''
    };
    let errors = store.getters.getErrors;
    if (errors.length == 0) store.dispatch('setError', error);
    else {
        let status = false;
        errors.forEach((el, index) => {
            if (el.error_desc == error.error_desc) {
                errors[index].error_count += 1;
                status = !status;
            }
        });
        if (!status) store.dispatch('setError', error);
    }
}

const loaderPresent = (message, store) => {
    if (message && message !== '') store.dispatch('changeLoaderText', message)
    store.dispatch('loaderPresent')
}

const loaderDismiss = (store) => {
    store.dispatch('changeLoaderText', 'root.spinnerText')
    store.dispatch('loaderDismiss')
}

const unrollParams = (params, queryFormat = false) => {
    const firstSimbol = (queryFormat) ? '?' : '/';
    const separator = (queryFormat) ? '&' : '/';
    let out = "";
    for (const key in params) {
        if (Object.hasOwnProperty.call(params, key)) {
            let el = params[key];
            if (Array.isArray(el)) el = JSON.stringify(el);
            if (queryFormat) out += `${key}=`;
            out += `${el}${separator}`;
        }
    }
    if (out === '') return out;
    out += '@#67527hvfsdvf@'
    out = out.replace(`${separator}@#67527hvfsdvf@`, "")
    return (firstSimbol + out);
}

const getRequest = async (path, params, responseCB, errorCB, options = {}, showNotification = false) => {
    return await ajaxRequest(path, null, params, 'GET', responseCB, errorCB, options, showNotification);
};
const postRequest = async (path, body, responseCB, errorCB, options, showNotification = false) => {
    return await ajaxRequest(path, body, {}, 'POST', responseCB, errorCB, options, showNotification);
};
const putRequest = async (path, params, body, responseCB, errorCB, options, showNotification = false) => {
    return await ajaxRequest(path, body, params, 'PUT', responseCB, errorCB, options, showNotification);
};
const patchRequest = async (path, params, body, responseCB, errorCB, options, showNotification = false) => {
    return await ajaxRequest(path, body, params, 'PATCH', responseCB, errorCB, options, showNotification);
};
const deleteRequest = async (path, params, responseCB, errorCB, options, showNotification = false) => {
    return await ajaxRequest(path, null, params, 'DELETE', responseCB, errorCB, options, showNotification);
};

const ajaxRequestAux = async (method, isLastSlash, path, params, body, options) => {
    let res;
    switch (method) {
        case 'GET':
            res = await axios.get((isLastSlash ? path.slice(0, -1) : path) + unrollParams(params), options);
            break;
        case 'POST':
            res = await axios.post(path, body, options);
            break;
        case 'PUT':
            res = await axios.put((isLastSlash ? path.slice(0, -1) : path) + unrollParams(params), body, options);
            break;
        case 'PATCH':
            res = await axios.patch((isLastSlash ? path.slice(0, -1) : path) + unrollParams(params), body, options);
            break;
        case 'DELETE':
            res = await axios.delete((isLastSlash ? path.slice(0, -1) : path) + unrollParams(params), options);
            break;
        default:
            break;
    }
    return res;
}

const ajaxRequest = async (path, body, params, method = 'POST', responseCB, errorCB, options, showNotification = false) => {
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    try {
        let res;
        let loginData = store.default.getters.userSession;
        if (!options) options = {}
        if (options?.headers?.authorization === undefined) {
            if (loginData.accessToken !== '') {
                if (!options.headers) options.headers = {};
                options.headers.authorization = `Bearer ${loginData.accessToken}`;
            } else {
                store.default.commit("restoreUserSession");
                let loginData = store.default.getters.userSession;
                if (loginData.accessToken !== '') {
                    if (!options.headers) options.headers = {};
                    options.headers.authorization = `Bearer ${loginData.accessToken}`;
                }
            }
        }
        const isLastSlash = path.charAt(path.length - 1) === '/';

        res = await ajaxRequestAux(method, isLastSlash, path, params, body, options);
        let obj = extractObj(res);
        if (obj.error_code === 'S401') {
            /* EXPIRED TOKEN MANAGEMENT */
            if (loginData && loginData.refreshToken && loginData.refreshToken !== '') {
                const refreshToken = loginData.refreshToken;

                if (!options.headers) options.headers = {};
                options.headers.Authorization = `Bearer ${refreshToken}`;
                let res;
                try {
                    res = await axios.patch(`${process.env.VUE_APP_SERVER_API_PATH}refresh-token`, {}, options);
                    obj = res.data;
                    if (!parseInt(obj.error_code)) {
                        store.default.commit("setUserSession", { ...obj.response });

                        loginData = store.default.getters.userSession;
                        const accessToken = { loginData }
                        options.headers.Authorization = `Bearer ${accessToken}`;
                        res = await ajaxRequestAux(method, isLastSlash, path, params, body, options);
                    } else if (parseInt(obj.error_code) === 401) router.default.replace({ name: "Login" });


                    if (showNotification) showSuccessLog("Operation Succeded");
                    return responseCB(await getObjResponse(res, showNotification));
                } catch (error) {
                    console.error("Got an error", error)
                    if (DEBUG_ENABLED) console.error("error_debug", reply);
                    if (showNotification) {
                        if (error.error_type === "warning") showWarningLog(error.error_desc)
                        else showErrorLog(error.error_desc);
                    }
                }
            }
        } else {
            return responseCB(await getObjResponse(res, showNotification));
        }


    } catch (err) {
        return errorCB(err)
    }
}

const extractObj = (evt) => {
    let toParse = true;
    let reply;
    let obj;
    if (evt.target !== undefined && evt.target.responseText !== undefined) {
        reply = evt.target.responseText;
        if (DEBUG_ENABLED) console.log("first debug: ", reply)
    } else {
        toParse = false;
    }

    try {
        if (toParse) {
            obj = JSON.parse(reply).data;
        } else {
            obj = evt.data;
        }
        if (DEBUG_ENABLED) console.log("debug", obj);
    } catch (error) {
        if (DEBUG_ENABLED) console.error("error_debug", reply);
    }
    return obj;
}

const getObjResponse = async (evt, showNotification = false) => {
    const obj = extractObj(evt);
    switch (obj.error_code) {

        case "401":
            if (obj.response.error_desc === "Unauthorized. Cannot use the token on current device" || obj.response.error_desc === "Unauthorized. Invalid Token") {
                store.default.dispatch('localLogout');
            } else {
            }
            if (router.default?.currentRoute?.value?.name && router.default.currentRoute.value.name !== 'Login')
                router.default.replace({ name: "Login", locale: router.default?.currentRoute?.value?.params?.locale });
            return false;
        case "S401":
            return obj.response;
        case 0:
            if (showNotification) {
                if (!obj.response) {
                    showWarningLog("Ops! Something went wrong");
                    if (obj.error_type !== undefined) {
                        if (obj.error_type === "warning") showWarningLog(obj.error_desc)
                        else showErrorLog(obj.error_desc);
                    }
                }
                else showSuccessLog("Operation Succeded");
            };
            return obj.response;

        default:
            if (showNotification) {
                const error = obj.response;
                if (error.error_type === "warning") showWarningLog(error.error_desc)
                else showErrorLog(error.error_desc);
            }
            break;
    }
    return false;
}

const autoSetColorMode = (store) => {

    var colorMode = localStorage.getItem("colorMode") || null;
    if (colorMode === null) {
        colorMode = store.getters.getColorMode;
        if (colorMode === null) {
            const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)");

            if (prefersDarkScheme.matches) {
                colorMode = 'dark'
            } else {
                colorMode = 'light'
            }
        }
        localStorage.setItem("colorMode", colorMode)
    } else {
        store.dispatch("setColorMode", colorMode)
    }

    if (colorMode === 'dark') {
        document.documentElement.classList.remove("transition-all");
        document.documentElement.classList.add("transition-all");
        document.documentElement.classList.add("dark");
    } else {
        document.documentElement.classList.remove("dark");
    }
}

const setColorMode = (store, colorMode) => {
    localStorage.setItem("colorMode", colorMode)
    store.dispatch("setColorMode", colorMode)

    if (colorMode === 'dark') {
        document.documentElement.classList.remove("transition-all");
        document.documentElement.classList.add("transition-all");
        document.documentElement.classList.add("dark");
    } else {
        document.documentElement.classList.remove("dark");
    }
}

const validate = (obj, validationModel, debug = false) => {
    if (debug) console.log('Obj: ', obj)
    const out = {};
    if (typeof validationModel !== 'object') return {};
    const keys = Object.keys(validationModel);
    if (!keys.length) return {};
    for (const key in validationModel) {
        if (Object.hasOwnProperty.call(validationModel, key)) {
            const element = validationModel[key];
            const inputElmt = obj[key];
            const elType = typeof element.type();
            if (inputElmt === undefined && element.required) throw {
                error: `A required prop is missing (${key})`
            };
            else if (inputElmt !== undefined && elType !== typeof inputElmt) throw {
                error: `A wrong prop type is been passed - ${key} should be of type ${elType} (${typeof inputElmt} passed)`
            };
            else if (inputElmt === undefined && ((!!element.default && elType !== 'boolean') || (element.default !== undefined && elType === 'boolean'))) {
                out[key] = element.default;
                obj[key] = element.default;
            }
            else if (inputElmt !== undefined) {
                out[key] = inputElmt;
                obj[key] = inputElmt;
            } else {
                if (debug) console.log("Shouldn't be here", key, element, inputElmt, element.default, !!element.default)
            }
        }
    }
    if (debug) console.log(out)
    return out;
}
const capitalize = (text) => {
    return text
        .replace("_", " ")
        .replace("-", " ")
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
};
const randomIntFromInterval = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min)
}

const setSystemNotificationType = (type) => {
    store.default.dispatch("setSystemNotificationType", type);
};
const setSystemNotificationMessage = (msg) => {
    store.default.dispatch("setSystemNotificationMessage", msg);
};
const showSystemNotification = () => {
    store.default.dispatch("setSystemNotificationActiveStatus", true);
};

const showSuccessLog = (msg) => {
    setSystemNotificationType("success");
    setSystemNotificationMessage(msg);
    showSystemNotification();
};
const showWarningLog = (msg) => {
    setSystemNotificationType("warning");
    setSystemNotificationMessage(msg);
    showSystemNotification();
};
const showErrorLog = (msg) => {
    setSystemNotificationType("error");
    setSystemNotificationMessage(msg);
    showSystemNotification();
};

const extractFieldsList = (config) => {
    const innerConfig = (config.__v_isRef !== undefined && config.__v_isRef) ? config.value : config;
    return innerConfig.map((el) => el.ref);
};

const changeRegex = (ref, regex) => {
    const input = document.querySelector(`[data-ref="${ref}"]`);
    input.dataset.regex = regex;
    input.regex = regex;
}
const checkFields = (fieldsList, errorLists) => {
    const inputs = document.getElementsByTagName("input");
    for (const input of inputs) {
        if (input.attributes["data-ref"] === undefined) return true
        let dataRef = "" + input.attributes["data-ref"].value;
        if (fieldsList.includes(dataRef)) {
            if (input.attributes["data-regex"] === undefined) return true
            const dataRegExArray =
                input.attributes["data-regex"].value.split("/gm");
            const dataRegEx = (
                dataRegExArray.length === 2
                    ? dataRegExArray[0]
                    : arr.slice(0, -1).join("/gm")
            ).slice(1);
            const val = input.value;
            const myRegExp = new RegExp(dataRegEx, "gm");
            const test = myRegExp.test(val);
            if (!test) {
                if (!errorLists[0].value.includes(dataRef)) {
                    errorLists[0].value.push(dataRef);
                }
            }
        }
    }
    let check = true;
    errorLists.forEach((errorList) => {
        check = check && !errorList.value.length;
    });
    return check;
}

const dateToLocale = (date) => {
    return new Date(date).toLocaleString().replace(',', '')
}

async function encodeHash256(message) {
    const encoder = new TextEncoder();
    const data = encoder.encode(message);
    const hashBuffer = await crypto.subtle.digest("SHA-256", data);

    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
        .map((byte) => byte.toString(16).padStart(2, "0"))
        .join("");

    return hashHex;
}

async function encodeBase64(data) {
    const encoder = new TextEncoder();
    const dataArrayBuffer = encoder.encode(data);
    const base64String = btoa(String.fromCharCode(...new Uint8Array(dataArrayBuffer)));
    return base64String;
}

async function decodeBase64(base64String) {
    const decodedString = atob(base64String);
    const decoder = new TextDecoder();
    const decodedData = decoder.decode(new Uint8Array([...decodedString].map(char => char.charCodeAt(0))));
    return decodedData;
}

module.exports = {
    myRound,
    formatPrice,
    checkPriceLength,
    loadScript,
    errorPush,
    loaderPresent,
    loaderDismiss,
    getObjResponse,
    autoSetColorMode,
    setColorMode,
    randomIntFromInterval,
    getRequest,
    postRequest,
    putRequest,
    patchRequest,
    deleteRequest,
    validate,
    capitalize,
    showSuccessLog,
    showWarningLog,
    showErrorLog,
    extractFieldsList,
    checkFields,
    changeRegex,
    dateToLocale,
    encodeHash256,
    encodeBase64,
    decodeBase64,
}