<template>
  <div>
    <label
      v-if="hl && textareaData.label"
      :for="textareaData.id || textareaData.name || null"
      >{{ textareaData.label }}</label
    >
    <textarea
      :id="textareaData.id"
      :name="textareaData.name"
      :rows="config.rows"
      class="form-textarea"
      :placeholder="textareaData.placeholder"
      :required="config.required"
      v-model="innerValue"
    ></textarea>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { validate } from "@/modules/routines.cjs";
export default {
  props: {
    config: {
      type: Object,
      validator(v) {
        const validationObj = {
          hasLabel: {
            type: Boolean,
            default: true,
          },
          required: {
            type: Boolean,
            default: false,
          },
          rows: {
            type: Number,
            default: 3,
          },
        };
        return validate(v, validationObj);
      },
      default: () => {},
    },
    textareaData: {
      type: Object,
      validator(v) {
        const validationObj = {
          id: { type: String, required: false },
          name: { type: String },
          label: { type: String, default: "Textarea" },
          placeholder: { type: String, default: "Type your text here..." },
        };
        return validate(v, validationObj);
      },
      default: () => {},
    },
    value: { type: String, default: "" },
  },

  setup(props, ctx) {
    const hl = computed(() => props.config.hasLabel ?? true);
    const emit = ctx.emit;
    const innerValue = computed({
      get() {
        return props.value || "";
      },
      set(value) {
        emit("update:value", value);
      },
    });
    const val = ref(null);
    return { hl, innerValue, val };
  },
};
</script>

<style>
</style>