
export default {
    setLogs(state, payload) {
        state.logs = payload.logs
    },
    setSystemLog(state, id) {
        if (id === '') state.log = {};
        else state.systemLog = state.logs.system.filter(log => {
            return log.id === id
        })[0]
    },
    setLog(state, id) {
        if (id === '') state.log = {};
        else state.log = state.logs.labkey.filter(log => {
            return log.id === id
        })[0]
    },
}