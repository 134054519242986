<template>
  <div class="text-black dark:text-white-dark">
    <!-- screen loader -->
    <MainLoader />
    <SystemNotification />

    <div class="fixed bottom-6 ltr:right-6 rtl:left-6 z-50">
      <template v-if="showTopButton">
        <button
          type="button"
          class="btn btn-outline-primary rounded-full p-2 animate-pulse bg-[#fafafa] dark:bg-[#060818] dark:hover:bg-primary"
          @click="goToTop"
        >
          <svg
            width="24"
            height="24"
            class="h-4 w-4"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.5"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 20.75C12.4142 20.75 12.75 20.4142 12.75 20L12.75 10.75L11.25 10.75L11.25 20C11.25 20.4142 11.5858 20.75 12 20.75Z"
              fill="currentColor"
            />
            <path
              d="M6.00002 10.75C5.69667 10.75 5.4232 10.5673 5.30711 10.287C5.19103 10.0068 5.25519 9.68417 5.46969 9.46967L11.4697 3.46967C11.6103 3.32902 11.8011 3.25 12 3.25C12.1989 3.25 12.3897 3.32902 12.5304 3.46967L18.5304 9.46967C18.7449 9.68417 18.809 10.0068 18.6929 10.287C18.5768 10.5673 18.3034 10.75 18 10.75L6.00002 10.75Z"
              fill="currentColor"
            />
          </svg>
        </button>
      </template>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import { ref, onMounted, computed } from "vue";
import store from "@/store";
import SystemNotification from "@/components/baseComponents/SystemNotification.vue";

export default {
  components: {
    SystemNotification,
  },
  setup() {
    const showTopButton = ref(false);
    onMounted(() => {
      window.onscroll = () => {
        if (
          document.body.scrollTop > 50 ||
          document.documentElement.scrollTop > 50
        ) {
          showTopButton.value = true;
        } else {
          showTopButton.value = false;
        }
      };

      store.dispatch("toggleLoader");
    });

    const goToTop = () => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    };
    return {
      showTopButton,
      goToTop,
    };
  },
};
</script>
