import getters from "./getters"
import actions from "./actions"
import mutations from "./mutations"
export default {
  state: {
    plans: [],
    services: [],
    transactions: [],
    plan: {},
    transaction: {},
    service: {},
    transactionStatuses: [],
  },
  getters,
  mutations,
  actions,
}