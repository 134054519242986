<template>
  <div class="mb-5">
    <ol :class="olClass">
      <li
        v-for="(pathObj, index) in calculatePath"
        :key="pathObj.completePath"
        :class="[
          liClass(index, isSelected(pathObj.completePath)),
          liColorClass(index, isSelected(pathObj.completePath)),
        ]"
      >
        <a
          :href="pathObj.completePath"
          :class="[
            aClass(index, pathObj.completePath, pathObj.clickable),
            aColorClass(pathObj.completePath),
            'text-xs md:text-sm sm:whitespace-nowrap sm:text-ellipsis ',
          ]"
          >{{ capitalize(pathObj.name) }}</a
        >
      </li>
    </ol>
  </div>
</template>
<script>
import store from "@/store";
import router from "@/router";
import { capitalize } from "@/modules/routines.cjs";
import { ColorClasses } from "@/components/ColorClasses";
import {
  backgroundClasses,
  textClasses,
  borderClasses,
  text70Classes,
  addClass,
} from "@/components/CompoundClasses.js";
import { computed, ref, watch } from "vue";

export default {
  setup() {
    const preparePathObjectAux = (
      completePath,
      params,
      routes,
      hasLocale = true,
      isHome = false,
      locale = null
    ) => {
      let basePath = hasLocale ? "/:locale/" : "/";
      if (isHome) {
        return {
          clickable: true,
          hasIcon: true,
          path: hasLocale ? routes["/:locale"].path : routes["/"].path,
          completePath,
          name: "home",
          isParam: false,
        };
      } else {
        let name = "";
        let isParam = false;
        const keys = Object.keys(routes);
        let clickable = false;

        let pathToSplit = completePath;
        if (completePath.charAt(0) === "/") {
          pathToSplit = completePath.slice(1);
        }
        basePath += pathToSplit.split("/").splice(1).join("/");
        if (keys.includes(basePath)) {
          clickable = true;
        } else {
          for (const key in params) {
            if (Object.hasOwnProperty.call(params, key)) {
              const param = params[key];
              const paramCount = completePath.split(`/${param}`).length - 1;
              if (paramCount === 1) {
                isParam = completePath.split(`/${param}`)[1] === "";
                const newPathToCheck = basePath.replace(
                  `/${param}`,
                  `/:${key}`
                );
                if (keys.includes(newPathToCheck)) {
                  clickable = true;
                  basePath = newPathToCheck;
                } else {
                  clickable = false;
                }
              } else if (!paramCount) {
                clickable = false;
              } else {
                const treatedPath = treatMultipleParams(
                  locale
                    ? completePath.replace(`/${locale}`, "/:locale")
                    : completePath,
                  key,
                  param,
                  keys
                );
                if (!treatedPath) {
                  clickable = false;
                } else {
                  basePath = treatedPath.path;
                  clickable = true;
                  isParam = treatedPath.lastIsParam;
                }
              }
            }
          }
        }
        const completePathArray = completePath.split("/");
        name = completePathArray[completePathArray.length - 1];
        const ret = {
          hasIcon: false,
          clickable,
          isParam,
          name,
        };

        if (clickable) {
          ret.path = basePath;
          ret.completePath = completePath;
        }
        return ret;
      }
    };

    const treatMultipleParams = (path, key, value, keys) => {
      const pathArray = path.split(`/${value}`);
      let acceptedPaths = [];
      let innerPath = "";
      let position = 1;
      let lastIsParam = false;
      for (; position < pathArray.length; position++) {
        let changed = false;
        for (let i = 0; i < pathArray.length - 1; i++) {
          if (i === position) {
            innerPath += `/:${key}${pathArray[i]}`;
            changed = true;
          } else if (i !== 0) {
            innerPath += `/${value}${pathArray[i]}`;
          } else innerPath += pathArray[i];
        }
        if (!changed) {
          innerPath += `/:${key}`;
          lastIsParam = true;
        }
        if (keys.includes(innerPath)) {
          acceptedPaths.push({ path: innerPath, lastIsParam });
        }
        innerPath = "";
        changed = false;
        lastIsParam = false;
      }
      return acceptedPaths.length !== 1 ? false : acceptedPaths[0];
    };
    const preparePathObject = (path, params, routes) => {
      const homePaths = ["/", "/:locale", "/:locale/"];
      const hasLocale = !!params.locale;
      if (hasLocale) {
        homePaths.push(...[`/${params.locale}`, `/${params.locale}/`]);
      }

      if (homePaths.includes(path)) {
        return preparePathObjectAux(
          `/${params.locale}`,
          params,
          routes,
          hasLocale,
          true
        );
      } else {
        const filteredParams = {};
        for (const key in params) {
          if (Object.hasOwnProperty.call(params, key)) {
            const param = params[key];
            if (key !== "locale") {
              filteredParams[key] = param;
            }
          }
        }

        return preparePathObjectAux(
          path,
          filteredParams,
          routes,
          hasLocale,
          false,
          hasLocale ? params.locale : null
        );
      }
    };
    const pathArrayToString = (pathArray, params) => {
      const out = [];
      const routes = router
        .getRoutes()
        .map((route) => {
          const ret = {};
          ret[route.path] = route;
          return ret;
        })
        .reduce((o, n) => {
          const newKey = Object.keys(n)[0];
          const out = { ...o };
          out[newKey] = n[newKey];
          return out;
        }, {});
      pathArray.forEach((path) => {
        const completePath = "/" + path.join("/");
        out.push(preparePathObject(completePath, params, routes));
      });
      return out;
    };
    const unrollPath = (path, params) => {
      let pathArray = path.split("/");
      const len = pathArray.length;
      if (len === 1) return [path];
      const paths = ref([]);
      if (pathArray.includes(""))
        pathArray = pathArray.reduce((o, n) => {
          if (n === "") return o;
          else {
            paths.value.push([...o, n]);
            return [...o, n];
          }
        }, []);
      let out = [];
      out = pathArrayToString(paths.value, params);
      return out;
    };
    const calculatePath = computed(() => {
      const cr = router?.currentRoute?.value || null;
      const params = cr?.params || null;
      const locale = params.locale || null;
      const fullPath = cr?.fullPath || null;
      if (fullPath !== `/${locale}`) {
        const paths = unrollPath(fullPath, params);
        return paths;
      } else return [];
    });
    const color = ref(
      ColorClasses[
        store.getters.breadcrumbsThemeColor
          ? store.getters.componentsDefaultColor
          : store.getters.breadcrumbsColor
      ]
    );
    watch(
      () => store.getters.breadcrumbsThemeColor,
      (newValue) => {
        color.value =
          ColorClasses[
            newValue
              ? store.getters.componentsDefaultColor
              : store.getters.breadcrumbsColor
          ];
      }
    );
    watch(
      () => store.getters.breadcrumbsColor,
      (newValue) => {
        color.value =
          ColorClasses[
            store.getters.breadcrumbsThemeColor
              ? store.getters.componentsDefaultColor
              : newValue
          ];
      }
    );

    watch(
      () => store.getters.componentsDefaultColor,
      (newValue) => {
        color.value =
          ColorClasses[
            store.getters.breadcrumbsThemeColor
              ? newValue
              : store.getters.breadcrumbsColor
          ];
      }
    );

    const olClass = computed(() => {
      let outClass = "flex font-semibold dark:text-white-dark";
      switch (store.getters.breadcrumsType) {
        case "arrowed":
          return [
            `${outClass}`,
            addClass({}, textClasses, "normal", color.value),
          ];
        case "iconed":
          return `${outClass} items-center flex-wrap text-gray-500 gap-y-4`;
        default:
          return `${outClass} text-gray-500`;
      }
    });
    const liColorClass = computed(function () {
      return (index, selected) => {
        const type = store.getters.breadcrumsType;
        if (!!index) {
          let commonSelectedClasses = addClass(
            {},
            backgroundClasses,
            "before",
            color.value
          );
          let commonClasses = selected ? commonSelectedClasses : "";
          switch (type) {
            case "dotted":
            case "iconed":
              return commonClasses;
            default:
              return ``;
          }
        }
      };
    });
    const liClass = computed(function () {
      return (index, selected) => {
        const type = store.getters.breadcrumsType;
        if (!index) {
          if (type === "arrowed")
            return "bg-[#ebedf2] ltr:rounded-l-md rtl:rounded-r-md dark:bg-[#1b2e4b]";
          else return "";
        } else {
          let commonSelectedClasses =
            "before:w-1 before:h-1 before:rounded-full before:inline-block before:relative before:-top-0.5 before:mx-4";
          let commonUnselectedClasses =
            "before:w-1 before:h-1 before:rounded-full before:bg-white-dark/70 before:inline-block before:relative before:-top-0.5 before:mx-4";
          let commonClasses = selected
            ? commonSelectedClasses
            : commonUnselectedClasses;
          switch (type) {
            case "arrowed":
              return "bg-[#ebedf2] dark:bg-[#1b2e4b]";
            case "dotted":
              return commonClasses;
            case "iconed":
              return `flex items-center ${commonClasses}`;
            default:
              return `before:content-['/'] before:px-1.5 before:text-white-dark/70`;
          }
        }
      };
    });
    const isSelected = (path) => router.currentRoute.value.href === path;
    const aColorClass = computed(function () {
      return (completePath) => {
        const selected = isSelected(completePath);
        const type = store.getters.breadcrumsType;
        switch (type) {
          case "arrowed":
            let selectedClasses = {};
            selectedClasses = addClass(
              selectedClasses,
              backgroundClasses,
              "normal",
              color.value
            );
            selectedClasses = addClass(
              selectedClasses,
              borderClasses,
              "beforeL",
              color.value
            );
            const unselectedCommonClasses = addClass(
              {},
              text70Classes,
              "hover",
              color.value
            );
            if (selected) return selectedClasses;
            else return unselectedCommonClasses;
          case "dotted":
          case "iconed":
            return selected
              ? addClass({}, textClasses, "normal", color.value)
              : "";
          case "basic":
          default:
            return "";
        }
      };
    });
    const aClass = computed(function () {
      return (index, completePath, selectable) => {
        const selected = isSelected(completePath);

        const basicClasses =
          "text-black dark:text-white-light hover:text-black/70 dark:hover:text-white-light/70";
        const selectableCommonClasses =
          "hover:text-gray-500/70 dark:hover:text-white-dark/70";
        const type = store.getters.breadcrumsType;
        switch (type) {
          case "arrowed":
            const basicArrowedClasses =
              "p-1.5 relative h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:z-[1]";
            const selectedClasses =
              "text-white-light ltr:pl-6 rtl:pr-6 ltr:pr-2 rtl:pl-2"; // bg-primary before:border-l-primary
            const unselectedCommonClasses =
              "before:border-l-[#ebedf2] dark:before:border-l-[#1b2e4b] dark:hover:text-white-dark/70"; //  hover:text-primary/70
            const unselectedFirstClasses =
              "ltr:pl-3 rtl:pr-3 ltr:pr-2 rtl:pl-2";
            const unselectedOtherClasses = "px-3 ltr:pl-6 rtl:pr-6";
            /* selected */
            if (selected) return `${basicArrowedClasses} ${selectedClasses}`;
            else
              return `${basicArrowedClasses} ${unselectedCommonClasses} ${
                !index ? unselectedFirstClasses : unselectedOtherClasses
              }`;
          case "dotted":
            return selected
              ? "" // text-primary
              : selectable
              ? selectableCommonClasses
              : "";
          case "iconed":
            let iconsCommon =
              "p-2.5 border border-gray-500/20 rounded-md shadow flex items-center justify-center ";
            return (
              iconsCommon +
              (selected
                ? `dark:border-0 dark:bg-[#191e3a]` // text-primary
                : selectable
                ? `dark:border-0 dark:bg-[#191e3a] ${selectableCommonClasses}`
                : "dark:border-0 dark:bg-[#191e3a]")
            );
          case "basic":
            return selected ? basicClasses : "";
          default:
            return selected
              ? basicClasses
              : selectable
              ? selectableCommonClasses
              : "";
        }
      };
    });
    return {
      olClass,
      calculatePath,
      liClass,
      liColorClass,
      aClass,
      aColorClass,
      capitalize,
      isSelected,
      color,
    };
  },
};
</script>

<style scoped>
.before\:border-l-\[15px\]::before {
  content: var(--tw-content);
  border-left-width: 16px;
}
</style>
