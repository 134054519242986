export default {
    setBreadcrumsType(state, type) {
        state.breadcrumsType = type;
    },
    setBreadcrumbsThemeColor(state, themeColor) {
        state.breadcrumbsThemeColor = themeColor;
    },
    setBreadcrumbsColor(state, color) {
        state.breadcrumbsColor = color;
    },
}