import getters from "./getters"
import actions from "./actions"
import mutations from "./mutations"
export default {
  state: {
    users: [],
    user: {},
    associatedLabkeyBuildings: []
  },
  getters,
  mutations,
  actions,
}