
import { getRequest } from '@/modules/routines.cjs'
export default {

    async retrieveLogs(context, loader = false) {
        return new Promise((resolve, reject) => {

            if (loader) context.dispatch('loaderPresent');
            getRequest(`${process.env.VUE_APP_SERVER_API_PATH}logs`, null, (e) => {
                if (!e) resolve(false);
                else {
                    context.commit('setLogs', { logs: e });
                    if (loader) context.dispatch('loaderDismiss');
                    resolve(true)
                }
            }, (e) => {
                if (loader) context.dispatch('loaderDismiss');
                resolve(false)
            })
        })
    },
    async selectLog(context, id = '') {
        context.commit('setLog', '');
        if (!context.getters.logs.length) await context.dispatch("retrieveLogs");
        if (!context.getters.logs.labkey.length) context.commit('setLog', '');
        else context.commit('setLog', id);
    },
    async selectSystemLog(context, id = '') {
        if (!context.getters.logs.length) await context.dispatch("retrieveLogs");
        if (!context.getters.logs.system.length) context.commit('setSystemLog', '');
        else context.commit('setSystemLog', id);
    },

}