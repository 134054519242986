<template>
  <div :class="[mainClass, inputMainClass]">
    <h6 v-if="title" class="text-lg font-bold mb-5">{{ title }}</h6>
    <slot></slot>
  </div>
</template>

<script>
import { computed } from "vue";
export default {
  props: {
    direction: {
      type: String,
      default: "v", // h or v
    },
    title: {
      type: String,
      default: "",
    },
    inputMainClass: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const mainClass = computed(() => {
      let outClass = "flex flex-col";
      return (
        outClass + (props.direction === "h" ? " md:flex-row gap-4" : " w-full")
      );
    });
    return {
      mainClass,
    };
  },
};
</script>

<style>
</style>