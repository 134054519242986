import { getRequest } from '@/modules/routines.cjs'

export default {
    setMenu(context, payload) {
        context.commit('setMenu', payload);
    },
    retrieveMenu(context, loader = false) {


        return new Promise((resolve, reject) => {

            if (loader) context.dispatch('loaderPresent');
            getRequest(`${process.env.VUE_APP_SERVER_API_PATH}menu`, null, (e) => {
                context.commit('setMenu', { menu: e })
                if (loader) context.dispatch('loaderDismiss');
                resolve(true)
            }, (e) => {
                if (loader) context.dispatch('loaderDismiss');
                reject(false)
            })
        })


    },
    openMenu(context) {
        context.commit('setMenuState', true);
    },
    closeMenu(context) {
        context.commit('setMenuState', false);
    }
}