const fieldsRegex = Object.freeze({
    text: /^[a-zA-Z0-9\s.,!()_+:;"'=?/|\\\-\À\Á\à\á\È\É\è\é\Ì\Í\ì\í\Ò\Ó\ò\ó\Ù\Ú\ù\ú]*$/gm,
    textRequired: /^[a-zA-Z0-9\s.,!()_+:;"'=?/|\\\-\À\Á\à\á\È\É\è\é\Ì\Í\ì\í\Ò\Ó\ò\ó\Ù\Ú\ù\ú]+$/gm,
    number: /^([0-9]+)$/gm,
    password: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\+\-\.!\?#@])[A-Za-z0-9\+\-\.!\?#@]{8,20}$/gm,
    email: /^([\w.]*)[@]{1}(([A-Za-z0-9\-]+[\.]{1})+([a-z]){2,})$/gm,
    url: /^(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?$/gm,
    tel: /^(((\+)|(00)){1}[1-9]{1,3})?([0-9]{4,12})$/gm,
    date: /^(?:\d{2}\/\d{2}\/\d{4}){1}$/gm,
    optionalDate: /^(?:\d{2}\/\d{2}\/\d{4})?$/gm,
    decimalNumber: /^\d+(\.\d{1,2})?$/gm,
    price: /^\d+(\.\d{1,2})?$/gm,
    wixId: /^([a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12})$/gm,
    durationMeasureUnit: /^((\b(hour|access)(Per(Day|Week|Month|Interval)|es)\b|fullInterval|accesses)|-)$/gm,
    optionalDurationMeasureUnit: /^((\b(hour|access)(Per(Day|Week|Month|Interval)|es)\b|fullInterval|accesses)|-)?$/gm,
    setupType: /^(\b(AAA|yearly|monthly|weeklyInterval|dateInterval|daily(?:24H|Till[04]))\b)$/gm,
    duration: /^(((\[){2}[^\]]*(\])(,)[0-9]{1,3}(\]))|(-)|[0-9]{1,3})$/gm,
    optionalDuration: /^(((\[){2}[^\]]*(\])(,)[0-9]{1,3}(\]))|(-)|[0-9]{1,3})?$/gm,
})
const regexErrors = Object.freeze({
    text: 'You can only use letters, number, spaces and the following special chars ".,!()_+:;\"\'=?/|\\-"',
    textRequired: 'You can only use at least one between letters, number, spaces and the following special chars ".,!()_+:;\"\'=?/|\\-"',
    number: "You can only use numbers. One, at least.",
    password: 'The password must be between 8 and 20 characters and must contain at least one small letter, one capital letter, one number and one of the special chars "+-.!?#@"',
    email: 'The email is not in a valid format',
    url: 'The url is not in a valid format',
    tel: 'The telephone number is not in a valid format',
    date: 'The date format must be dd/mm/yyyy',
    optionalDate: 'If provided, the date format must be dd/mm/yyyy',
    decimalNumber: 'You must provide a decimal number',
    price: 'You must provide a decimal number',
    wixId: 'The wix id format must be \'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx\'',
    durationMeasureUnit: "Allowed values: 'accessPerInterval' or 'fullInterval'",
    optionalDurationMeasureUnit: "Allowed values: 'accessPerInterval', 'fullInterval', '-' or leave the field empty",
    setupType: "Allowed values: 'monthly', 'dateInterval', 'dailyTill0' or 'dailyTill4'.",
    duration: "You can provide a number or a setup in the following format: \"[['{reservationDate} sh:sm:ss', '{reservationDate}+xxxgg eh:em:es'],yy]\", having <br/>sh=start hour <br/>sm=start minute <br/>ss=start second <br/>xxx=duration in days <br/>eh=end hour <br/>em=end minute <br/>es=end second",
    optionalDuration: "You can either leave it empty, provide a number or a setup in the following format: \"[['{reservationDate} sh:sm:ss', '{reservationDate}+xxxgg eh:em:es'],yy]\", having <br/>sh=start hour <br/>sm=start minute <br/>ss=start second <br/>xxx=duration in days <br/>eh=end hour <br/>em=end minute <br/>es=end second",
})




module.exports = { fieldsRegex, regexErrors }