<template>
  <div :class="{ 'dark text-white-dark': semidark }">
    <nav
      class="sidebar fixed min-h-screen h-full top-0 bottom-0 w-[260px] shadow-[5px_0_25px_0_rgba(94,92,154,0.1)] z-50 transition-all duration-300"
    >
      <MainMenu :voices="menuVoices" />
    </nav>
  </div>
</template>

<script>
import { ref, onMounted, reactive, computed } from "vue";
import store from "@/store";

import { i18n } from "@/i18n";

export default {
  setup() {
    const { t } = i18n.global;
    const subActive = ref("");
    const menuVoices = computed(() => store.getters.getMenu);

    onMounted(() => {
      const selector = document.querySelector(
        '.sidebar ul a[href="' + window.location.pathname + '"]'
      );
      if (selector) {
        selector.classList.add("active");
        const ul = selector.closest("ul.sub-menu");
        if (ul) {
          let ele = ul.closest("li.menu").querySelectorAll(".nav-link") || [];
          if (ele.length) {
            ele = ele[0];
            setTimeout(() => {
              ele.click();
            });
          }
        }
      }
    });
    const semidark = computed(() => store.getters.getSemidarkStatus);
    const layout = computed(() => store.getters.getMainLayout);
    return {
      subActive,
      menuVoices,
      semidark,
      t,
      layout,
    };
  },
};
</script>
