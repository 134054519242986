
import { createApp } from 'vue/dist/vue.esm-bundler.js'
import './registerServiceWorker';
import router from './router';
import store from './store';
import App from '@/App.vue';

import PerfectScrollbar from 'vue3-perfect-scrollbar';
import { createHead } from '@vueuse/head';
import { i18n } from '@/i18n';
import { Trans } from '@/modules/Translation'
import Maska from 'maska';
import VueEasymde from 'vue3-easymde';
import 'easymde/dist/easymde.min.css';
import Popper from 'vue3-popper';
import vue3JsonExcel from 'vue3-json-excel';
import { TippyPlugin } from 'tippy.vue';

/* COMPONENTS */
import TopBar from "@/components/layout/TopBar.vue";
import Sidebar from '@/components/layout/Sidebar.vue';
import Header from '@/components/layout/Header.vue';
import Footer from '@/components/layout/Footer.vue';
import DataSummary from '@/components/baseComponents/DataSummary.vue';
import CardContent from '@/components/baseComponents/CardContent.vue';
import PageSection from "@/components/layout/pageSection/PageSection.vue";
import MainMenu from "@/components/layout/menu/MainMenu.vue";
import MyDataTable from "@/components/layout/table/MyDataTable";
import Icon from '@/components/baseComponents/Icon';
import Loader from '@/components/baseComponents/Loader.vue';
import MainLoader from '@/components/MainLoader.vue';
import VueCollapsible from 'vue-height-collapsible/vue3';
import Vue3Datatable from "@bhplugin/vue3-datatable";
import MyInput from "@/components/formComponents/MyInput.vue";
import MySelect from "@/components/formComponents/MySelect.vue";
import MyTextarea from "@/components/formComponents/MyTextarea.vue";

import MyAccordion from "@/components/accordion/MyAccordion.vue";
import MyButton from "@/components/baseComponents/MyButton.vue";
import Notification from "@/components/baseComponents/Notifications.vue";
import MyWizard from "@/components/wizard/MyWizard.vue";
import MyTabs from "@/components/tabs/MyTabs.vue";
import Badge from "@/components/baseComponents/Badge.vue";
import Modal from "@/components/modal/Modal";
import DefaultPage from "@/components/layout/pages/DefaultPage.vue";
import FormGroup from "@/components/layout/pageSection/FormGroup.vue";
import CreationPage from "@/components/layout/pages/CreationPage.vue";
import EditPage from "@/components/layout/pages/EditPage.vue";


const app = createApp(App);

// main app css
import '@/assets/css/app.css';

// perfect scrollbar
app.use(PerfectScrollbar);

//vue-meta
const head = createHead();
app.use(head);

// set default settings
import appSetting from '@/app-setting';
appSetting.init();

//vue-i18n
app.config.globalProperties.$i18nRoute = Trans.i18nRoute.bind(Trans)

app.use(i18n);
app.use(store);

app.use(router);
app.use(Trans)
app.provide('i18n', i18n)

// tippy tooltips
app.use(TippyPlugin);

//input mask
app.use(Maska);

//markdown editor
app.use(VueEasymde);

// popper
app.component('Popper', Popper);

// json to excel
app.use(vue3JsonExcel);

/* COMPONENTS */
app.component("MainLoader", MainLoader);
app.component("Loader", Loader);
app.component("TopBar", TopBar);
app.component("Sidebar", Sidebar);
app.component("Header", Header);
app.component("Footer", Footer);
app.component("DataSummary", DataSummary);
app.component("CardContent", CardContent);
app.component("PageSection", PageSection);
app.component("MainMenu", MainMenu);
app.component("MyDataTable", MyDataTable);
app.component("Icon", Icon);
app.component("MyInput", MyInput);
app.component("MySelect", MySelect);
app.component("MyTextarea", MyTextarea);

app.component("MyAccordion", MyAccordion);
app.component("MyButton", MyButton);
app.component("Notification", Notification);
app.component("MyWizard", MyWizard);
app.component("MyTabs", MyTabs);
app.component("Badge", Badge);
app.component("Modal", Modal);
app.component("DefaultPage", DefaultPage);
app.component("FormGroup", FormGroup);
app.component("CreationPage", CreationPage);
app.component("EditPage", EditPage);
app.component("vue-collapsible", VueCollapsible);
app.component("vue3-datatable", Vue3Datatable);

app.mount('#app');

