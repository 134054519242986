export default {
    setLocale(state, locale) {
        state.locale = locale;
    },
    setMainLayout(state, layout) {
        state.mainLayout = layout;
    },
    setTheme(state, theme) {
        state.theme = theme;
    },
    setDarkMode(state) {
        state.isDarkMode = true;
    },
    unsetDarkMode(state) {
        state.isDarkMode = false;
    },
    setLayout(state, layout) {
        state.layout = layout;
    },
    toggleSidebar(state) {
        state.sidebar = !state.sidebar;
    },
    setSidebar(state) {
        state.sidebar = true;
    },
    unsetSidebar(state) {
        state.sidebar = false;
    },
    setMenuOrientation(state, orientation) {
        state.menuOrientation = orientation;
    },
    setRtlClass(state, rtlClass) {
        state.rtlClass = rtlClass;
    },
    setAnimation(state, animation) {
        state.animation = animation;
    },
    setNavbar(state, navbar) {
        state.navbar = navbar;
    },
    setSemidarkStatus(state, semidark) {
        state.semidark = semidark;
    },
    setSemidark(state) {
        state.semidark = true;
    },
    unsetSemidark(state) {
        state.semidark = false;
    },
    setComponentsDefaultColor(state, color) {
        state.componentsDefaultColor = color;
    },
}