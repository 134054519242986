import getters from "./getters"
import actions from "./actions"
import mutations from "./mutations"
export default {
    state: {
        breadcrumsType: 'arrowed', // default, basic, arrowed, dotted, iconed
        breadcrumbsThemeColor: true,
        breadcrumbsColor: "green"
    },
    getters,
    mutations,
    actions
}
