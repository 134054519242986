<template>
  <TabGroup as="div" :class="`mb-5${tabGroupDynamicClass}`">
    <div v-if="config.isVertical" class="ml-10 mb-5 sm:mb-0">
      <TabCycle :config="config" />
    </div>
    <TabCycle v-else :config="config" />
    <TabPanels :class="panelsClass">
      <TabPanel
        v-for="(label, i) in config.labels"
        :key="`${label.text}_${i}_panel`"
        :class="{ active: i === 0 }"
      >
        <slot :name="label.text"></slot>
      </TabPanel>
    </TabPanels>
  </TabGroup>
</template>

<script>
import { TabGroup, Tab, TabPanels, TabPanel } from "@headlessui/vue";
import { reactive, computed } from "vue";
import { validate } from "@/modules/routines.cjs";
import TabCycle from "@/components/tabs/tabsComponents/TabCycle.vue";
export default {
  components: {
    TabGroup,
    Tab,
    TabPanels,
    TabPanel,
    TabCycle,
  },
  props: {
    config: {
      type: Object,
      validator(v) {
        const validationObj = {
          labels: {
            type: Array,
            default: reactive([
              { text: "Home", disabled: false, icon: "home" },
              { text: "Profile", disabled: true, icon: "profile" },
              { text: "Contact", disabled: false, icon: "contact" },
            ]),
          },
          isVertical: {
            type: Boolean,
            default: false,
          },
          pillsTabs: {
            type: Boolean,
            default: false,
          },
          tabType: {
            type: String,
            default: "tab", // tab, topLine(isVertical must be equal to false), line (for tabs). tab, circle (for pills)
          },
          animatedLine: {
            // only works for line tab type
            type: Boolean,
            default: false,
          },
          borderedPanels: {
            type: Boolean,
            default: false,
          },
          hasIcon: {
            type: Boolean,
            default: false,
          },
          verticalIcon: {
            // works on pills with icons only
            type: Boolean,
            default: false,
          },
          themeColor: {
            // if enabled uses the COMPONENTS_DEFAULT_COLOR global variable as default color
            type: Boolean,
            default: false,
          },
          color: {
            // only works if themeColor is equal to false
            type: String,
            default: "blue",
          },
          justify: {
            // isVertical must be equal to false for this to work
            type: String,
            default: "left", //left, right, center, full
          },
          unpaddedContent: {
            type: Boolean,
            default: false,
          },
        };
        return validate(v, validationObj);
      },
      default: reactive({}),
    },
  },
  setup(props) {
    const tabGroupDynamicClass = computed(() => {
      if (!props.config.isVertical) return "";
      else return " flex flex-col sm:flex-row";
    });
    const panelsClass = computed(() => {
      const unpadded = props.config.unpaddedContent;
      if (props.config.pillsTabs) {
        let outClass = "flex-1 text-sm pt-5";
        outClass += props.config.isVertical
          ? ` border-l-0 ${!unpadded ? "px-8" : ""}`
          : ` border-t-0 ${!unpadded ? "p-6" : ""}`;
        return outClass;
      }
      let outClass = "flex-1 text-sm";
      outClass += props.config.borderedPanels
        ? ` border border-white-light dark:border-[#191e3a] ${
            !unpadded ? "p-4" : ""
          }`
        : " pt-5";
      outClass += props.config.isVertical
        ? ` border-l-0 ${!unpadded ? "px-8" : ""}`
        : ` border-t-0 ${!unpadded ? "p-6" : ""}`;
      return outClass;
    });

    return {
      panelsClass,
      tabGroupDynamicClass,
    };
  },
};
</script>

<style>
</style>
