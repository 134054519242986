<template>
  <span> </span>
</template>

<script>
import { computed, ref, watch, reactive } from "vue";
import { validate } from "@/modules/routines.cjs";
import Swal from "sweetalert2";
import store from "@/store";
import { ColorClasses } from "@/components/ColorClasses";

export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      validator(v) {
        const validationObj = {
          themeColor: { type: Boolean, default: false },
          color: { type: String, default: "blue" },
          closeButton: { type: Boolean, default: true },
          okButton: { type: Boolean, default: false },
          vPosition: { type: String, default: "bottom" },
          hPosition: { type: String, default: "left" },
          duration: { type: Number, default: 3000 },
        };
        return validate(v, validationObj);
      },
      default: reactive({}),
    },
    data: {
      type: Object,
      validator(v) {
        const validationObj = {
          msg: {
            type: String,
            default: "This is a notification.",
          },
        };
        return validate(v, validationObj);
      },
      default: reactive({}),
    },
  },
  setup(props, ctx) {
    const emit = ctx.emit;
    const innerActive = computed({
      get() {
        return props.active;
      },
      set(active) {
        emit("update:active", active);
      },
    });
    const rtlClass = computed(() => store.getters.rtlClass);
    const color = ref(
      ColorClasses[
        props.config.themeColor
          ? store.getters.componentsDefaultColor
          : props.config.color
      ]
    );
    watch(
      () => props.config.themeColor,
      (newValue) => {
        color.value =
          ColorClasses[
            newValue ? store.getters.componentsDefaultColor : props.config.color
          ];
      }
    );
    watch(
      () => props.config.color,
      (newValue) => {
        color.value =
          ColorClasses[
            props.config.themeColor
              ? store.getters.componentsDefaultColor
              : newValue
          ];
      }
    );
    watch(
      () => store.getters.componentsDefaultColor,
      (newValue) => {
        color.value =
          ColorClasses[props.config.themeColor ? newValue : props.config.color];
      }
    );
    watch(innerActive, (nv) => {
      if (nv) {
        let position = "";
        switch (props.config.vPosition) {
          case "top":
            position = "top";
            break;
          case "center":
            position = "center";
            break;
          case "bottom":
            position = "bottom";
            break;
          default:
            position = "bottom-start";
        }
        if (position !== "bottom-start") {
          switch (props.config.hPosition) {
            case "left":
              position += "-start"; // (position !== "" ? "-" : "") + "start";
              break;
            case "center":
              position += "";
              break;
            case "right":
              position += "-end"; // (position !== "" ? "-" : "") + "end";
              break;
            default:
              position = "bottom-start";
          }
        }

        const toast = Swal.mixin({
          toast: true,
          position: position,
          showConfirmButton: props.config.okButton && !props.config.closeButton,
          timer: props.config.duration,
          showCloseButton: props.config.closeButton,
          customClass: {
            popup: `color-${color.value}`,
            closeButton: `!text-white`,
          },
        });
        toast
          .fire({
            title: props.data.msg,
          })
          .then((result) => {
            emit("update:active", false);
          });
      }
    });

    return {
      rtlClass,
      innerActive,
    };
  },
};
</script>

<style>
</style>