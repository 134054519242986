<template>
  <div>
    <label
      v-if="config.hasLabel || false"
      :for="config.id || config.name || null"
      >{{ data.label }}</label
    >
    <select
      :id="config.id || null"
      :name="config.name || null"
      :class="selectDynamicClass"
      :required="config.required"
      :disabled="config.disabled"
      :multiple="isMultiple"
      v-model="innerValue"
      v-if="config.type !== 'multiselect'"
    >
      <option value="-1" :disabled="!config.selectableDefault || false">
        {{ data.placeholder || "Please select one..." }}
      </option>
      <option
        :value="option.value"
        v-for="(option, index) in data.options"
        :key="getOptionKey(index)"
      >
        {{ option.text }}
      </option>
    </select>
    <multiselect
      v-else
      :id="config.id"
      :name="config.name"
      class="custom-multiselect cursor-pointer"
      :searchable="config.isSearchable"
      :allow-empty="config.isMultiple && !config.atLeastOneSelected"
      :preselect-first="true"
      :placeholder="data.placeholder"
      :selected-label="data.selectedLabel || null"
      :select-label="data.selectLabel || null"
      :deselect-label="data.deselectLabel || null"
      :multiple="config.isMultiple"
      label="text"
      track-by="value"
      :object="true"
      value-prop="value"
      :group-values="config.isGrouped ? 'list' : null"
      :group-label="config.isGrouped ? 'groupName' : null"
      :disabled="config.disabled"
      :options="options"
      v-model="innerValue"
    ></multiselect>
  </div>
</template>

<script>
import { ref, reactive, computed } from "vue";
import { validate } from "@/modules/routines.cjs";
import Multiselect from "@suadelabs/vue3-multiselect";
import "@suadelabs/vue3-multiselect/dist/vue3-multiselect.css";
/* "@suadelabs/vue3-multiselect": "^1.0.2", */

export default {
  components: {
    Multiselect,
  },
  props: {
    config: {
      type: Object,
      validator(v) {
        const validationObj = {
          type: {
            // dropdown, multiple, multiselect
            type: String,
            default: "dropdown",
          },
          size: {
            // s, m, l
            type: String,
            default: "m",
          },
          required: {
            type: Boolean,
            default: false,
          },
          hasLabel: {
            type: Boolean,
            default: true,
          },
          isSearchable: {
            type: Boolean,
            default: false,
          },
          selectableDefault: {
            type: Boolean,
            default: false,
          },
          disabled: {
            type: Boolean,
            default: false,
          },
          isGrouped: {
            type: Boolean,
            default: false,
          },
          isMultiple: {
            type: Boolean,
            default: false,
          },
          atLeastOneSelected: {
            type: Boolean,
            default: true,
          },
          id: { type: String },
          name: { type: String },
        };
        return validate(v, validationObj);
      },
      default: () => {},
    },
    data: {
      type: Object,
      validator(v) {
        const validationObj = {
          label: { type: String },
          selectedLabel: {
            type: String,
            default: "",
          },
          selectLabel: {
            type: String,
            default: "",
          },
          deselectLabel: {
            type: String,
            default: "",
          },
          placeholder: { type: String, default: "Please select one..." },
          options: {
            type: Array,
            default: [],
          },
        };
        return validate(v, validationObj);
      },
      default: () => {},
    },
    value: {
      type: [String, Number, Array],
      default: -1,
    },
  },
  setup(props, ctx) {
    const selectDynamicClass = computed(() => {
      let outClass = "";
      switch (props.config.type) {
        case "multiple":
          outClass = "form-multiselect";
          break;
        default:
          outClass = "form-select";
          break;
      }
      switch (props.config.size) {
        case "l":
          outClass += " form-select-lg";
          break;
        case "s":
          outClass += " form-select-sm";
          break;
        default:
          break;
      }
      return `${outClass} text-white-dark cursor-pointer`;
    });

    const isMultiple = computed(() => {
      return props.config.type !== "dropdown";
    });

    const getOptionKey = (i) => {
      return props.data.id !== null || props.data.name !== null
        ? (props.data.id ?? props.data.name) + `_${i}`
        : i;
    };

    const emit = ctx.emit;
    const options = computed(() => {
      if (props.config.isGrouped) {
        return [
          /* {
            groupName: null,
            list: [
              {
                value: -1,
                text: props.data.placeholder,
                $isDisabled: !props.config.selectableDefault,
              },
            ],
          }, */
          ...props.data.options,
        ];
      } else
        return [
          {
            value: -1,
            text: props.data.placeholder,
            $isDisabled: !props.config.selectableDefault,
          },
          ...props.data.options,
        ];
    });
    const innerValue = computed({
      get() {
        if (props.value === []) return -1;
        if (props.config.type !== "multiselect") return props.value;
        if (props.config.isGrouped) {
          const check = props.config.isMultiple;
          let values = [];
          for (let i = 0; i < props.data.options.length; i++) {
            const el = props.data.options[i];
            const foundEls = el.list.filter((option) => {
              return !check || typeof props.value !== "object"
                ? option.value === props.value
                : props.value.includes(option.value);
            });
            if (!!foundEls.length) {
              if (!check || typeof props.value !== "object") return foundEls[0];
              values = [...values, ...foundEls];
            }
          }
          return values;
        } else {
          return props.config.isMultiple && typeof props.value === "object"
            ? props.data.options.filter((option) =>
                props.value.includes(option.value)
              )
            : props.data.options.filter(
                (option) => option.value === props.value
              )[0];
        }
      },
      set(value) {
        emit(
          "update:value",
          props.config.type !== "multiselect"
            ? value
            : props.config.isMultiple
            ? typeof props.value !== "object"
              ? [value[0].value]
              : value.map((v) => v.value)
            : value.value
        );
      },
    });
    const log = (msg) => console.log(`Logging: `, msg);
    const test = ref([]);
    return {
      selectDynamicClass,
      isMultiple,
      getOptionKey,
      innerValue,
      options,
      log,
      test,
    };
  },
};
</script>

<style>
</style>