const inputDefaultTypes = Object.freeze({
    text: 'String',
    password: 'String',
    email: 'String',
    url: 'String',
    tel: 'String',
    search: 'String',
    range: 'Number',
    radio: 'String',
    checkbox: 'Boolean',
    file: 'Array',
    switch: 'Boolean',
})


const selectDefaultTypes = Object.freeze({
    dropdown: 'String',
    multiple: 'Array',
    multiselect: 'Array'
})
const textareaDefaultTypes = 'String'


const typeToDefault = Object.freeze({
    String: '',
    Array: [],
    Boolean: false,
    Number: -1,
    Object: {}
})
module.exports = {
    inputDefaultTypes,
    selectDefaultTypes,
    textareaDefaultTypes,
    typeToDefault,
}