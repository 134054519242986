<template>
  <Notification
    :config="notificationConfig"
    :data="notificationData"
    :active="active"
    @update:active="updateSystemNotificationStatus"
  />
</template>

<script>
import store from "@/store";
import { computed, reactive, ref } from "vue";
import Notification from "./Notifications.vue";
export default {
  components: {
    Notification,
  },
  setup() {
    const notificationConfig = computed(() => {
      const type = ref(store.getters.systemNotificationType);
      const color =
        type.value === "error"
          ? "red"
          : type.value === "warning"
          ? "yellow"
          : "green";
      const closeButton = store.getters.sNotificationsCloseButton;
      const okButton = store.getters.sNotificationsOkButton;
      const vPosition = store.getters.sNotificationsVPosition;
      const hPosition = store.getters.sNotificationsHPosition;
      const duration = store.getters.sNotificationsDuration;
      return {
        themeColor: false,
        color: color,
        closeButton,
        okButton,
        vPosition,
        hPosition,
        duration: duration,
      };
    });
    const updateSystemNotificationStatus = (e) => {
      if (!e) store.dispatch("setSystemNotificationActiveStatus", false);
    };
    const notificationData = computed(() => {
      return reactive({ msg: store.getters.systemNotificationMessage });
    });
    const active = computed(() => store.getters.systemNotificationActiveStatus);

    return {
      notificationConfig,
      notificationData,
      active,
      updateSystemNotificationStatus,
    };
  },
};
</script>

<style>
</style>