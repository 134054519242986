<template>
  <span>
    <!-- Modal -->
    <template v-if="config.animation.active">
      <TransitionRoot appear :show="modalValue" as="template">
        <Dialog
          as="div"
          @close="config.static ? null : (modalValue = false)"
          class="relative z-50"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0 bg-[black]/60" />
          </TransitionChild>

          <div class="fixed inset-0 overflow-y-auto">
            <div
              class="flex min-h-full justify-center px-4 py-8"
              :class="
                config.verticallyCentered ? 'items-center' : 'items-start'
              "
            >
              <ModalCore
                :config="myConfig"
                :modalData="modalData"
                ref="modalCore"
                v-model:modal="modalValue"
              >
                <template v-slot:modalBody>
                  <slot name="modalBody"></slot>
                </template>
              </ModalCore>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
    </template>
    <template v-else>
      <Dialog
        as="div"
        :open="modalValue"
        @close="config.static ? null : (modalValue = false)"
        class="relative z-50"
      >
        <DialogOverlay class="fixed inset-0 bg-[black]/60" />
        <div class="fixed inset-0 overflow-y-auto">
          <div
            class="flex min-h-full justify-center px-4 py-8"
            :class="config.verticallyCentered ? 'items-center' : 'items-start'"
          >
            <ModalCore
              :config="myConfig"
              :modalData="modalData"
              ref="modalCore"
              v-model:modal="modalValue"
            >
              <template v-slot:modalBody>
                <slot name="modalBody"></slot>
              </template>
            </ModalCore>
          </div>
        </div>
      </Dialog>
    </template>
  </span>
</template>
<script>
import { ref, computed, reactive } from "vue";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
} from "@headlessui/vue";

import ModalCore from "./modalComponents/ModalCore.vue";

export default {
  props: {
    config: {
      type: Object,
      default: () => {
        return ref({
          static: {
            type: Boolean,
            default: false,
          },
          verticallyCentered: {
            type: Boolean,
            default: false,
          },
          size: {
            type: String,
            default: "Normal",
          },
          modalType: {
            // can be modal, videoPlayer, login, profile, register, slider, standardCustom
            type: String,
            default: "modal",
          },
          animation: {
            type: Object,
            default: {
              active: true,
              direction: "",
              fades: false,
              slides: false,
              rotates: false,
              zooms: false,
            },
          },
        });
      },
    },
    modalData: {
      title: {
        type: String,
        default: "",
      },
      url: {
        type: String,
        default: "",
      },
      carouselPics: {
        type: Array,
        default: () => [],
      },
    },
    opened: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    ModalCore,
  },
  setup(props, ctx) {
    const widthClass = computed(() => {
      if (props.config.modalType === "videoPlayer") return "max-w-3xl";
      else if (props.config.modalType === "slider") return "max-w-xl";
      else if (
        props.config.modalType === "login" ||
        props.config.modalType === "register"
      )
        return "max-w-sm";
      else if (props.config.modalType === "profile") return "max-w-[300px]";
      switch (props.config.size) {
        case "xl":
          return "max-w-5xl";
        case "l":
          return "max-w-xl";
        case "s":
          return "max-w-sm";
        default:
          return "max-w-lg";
      }
    });
    const myConfig = computed(() => reactive({ ...props.config, widthClass }));
    const emit = ctx.emit;
    const modalValue = computed({
      get() {
        return props.opened;
      },
      set(value) {
        emit("update:opened", value);
      },
    });

    return {
      modalValue,
      widthClass,
      myConfig,
    };
  },
};
</script>
