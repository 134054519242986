export default {
    setBreadcrumsType(context, type = 'default') {
        context.commit('setBreadcrumsType', type);
    },
    setBreadcrumbsThemeColor(context, themeColor = true) {
        context.commit('setBreadcrumbsThemeColor', themeColor);
    },
    setBreadcrumbsColor(context, color) {
        context.commit('setBreadcrumbsColor', color);
    },
}