<template>
  <div class="p-5">
    <form class="space-y-5" @submit.prevent="login">
      <div>
        <label for="username">Username</label>
        <input
          id="username"
          type="username"
          class="form-input"
          placeholder="Enter Username"
          v-model="username"
        />
      </div>
      <div>
        <label for="password">Password</label>
        <input
          id="password"
          type="password"
          class="form-input"
          placeholder="Enter Password"
          v-model="password"
        />
      </div>
      <button type="submit" class="btn btn-primary w-full">SIGN IN</button>
    </form>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import router from "@/router";
import store from "@/store";
import { loaderDismiss } from "@/modules/routines.cjs";

export default {
  setup() {
    onMounted(() => {
      store.commit("restoreUserSession");
      if (store.getters.userSession.loggedIn)
        router.replace({ name: process.env.VUE_APP_AUTH_REDIRECT });
      loaderDismiss(store);
    });
    const userSession = computed(() => store.getters.userSession);
    const username = ref("");
    const password = ref("");
    let loginData;
    const login = async () => {
      try {
        loginData = {
          username: username.value,
          password: password.value,
        };
        await store.dispatch("login", loginData);
        router.replace({ name: "Transactions" });
      } catch (e) {
        console.log("Wrong username or password", e);
      }
    };
    return {
      login,
      router,
      username,
      password,
      userSession,
    };
  },
};
</script>
