<template>
  <h6
    :class="['font-semibold', inputLabelClass, labelClass]"
    v-if="direction === 'v'"
  >
    {{ label }}
    <router-link
      v-if="clickableValue"
      :to="valueHref || null"
      @click="valueCallback || null"
      ><span
        :class="[
          'block overflow-x-auto',
          valueClass,
          valueRow ? 'flex flex-row items-center' : '',
        ]"
        >{{ value !== undefined && value !== null && value !== "" ? value : "-"
        }}<slot></slot></span
    ></router-link>
    <span
      v-else
      :class="[
        'block overflow-x-auto',
        valueClass,
        valueRow ? 'flex flex-row items-center' : '',
      ]"
      >{{ value !== undefined && value !== null && value !== "" ? value : "-"
      }}<slot></slot
    ></span>
  </h6>
  <div class="flex flex-col md:flex-row md:gap-4" v-else>
    <h6 :class="['font-semibold', inputLabelClass, labelClass]">
      {{ label }}
    </h6>
    <router-link
      v-if="clickableValue"
      :to="valueHref || null"
      @click="valueCallback || null"
      ><span
        :class="[
          'block overflow-x-auto',
          valueClass,
          valueRow ? 'flex flex-row items-center' : '',
        ]"
        >{{ value !== undefined && value !== null && value !== "" ? value : "-"
        }}<slot></slot></span
    ></router-link>
    <span
      v-else
      :class="[
        'block overflow-x-auto',
        valueClass,
        valueRow ? 'flex flex-row items-center' : '',
      ]"
      >{{ value !== undefined && value !== null && value !== "" ? value : "-"
      }}<slot></slot
    ></span>
  </div>
</template>

<script>
import { ColorClasses } from "@/components/ColorClasses.js";
import { computed } from "vue";
import { textClasses, addClass } from "@/components/CompoundClasses.js";
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    direction: {
      type: String,
      default: "v", // v or h
    },
    value: {
      type: String,
      default: "",
    },
    valueRow: {
      type: Boolean,
      default: false,
    },
    labelColor: {
      type: String,
      default: "default",
    },
    valueColor: {
      type: String,
      default: "default",
    },
    inputLabelClass: {
      type: String,
      default: "",
    },
    clickableValue: {
      type: Boolean,
      default: false,
    },
    valueHref: {
      type: String,
      default: "",
    },
    valueCallback: {
      type: Function,
      default: null,
    },
  },
  setup(props) {
    const labelClass = computed(() => {
      if (props.labelColor === "default")
        return "text-[#515365] dark:text-white-dark";

      const color = ColorClasses[props.labelColor];
      return addClass(
        {},
        textClasses,
        "normal",
        color,
        true,
        textClasses,
        "dark",
        color
      );
    });
    const valueClass = computed(() => {
      if (props.valueColor === "default")
        return "text-white-dark dark:text-white-light";
      const color = ColorClasses[props.valueColor];
      return addClass(
        {},
        textClasses,
        "normal",
        color,
        true,
        textClasses,
        "dark",
        color
      );
    });

    return {
      labelClass,
      valueClass,
    };
  },
};
</script>

<style>
</style>