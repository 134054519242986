const backgroundClasses = Object.freeze({
    normal: {
        primary: { "bg-primary": true },
        success: { "bg-success": true },
        secondary: { "bg-secondary": true },
        danger: { "bg-danger": true },
        warning: { "bg-warning": true },
        info: { "bg-info": true },
        white: { "bg-white": true },
    },
    important: {
        primary: { "!bg-primary": true },
        success: { "!bg-success": true },
        secondary: { "!bg-secondary": true },
        danger: { "!bg-danger": true },
        warning: { "!bg-warning": true },
        info: { "!bg-info": true },
        white: { "!bg-white": true },
    },
    before: {
        primary: { "before:bg-primary": true },
        success: { "before:bg-success": true },
        secondary: { "before:bg-secondary": true },
        danger: { "before:bg-danger": true },
        warning: { "before:bg-warning": true },
        info: { "before:bg-info": true },
        white: { "before:bg-white": true },
    },
    hover: {
        primary: { "hover:bg-primary": true },
        success: { "hover:bg-success": true },
        secondary: { "hover:bg-secondary": true },
        danger: { "hover:bg-danger": true },
        warning: { "hover:bg-warning": true },
        info: { "hover:bg-info": true },
        white: { "hover:bg-white": true },
    },
    importantHover: {
        primary: { "hover:!bg-primary": true },
        success: { "hover:!bg-success": true },
        secondary: { "hover:!bg-secondary": true },
        danger: { "hover:!bg-danger": true },
        warning: { "hover:!bg-warning": true },
        info: { "hover:!bg-info": true },
        white: { "hover:!bg-white": true },
    },
    fileHover: {
        primary: { "file:hover:bg-primary": true },
        success: { "file:hover:bg-success": true },
        secondary: { "file:hover:bg-secondary": true },
        danger: { "file:hover:bg-danger": true },
        warning: { "file:hover:bg-warning": true },
        info: { "file:hover:bg-info": true },
        white: { "file:hover:bg-white": true },
    }
})

const textClasses = Object.freeze({
    normal: {
        primary: { "text-primary": true },
        success: { "text-success": true },
        secondary: { "text-secondary": true },
        danger: { "text-danger": true },
        warning: { "text-warning": true },
        info: { "text-info": true },
        white: { "text-white": true },
    },
    dark: {
        primary: { "dark:text-primary": true },
        success: { "dark:text-success": true },
        secondary: { "dark:text-secondary": true },
        danger: { "dark:text-danger": true },
        warning: { "dark:text-warning": true },
        info: { "dark:text-info": true },
        white: { "dark:text-white": true },
    },
    important: {
        primary: { "!text-primary": true },
        success: { "!text-success": true },
        secondary: { "!text-secondary": true },
        danger: { "!text-danger": true },
        warning: { "!text-warning": true },
        info: { "!text-info": true },
        white: { "!text-white": true },
    },
    hover: {
        primary: { "hover:text-primary": true },
        success: { "hover:text-success": true },
        secondary: { "hover:text-secondary": true },
        danger: { "hover:text-danger": true },
        warning: { "hover:text-warning": true },
        info: { "hover:text-info": true },
        white: { "hover:text-white": true },
    },
    peerChecked: {
        primary: { "peer-checked:text-primary": true },
        success: { "peer-checked:text-success": true },
        secondary: { "peer-checked:text-secondary": true },
        danger: { "peer-checked:text-danger": true },
        warning: { "peer-checked:text-warning": true },
        info: { "peer-checked:text-info": true },
        white: { "peer-checked:text-white": true },
    },
})
const colorClasses = Object.freeze({
    normal: {
        primary: { "color-primary": true },
        success: { "color-success": true },
        secondary: { "color-secondary": true },
        danger: { "color-danger": true },
        warning: { "color-warning": true },
        info: { "color-info": true },
        white: { "color-white": true },
    },
    important: {
        primary: { "!color-primary": true },
        success: { "!color-success": true },
        secondary: { "!color-secondary": true },
        danger: { "!color-danger": true },
        warning: { "!color-warning": true },
        info: { "!color-info": true },
        white: { "!color-white": true },
    },
    hover: {
        primary: { "hover:color-primary": true },
        success: { "hover:color-success": true },
        secondary: { "hover:color-secondary": true },
        danger: { "hover:color-danger": true },
        warning: { "hover:color-warning": true },
        info: { "hover:color-info": true },
        white: { "hover:color-white": true },
    },
})

const borderClasses = Object.freeze({
    important: {
        primary: { "!border-primary": true },
        success: { "!border-success": true },
        secondary: { "!border-secondary": true },
        danger: { "!border-danger": true },
        warning: { "!border-warning": true },
        info: { "!border-info": true },
        white: { "!border-white": true },
    },
    hover: {
        primary: { "hover:border-primary": true },
        success: { "hover:border-success": true },
        secondary: { "hover:border-secondary": true },
        danger: { "hover:border-danger": true },
        warning: { "hover:border-warning": true },
        info: { "hover:border-info": true },
        white: { "hover:border-white": true },
    },
    importantHover: {
        primary: { "hover:!border-primary": true },
        success: { "hover:!border-success": true },
        secondary: { "hover:!border-secondary": true },
        danger: { "hover:!border-danger": true },
        warning: { "hover:!border-warning": true },
        info: { "hover:!border-info": true },
        white: { "hover:!border-white": true },
    },
    beforeL: {
        primary: { "before:border-l-primary": true },
        success: { "before:border-l-success": true },
        secondary: { "before:border-l-secondary": true },
        danger: { "before:border-l-danger": true },
        warning: { "before:border-l-warning": true },
        info: { "before:border-l-info": true },
        white: { "before:border-l-white": true },
    }
})
const text70Classes = Object.freeze({
    important: {
        primary: { "!text-primary/70": true },
        success: { "!text-success/70": true },
        secondary: { "!text-secondary/70": true },
        danger: { "!text-danger/70": true },
        warning: { "!text-warning/70": true },
        info: { "!text-info/70": true },
        white: { "!text-white/70": true },
    },
    hover: {
        primary: { "hover:text-primary/70": true },
        success: { "hover:text-success/70": true },
        secondary: { "hover:text-secondary/70": true },
        danger: { "hover:text-danger/70": true },
        warning: { "hover:text-warning/70": true },
        info: { "hover:text-info/70": true },
        white: { "hover:text-white/70": true },
    },
    importantHover: {
        primary: { "hover:!text-primary/70": true },
        success: { "hover:!text-success/70": true },
        secondary: { "hover:!text-secondary/70": true },
        danger: { "hover:!text-danger/70": true },
        warning: { "hover:!text-warning/70": true },
        info: { "hover:!text-info/70": true },
        white: { "hover:!text-white/70": true },
    },
})
const background90Classes = Object.freeze({
    file: {
        primary: { "file:bg-primary/90": true },
        success: { "file:bg-success/90": true },
        secondary: { "file:bg-secondary/90": true },
        danger: { "file:bg-danger/90": true },
        warning: { "file:bg-warning/90": true },
        info: { "file:bg-info/90": true },
        white: { "file:bg-white/90": true },
    },
})
const buttonClasses = Object.freeze({
    normal: {
        primary: { "btn-primary": true },
        success: { "btn-success": true },
        secondary: { "btn-secondary": true },
        danger: { "btn-danger": true },
        warning: { "btn-warning": true },
        info: { "btn-info": true },
        white: { "btn-white": true },
    },
    important: {
        primary: { "!btn-primary": true },
        success: { "!btn-success": true },
        secondary: { "!btn-secondary": true },
        danger: { "!btn-danger": true },
        warning: { "!btn-warning": true },
        info: { "!btn-info": true },
        white: { "!btn-white": true },
    },
    outline: {
        primary: { "btn-outline-primary": true },
        success: { "btn-outline-success": true },
        secondary: { "btn-outline-secondary": true },
        danger: { "btn-outline-danger": true },
        warning: { "btn-outline-warning": true },
        info: { "btn-outline-info": true },
        white: { "btn-outline-white": true },
    },
    importantOutline: {
        primary: { "!btn-outline-primary": true },
        success: { "!btn-outline-success": true },
        secondary: { "!btn-outline-secondary": true },
        danger: { "!btn-outline-danger": true },
        warning: { "!btn-outline-warning": true },
        info: { "!btn-outline-info": true },
        white: { "!btn-outline-white": true },
    },
})
const outlineClasses = Object.freeze({
    normal: {
        primary: { "outline-primary": true },
        success: { "outline-success": true },
        secondary: { "outline-secondary": true },
        danger: { "outline-danger": true },
        warning: { "outline-warning": true },
        info: { "outline-info": true },
        white: { "outline-white": true },
    },
    important: {
        primary: { "!outline-primary": true },
        success: { "!outline-success": true },
        secondary: { "!outline-secondary": true },
        danger: { "!outline-danger": true },
        warning: { "!outline-warning": true },
        info: { "!outline-info": true },
        white: { "!outline-white": true },
    },
})
const badgeClasses = Object.freeze({
    outline: {
        primary: { "badge-outline-primary": true },
        success: { "badge-outline-success": true },
        secondary: { "badge-outline-secondary": true },
        danger: { "badge-outline-danger": true },
        warning: { "badge-outline-warning": true },
        info: { "badge-outline-info": true },
        white: { "badge-outline-white": true },
    },
})

const addClass = (
    obj = {},
    type,
    modifier,
    color,
    isNested = false,
    nestedType,
    nestedModifier,
    nestedColor
) => {
    if (isNested)
        obj = addClass(obj, nestedType, nestedModifier, nestedColor, false);
    const ret = { ...obj, ...type[modifier][color] };
    return ret;
};
module.exports = {
    backgroundClasses,
    textClasses,
    borderClasses,
    text70Classes,
    buttonClasses,
    colorClasses,
    outlineClasses,
    background90Classes,
    badgeClasses,
    addClass
}