<template>
  <div>
    <Modal
      v-if="!!config.modalData && !!Object.keys(config.modalData).length"
      :config="modalConfig"
      :modalData="innerModalData"
      v-model:opened="innerModalOpened"
    >
      <template v-slot:modalBody v-if="innerModalBody">
        <div class="flex flex-col">
          <div class="flex flex-row mb-8">
            <p>{{ innerModalText }}</p>
          </div>
          <div class="grid grid-flow-col auto-cols-max justify-between">
            <MyButton text="Cancel" :config="cancelButtonConfig" />
            <MyButton text="Confirm" :config="confirmButtonConfig" />
          </div>
        </div>
      </template>
      <template v-slot:modalBody v-else>
        <slot name="modalBody"></slot>
      </template>
    </Modal>
    <div class="panel pb-1.5">
      <TableHeader
        v-model:searchString="searchString"
        v-model:cols="innerCols"
        :rows="rows"
        :config="config"
        @delete-selected="deleteSelection()"
      />
      <div class="datatable">
        <vue3-datatable
          ref="datatable"
          :pageSize="20"
          :rows="rows"
          :columns="innerCols"
          :totalRows="rows?.length"
          :sortable="config.sortable"
          :search="searchString"
          :stickyHeader="config.stickyHeader"
          :selectRowOnClick="config.selectRowOnClick"
          skin="whitespace-nowrap table-hover"
          :hasCheckbox="config.hasCheckbox"
          @rowSelect="handleSelectedRowsChange"
          @rowClick="clickedRowCB"
          firstArrow='<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-4.5 h-4.5 rtl:rotate-180"> <path d="M13 19L7 12L13 5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path opacity="0.5" d="M16.9998 19L10.9998 12L16.9998 5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>'
          lastArrow='<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-4.5 h-4.5 rtl:rotate-180"> <path d="M11 19L17 12L11 5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path opacity="0.5" d="M6.99976 19L12.9998 12L6.99976 5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg> '
          previousArrow='<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-4.5 h-4.5 rtl:rotate-180"> <path d="M15 5L9 12L15 19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>'
          nextArrow='<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-4.5 h-4.5 rtl:rotate-180"> <path d="M9 5L15 12L9 19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>'
        >
          <slot></slot>
          <template #dob="data">
            {{ formatDate(data.value.dob) }}
          </template>
          <template #isActive="data">
            <span
              class="capitalize"
              :class="data.value.isActive ? 'text-success' : 'text-danger'"
              >{{ data.value.isActive }}
            </span>
          </template>
          <template #actions="data" v-if="config.actions">
            <div class="flex gap-4 items-center justify-center">
              <router-link
                v-if="config.permissions.edit"
                :to="
                  ('/' + Trans.currentLocale || '/') +
                  config.actions.baseEditRoute +
                  (!!data.value[config.actions.baseEditField]
                    ? '/' + data.value[config.actions.baseEditField]
                    : '')
                "
                class="hover:text-info"
              >
                <Icon type="svg" name="edit" />
              </router-link>
              <router-link
                v-if="config.permissions.read"
                :to="
                  ('/' + Trans.currentLocale || '/') +
                  config.actions.baseViewRoute +
                  (data.value[config.actions.baseViewField]
                    ? '/' + data.value[config.actions.baseViewField]
                    : '')
                "
                class="hover:text-primary"
              >
                <Icon type="svg" name="view" />
              </router-link>
              <button
                v-if="config.permissions.delete"
                type="button"
                class="hover:text-danger"
                @click="deleteRow(`${data.value.id}`)"
              >
                <Icon type="svg" name="bin" />
              </button>
            </div>
          </template>
        </vue3-datatable>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import { ref, reactive, watch, computed } from "vue";
import TableHeader from "./TableHeader.vue";
import { validate } from "@/modules/routines.cjs";
import { Trans } from "@/modules/Translation";

export default {
  components: { TableHeader },
  props: {
    config: {
      type: Object,
      validator(v) {
        const validationObj = {
          downloads: {
            type: Boolean,
            default: false,
          },
          customCols: {
            type: Boolean,
            default: false,
          },
          search: {
            type: Boolean,
            default: true,
          },
          sortable: {
            type: Boolean,
            default: true,
          },
          hasCheckbox: {
            type: Boolean,
            default: false,
          },
          stickyHeader: {
            type: Boolean,
            default: true,
          },
          selectRowOnClick: {
            type: Boolean,
            default: false,
          },
          deleteCB: {
            type: Function,
            default: () => {},
          },
          CB: {
            type: Function,
            default: () => {},
          },
          cols: {
            type: Array,
            default: [
              {
                field: "id",
                title: "Id",
                isUnique: true,
                filter: false,
                hide: true,
              },
              {
                field: "firstName",
                title: "Firstname",
                hide: false,
              },
              {
                field: "lastName",
                title: "Lastname",
                hide: false,
              },
              {
                field: "company",
                title: "Company",
                hide: false,
              },
              {
                field: "age",
                title: "Age",
                hide: false,
              },
              {
                field: "dob",
                title: "Start Date",
                hide: false,
              },
              {
                field: "email",
                title: "Email",
                hide: false,
              },
              {
                field: "phone",
                title: "Phone No.",
                hide: false,
              },
            ],
          },
          modalData: {
            type: Object,
            default: reactive({
              title: "Confirm Deletion",
            }),
          },
        };
        return validate(v, validationObj);
      },
      default: reactive({}),
    },
    cols: {
      type: Array,
      default: [
        {
          field: "id",
          title: "Id",
          isUnique: true,
          filter: false,
          hide: true,
        },
        {
          field: "firstName",
          title: "Firstname",
          hide: false,
        },
        {
          field: "lastName",
          title: "Lastname",
          hide: false,
        },
        {
          field: "company",
          title: "Company",
          hide: false,
        },
        {
          field: "age",
          title: "Age",
          hide: false,
        },
        {
          field: "dob",
          title: "Start Date",
          hide: false,
        },
        {
          field: "email",
          title: "Email",
          hide: false,
        },
        {
          field: "phone",
          title: "Phone No.",
          hide: false,
        },
      ],
    },
    data: {
      type: Object,
      validator(v) {
        const validationObj = {
          rows: {
            type: Array,
            default: [
              {
                id: 1,
                firstName: "Caroline",
                lastName: "Jensen",
                email: "carolinejensen@zidant.com",
                dob: "2004-05-28",
                address: {
                  street: "529 Scholes Street",
                  city: "Temperanceville",
                  zipcode: 5235,
                  geo: {
                    lat: 23.806115,
                    lng: 164.677197,
                  },
                },
                phone: "+1 (821) 447-3782",
                isActive: false,
                age: 39,
                company: "POLARAX",
              },
              {
                id: 2,
                firstName: "Celeste",
                lastName: "Grant",
                email: "celestegrant@polarax.com",
                dob: "1989-11-19",
                address: {
                  street: "639 Kimball Street",
                  city: "Bascom",
                  zipcode: 8907,
                  geo: {
                    lat: 65.954483,
                    lng: 98.906478,
                  },
                },
                phone: "+1 (838) 515-3408",
                isActive: false,
                age: 32,
                company: "MANGLO",
              },
              {
                id: 3,
                firstName: "Tillman",
                lastName: "Forbes",
                email: "tillmanforbes@manglo.com",
                dob: "2016-09-05",
                address: {
                  street: "240 Vandalia Avenue",
                  city: "Thynedale",
                  zipcode: 8994,
                  geo: {
                    lat: -34.949388,
                    lng: -82.958111,
                  },
                },
                phone: "+1 (969) 496-2892",
                isActive: false,
                age: 26,
                company: "APPLIDECK",
              },
              {
                id: 4,
                firstName: "Daisy",
                lastName: "Whitley",
                email: "daisywhitley@applideck.com",
                dob: "1987-03-23",
                address: {
                  street: "350 Pleasant Place",
                  city: "Idledale",
                  zipcode: 9369,
                  geo: {
                    lat: -54.458809,
                    lng: -127.476556,
                  },
                },
                phone: "+1 (861) 564-2877",
                isActive: true,
                age: 21,
                company: "VOLAX",
              },
              {
                id: 5,
                firstName: "Weber",
                lastName: "Bowman",
                email: "weberbowman@volax.com",
                dob: "1983-02-24",
                address: {
                  street: "154 Conway Street",
                  city: "Broadlands",
                  zipcode: 8131,
                  geo: {
                    lat: 54.501351,
                    lng: -167.47138,
                  },
                },
                phone: "+1 (962) 466-3483",
                isActive: false,
                age: 26,
                company: "ORBAXTER",
              },
              {
                id: 6,
                firstName: "Buckley",
                lastName: "Townsend",
                email: "buckleytownsend@orbaxter.com",
                dob: "2011-05-29",
                address: {
                  street: "131 Guernsey Street",
                  city: "Vallonia",
                  zipcode: 6779,
                  geo: {
                    lat: -2.681655,
                    lng: 3.528942,
                  },
                },
                phone: "+1 (884) 595-2643",
                isActive: true,
                age: 40,
                company: "OPPORTECH",
              },
              {
                id: 7,
                firstName: "Latoya",
                lastName: "Bradshaw",
                email: "latoyabradshaw@opportech.com",
                dob: "2010-11-23",
                address: {
                  street: "668 Lenox Road",
                  city: "Lowgap",
                  zipcode: 992,
                  geo: {
                    lat: 36.026423,
                    lng: 130.412198,
                  },
                },
                phone: "+1 (906) 474-3155",
                isActive: true,
                age: 24,
                company: "GORGANIC",
              },
              {
                id: 8,
                firstName: "Kate",
                lastName: "Lindsay",
                email: "katelindsay@gorganic.com",
                dob: "1987-07-02",
                address: {
                  street: "773 Harrison Avenue",
                  city: "Carlton",
                  zipcode: 5909,
                  geo: {
                    lat: 42.464724,
                    lng: -12.948403,
                  },
                },
                phone: "+1 (930) 546-2952",
                isActive: true,
                age: 24,
                company: "AVIT",
              },
              {
                id: 9,
                firstName: "Marva",
                lastName: "Sandoval",
                email: "marvasandoval@avit.com",
                dob: "2010-11-02",
                address: {
                  street: "200 Malta Street",
                  city: "Tuskahoma",
                  zipcode: 1292,
                  geo: {
                    lat: -52.206169,
                    lng: 74.19452,
                  },
                },
                phone: "+1 (927) 566-3600",
                isActive: false,
                age: 28,
                company: "QUILCH",
              },
              {
                id: 10,
                firstName: "Decker",
                lastName: "Russell",
                email: "deckerrussell@quilch.com",
                dob: "1994-04-21",
                address: {
                  street: "708 Bath Avenue",
                  city: "Coultervillle",
                  zipcode: 1268,
                  geo: {
                    lat: -41.550295,
                    lng: -146.598075,
                  },
                },
                phone: "+1 (846) 535-3283",
                isActive: false,
                age: 27,
                company: "MEMORA",
              },
              {
                id: 11,
                firstName: "Odom",
                lastName: "Mills",
                email: "odommills@memora.com",
                dob: "2010-01-24",
                address: {
                  street: "907 Blake Avenue",
                  city: "Churchill",
                  zipcode: 4400,
                  geo: {
                    lat: -56.061694,
                    lng: -130.238523,
                  },
                },
                phone: "+1 (995) 525-3402",
                isActive: true,
                age: 34,
                company: "ZORROMOP",
              },
              {
                id: 12,
                firstName: "Sellers",
                lastName: "Walters",
                email: "sellerswalters@zorromop.com",
                dob: "1975-11-12",
                address: {
                  street: "978 Oakland Place",
                  city: "Gloucester",
                  zipcode: 3802,
                  geo: {
                    lat: 11.732587,
                    lng: 96.118099,
                  },
                },
                phone: "+1 (830) 430-3157",
                isActive: true,
                age: 28,
                company: "ORBOID",
              },
              {
                id: 13,
                firstName: "Wendi",
                lastName: "Powers",
                email: "wendipowers@orboid.com",
                dob: "1979-06-02",
                address: {
                  street: "376 Greenpoint Avenue",
                  city: "Elliott",
                  zipcode: 9149,
                  geo: {
                    lat: -78.159578,
                    lng: -9.835103,
                  },
                },
                phone: "+1 (863) 457-2088",
                isActive: true,
                age: 31,
                company: "SNORUS",
              },
              {
                id: 14,
                firstName: "Sophie",
                lastName: "Horn",
                email: "sophiehorn@snorus.com",
                dob: "2018-09-20",
                address: {
                  street: "343 Doughty Street",
                  city: "Homestead",
                  zipcode: 330,
                  geo: {
                    lat: 65.484087,
                    lng: 137.413998,
                  },
                },
                phone: "+1 (885) 418-3948",
                isActive: true,
                age: 22,
                company: "XTH",
              },
              {
                id: 15,
                firstName: "Levine",
                lastName: "Rodriquez",
                email: "levinerodriquez@xth.com",
                dob: "1973-02-08",
                address: {
                  street: "643 Allen Avenue",
                  city: "Weedville",
                  zipcode: 8931,
                  geo: {
                    lat: -63.185586,
                    lng: 117.327808,
                  },
                },
                phone: "+1 (999) 565-3239",
                isActive: true,
                age: 27,
                company: "COMTRACT",
              },
              {
                id: 16,
                firstName: "Little",
                lastName: "Hatfield",
                email: "littlehatfield@comtract.com",
                dob: "2012-01-03",
                address: {
                  street: "194 Anthony Street",
                  city: "Williston",
                  zipcode: 7456,
                  geo: {
                    lat: 47.480837,
                    lng: 6.085909,
                  },
                },
                phone: "+1 (812) 488-3011",
                isActive: false,
                age: 33,
                company: "ZIDANT",
              },
              {
                id: 17,
                firstName: "Larson",
                lastName: "Kelly",
                email: "larsonkelly@zidant.com",
                dob: "2010-06-14",
                address: {
                  street: "978 Indiana Place",
                  city: "Innsbrook",
                  zipcode: 639,
                  geo: {
                    lat: -71.766732,
                    lng: 150.854345,
                  },
                },
                phone: "+1 (892) 484-2162",
                isActive: true,
                age: 20,
                company: "SUREPLEX",
              },
              {
                id: 18,
                firstName: "Kendra",
                lastName: "Molina",
                email: "kendramolina@sureplex.com",
                dob: "2002-07-19",
                address: {
                  street: "567 Charles Place",
                  city: "Kimmell",
                  zipcode: 1966,
                  geo: {
                    lat: 50.765816,
                    lng: -117.106499,
                  },
                },
                phone: "+1 (920) 528-3330",
                isActive: false,
                age: 31,
                company: "DANJA",
              },
              {
                id: 19,
                firstName: "Ebony",
                lastName: "Livingston",
                email: "ebonylivingston@danja.com",
                dob: "1994-10-18",
                address: {
                  street: "284 Cass Place",
                  city: "Navarre",
                  zipcode: 948,
                  geo: {
                    lat: 65.271256,
                    lng: -83.064729,
                  },
                },
                phone: "+1 (970) 591-3039",
                isActive: false,
                age: 33,
                company: "EURON",
              },
              {
                id: 20,
                firstName: "Kaufman",
                lastName: "Rush",
                email: "kaufmanrush@euron.com",
                dob: "2011-07-10",
                address: {
                  street: "408 Kingsland Avenue",
                  city: "Beaulieu",
                  zipcode: 7911,
                  geo: {
                    lat: 41.513153,
                    lng: 54.821641,
                  },
                },
                phone: "+1 (924) 463-2934",
                isActive: false,
                age: 39,
                company: "ILLUMITY",
              },
              {
                id: 21,
                firstName: "Frank",
                lastName: "Hays",
                email: "frankhays@illumity.com",
                dob: "2005-06-15",
                address: {
                  street: "973 Caton Place",
                  city: "Dargan",
                  zipcode: 4104,
                  geo: {
                    lat: 63.314988,
                    lng: -138.771323,
                  },
                },
                phone: "+1 (930) 577-2670",
                isActive: false,
                age: 31,
                company: "SYBIXTEX",
              },
              {
                id: 22,
                firstName: "Carmella",
                lastName: "Mccarty",
                email: "carmellamccarty@sybixtex.com",
                dob: "1980-03-06",
                address: {
                  street: "919 Judge Street",
                  city: "Canby",
                  zipcode: 8283,
                  geo: {
                    lat: 9.198597,
                    lng: -138.809971,
                  },
                },
                phone: "+1 (876) 456-3218",
                isActive: true,
                age: 21,
                company: "ZEDALIS",
              },
              {
                id: 23,
                firstName: "Massey",
                lastName: "Owen",
                email: "masseyowen@zedalis.com",
                dob: "2012-03-01",
                address: {
                  street: "108 Seaview Avenue",
                  city: "Slovan",
                  zipcode: 3599,
                  geo: {
                    lat: -74.648318,
                    lng: 99.620699,
                  },
                },
                phone: "+1 (917) 567-3786",
                isActive: false,
                age: 40,
                company: "DYNO",
              },
              {
                id: 24,
                firstName: "Lottie",
                lastName: "Lowery",
                email: "lottielowery@dyno.com",
                dob: "1982-10-10",
                address: {
                  street: "557 Meserole Avenue",
                  city: "Fowlerville",
                  zipcode: 4991,
                  geo: {
                    lat: 54.811546,
                    lng: -20.996515,
                  },
                },
                phone: "+1 (912) 539-3498",
                isActive: true,
                age: 36,
                company: "MULTIFLEX",
              },
              {
                id: 25,
                firstName: "Addie",
                lastName: "Luna",
                email: "addieluna@multiflex.com",
                dob: "1988-05-01",
                address: {
                  street: "688 Bulwer Place",
                  city: "Harmon",
                  zipcode: 7664,
                  geo: {
                    lat: -12.762766,
                    lng: -39.924497,
                  },
                },
                phone: "+1 (962) 537-2981",
                isActive: true,
                age: 32,
                company: "PHARMACON",
              },
            ],
          },
        };
        return validate(v, validationObj);
      },
      default: reactive({}),
    },
    modalOpened: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const emit = ctx.emit;
    const searchString = ref("");

    const rows = ref(props.data.rows) || [];

    const innerCols = computed({
      get() {
        return props.cols;
      },
      set(value) {
        emit("update:cols", value);
      },
      value() {
        return props.cols;
      },
    });

    watch(
      () => props.data.rows,
      (nv) => {
        rows.value = nv;
      }
    );

    const formatDate = (date) => {
      if (date) {
        const dt = new Date(date);
        const month =
          dt.getMonth() + 1 < 10
            ? "0" + (dt.getMonth() + 1)
            : dt.getMonth() + 1;
        const day = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
        return day + "/" + month + "/" + dt.getFullYear();
      }
      return "";
    };

    const datatable = ref(null);
    const selectedRows = ref([]);
    const deleteSelection = async (item) => {
      innerModalTitleBu.value = props.config.modalData.title || "";
      selectedRows.value = datatable.value.getSelectedRows();
      if (selectedRows.value.length) {
        innerModalBody.value = true;
        innerModalTitle.value = "Deletion confirmation";
        innerModalText.value =
          selectedRows.value.length === 1
            ? "Are you sure want to delete selected row?"
            : "Are you sure want to delete selected rows?";
        emit("update:modalOpened", true);
      }
    };

    const innerModalOpened = computed({
      get() {
        return props.modalOpened;
      },
      set(value) {
        emit("update:modalOpened", value);
      },
    });
    const innerClickedRow = (e) => {
      e.isActive = !e.isActive;
      emit("rowClicked", e);
    };

    const clickedRowCB = (e) => {
      return props.config.clickedRow
        ? props.config.clickedRow(e)
        : innerClickedRow(e);
    };
    const innerModalTitle = ref(props.config?.modalData?.title || "");
    const innerModalTitleBu = ref("");
    const modalConfig = ref({
      size: "l",
      modalType: "modal", //modal, videoPlayer, login, profile, register, slider, standardCustom
      static: false,
      verticallyCentered: true,
      animation: {
        active: true,
        direction: "Up",
        fades: true,
        slides: false,
        rotates: false,
        zooms: true,
      },
    });
    const innerModalBody = ref(false);
    const innerModalText = ref("");
    const innerModalData = reactive({
      title: innerModalTitle,
    });
    const handleSelectedRowsChange = (rows) => {
      const dataLen = rows.length;
      emit("deleteButtonDisabled", dataLen > 0 ? false : true);
    };

    const deleteRow = (id) => props.config.deleteCB(id);
    const cancelButtonConfig = {
      themeColor: false,
      color: "red",
      callback: () => {
        emit("update:modalOpened", false);
        innerModalTitle.value = innerModalTitleBu.value;
      },
    };
    const confirmButtonConfig = {
      themeColor: true,
      callback: async () => {
        let ids;

        ids = selectedRows.value.map((d) => {
          return d.id;
        });
        rows.value = rows.value.filter((d) => !ids.includes(d.id));
        datatable.value.clearSelectedRows();
        const len = ids.length;
        if (len > 1) {
          try {
            await store.dispatch(
              props.config.deleteButton?.multipleAction,
              ids
            );
          } catch (e) {
            emit("update:modalOpened", false);
          }
        } else {
          try {
            await store.dispatch(
              props.config.deleteButton?.singleAction,
              ids[0]
            );
          } catch (e) {
            emit("update:modalOpened", false);
          }
        }
        selectedRows.value = [];

        innerModalTitle.value = innerModalTitle.value;
        innerModalBody.value = false;
        emit("update:modalOpened", false);
      },
    };

    return {
      rows,
      datatable,
      deleteSelection,
      formatDate,
      clickedRowCB,
      Trans,
      searchString,
      innerCols,
      deleteRow,
      innerModalOpened,
      modalConfig,
      innerModalData,
      handleSelectedRowsChange,
      innerModalTitle,
      innerModalBody,
      innerModalText,
      cancelButtonConfig,
      confirmButtonConfig,
      innerModalTitleBu,
    };
  },
};
</script>
