<template>
  <div class="px-5">
    <swiper
      :modules="[Navigation, Pagination]"
      :navigation="{
        nextEl: '.swiper-button-next-ex1',
        prevEl: '.swiper-button-prev-ex1',
      }"
      :pagination="{ clickable: true }"
      class="max-w-3xl mx-auto mb-5"
      id="slider1"
      :dir="rtlClass"
      :key="rtlClass === 'rtl' ? 'true' : 'false'"
    >
      <template v-for="(item, i) in carouselPics" :key="i">
        <swiper-slide>
          <img :src="`${item}`" class="w-full" alt="" />
        </swiper-slide>
      </template>
      <a
        href="javascript:;"
        class="swiper-button-prev-ex1 grid place-content-center ltr:left-2 rtl:right-2 p-1 transition text-primary hover:text-white border border-primary hover:border-primary hover:bg-primary rounded-full absolute z-[999] top-1/2 -translate-y-1/2"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="w-5 h-5 rtl:rotate-180"
        >
          <path
            d="M15 5L9 12L15 19"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </a>
      <a
        href="javascript:;"
        class="swiper-button-next-ex1 grid place-content-center ltr:right-2 rtl:left-2 p-1 transition text-primary hover:text-white border border-primary hover:border-primary hover:bg-primary rounded-full absolute z-[999] top-1/2 -translate-y-1/2"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="w-5 h-5 ltr:rotate-180"
        >
          <path
            d="M15 5L9 12L15 19"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </a>
    </swiper>
  </div>
</template>

<script>
import { computed } from "vue";
import store from "@/store";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
export default {
  props: {
    carouselPics: {
      type: Array,
      default: () => [
        "/assets/images/carousel1.jpeg",
        "/assets/images/carousel2.jpeg",
        "/assets/images/carousel3.jpeg",
      ],
    },
  },
  components: {
    swiper: Swiper,
    "swiper-slide": SwiperSlide,
  },
  setup() {
    const rtlClass = computed(() => store.getters.getRtlClass);
    return {
      rtlClass,
      Navigation,
      Pagination,
    };
  },
};
</script>

<style>
</style>