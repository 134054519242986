export default {
    sNotificationsCloseButton(state) {
        return state.sNotificationsCloseButton;
    },
    sNotificationsOkButton(state) {
        return state.sNotificationsOkButton;
    },
    sNotificationsVPosition(state) {
        return state.sNotificationsVPosition;
    },
    sNotificationsHPosition(state) {
        return state.sNotificationsHPosition;
    },
    sNotificationsDuration(state) {
        return state.sNotificationsDuration;
    },
    systemNotificationActiveStatus(state) {
        return state.systemNotificationActiveStatus;
    },
    systemNotificationMessage(state) {
        return state.systemNotificationMessage;
    },
    systemNotificationType(state) {
        return state.systemNotificationType;
    },
}