<template>
  <button
    @click="config.callback"
    :disabled="disabled"
    type="button"
    :class="buttonClass"
  >
    <Icon
      :class-name="iconClass"
      type="svg"
      v-if="config.hasIcon && config.iconPosition !== 'right'"
      :name="config.icon"
    />
    <template v-if="!(config.hasIcon && config.iconPosition === 'solo')">{{
      text
    }}</template>
    <Icon
      :class-name="iconClass"
      type="svg"
      v-if="config.hasIcon && config.iconPosition === 'right'"
      :name="config.icon"
    />
  </button>
</template>

<script>
import store from "@/store";
import { reactive, computed, ref, watch } from "vue";
import { validate } from "@/modules/routines.cjs";
import Icon from "./Icon.vue";
import { ColorClasses } from "@/components/ColorClasses";
import { buttonClasses, addClass } from "@/components/CompoundClasses.js";
export default {
  components: {
    Icon,
  },
  props: {
    config: {
      type: Object,
      validator(v) {
        const validationObj = {
          type: {
            // default, rounded
            type: String,
            default: "default",
          },
          themeColor: {
            type: Boolean,
            default: false,
          },
          color: {
            type: String,
            default: "blue",
          },
          disabled: {
            type: Boolean,
            default: false,
          },
          outline: {
            type: Boolean,
            default: false,
          },
          hasIcon: {
            type: Boolean,
            default: false,
          },
          icon: {
            type: String,
            default: "home",
          },
          iconPosition: {
            // left, right, solo
            type: String,
            default: "left",
          },
          fullWidth: {
            type: Boolean,
            default: false,
          },
          size: {
            type: String,
            default: "m",
          },
          callback: {
            type: Function,
            default: () => {
              console.log("works");
            },
          },
        };
        return validate(v, validationObj);
      },
      default: reactive({}),
    },
    text: { type: String, default: "Button" },
  },
  setup(props) {
    const color = ref(
      ColorClasses[
        props.config.themeColor
          ? store.getters.componentsDefaultColor
          : props.config.color
      ]
    );
    watch(
      () => props.config.themeColor,
      (newValue) => {
        color.value =
          ColorClasses[
            newValue ? store.getters.componentsDefaultColor : props.config.color
          ];
      }
    );
    watch(
      () => props.config.color,
      (newValue) => {
        color.value =
          ColorClasses[
            props.config.themeColor
              ? store.getters.componentsDefaultColor
              : newValue
          ];
      }
    );
    watch(
      () => store.getters.componentsDefaultColor,
      (newValue) => {
        color.value =
          ColorClasses[props.config.themeColor ? newValue : props.config.color];
      }
    );
    const iconClass = computed(() => {
      if (!props.config.hasIcon) return "";
      switch (props.config.iconPosition) {
        case "right":
          return "ltr:ml-1.5 rtl:mr-1.5";
        case "left":
          return "ltr:mr-1.5 rtl:ml-1.5";
        default:
          return "";
      }
    });
    const buttonClass = computed(() => {
      let outClass =
        "btn " +
        (props.config.type === "rounded" ? " rounded-full" : "") +
        (props.config.fullWidth ? " w-full" : "");
      switch (props.config.size) {
        case "s":
          outClass += " btn-sm";
          break;
        case "l":
          outClass += " btn-lg";
          break;
        default:
          break;
      }

      return [
        outClass,
        !props.config.disabled
          ? addClass(
              {},
              buttonClasses,
              props.config.outline ? "outline" : "normal",
              color.value
            )
          : props.config.outline
          ? "btn-outline-dark pointer-events-none"
          : "btn-dark pointer-events-none",
      ];
    });
    const disabled = computed(() => props.config.disabled);
    return {
      buttonClass,
      iconClass,
      disabled,
    };
  },
};
</script>

<style>
</style>