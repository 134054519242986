<template>
  <div class="bg-white dark:bg-[#0e1726] h-full">
    <menu-head />
    <perfect-scrollbar
      :options="{
        swipeEasing: true,
        wheelPropagation: false,
      }"
      class="h-[calc(100vh-80px)] relative"
    >
      <ul class="relative font-semibold p-4 py-0 flex flex-col h-full">
        <span v-for="voice in voices" :key="voice.id" class="space-y-0.5">
          <menu-title
            v-if="voice.isTitle"
            :locale-key="voice.localeKey"
          ></menu-title>
          <sub-menu
            v-else-if="voice.hasSubMenu"
            :locale-key="voice.localeKey"
            :sub-menu="voice.subMenuVoices"
            :icon="checkIcon(voice.icon)"
            :dropdown-id="voice.dropdownId"
            :active-dropdown="activeDropdown"
            :sub-active="subActive"
            @activeDropdownChange="changeActiveDropdown"
            @subActiveChange="changeSubActive"
          ></sub-menu>
          <menu-voice
            v-else
            :locale-key="voice.localeKey"
            :destination="voice.destination"
            :icon="checkIcon(voice.icon)"
          ></menu-voice>
        </span>
        <slot></slot>
        <menu-voice
          class="mt-auto"
          locale-key="logout"
          :callback="logout"
          :icon="checkIcon({ name: 'logout' })"
        ></menu-voice>
      </ul>
    </perfect-scrollbar>
  </div>
</template>

<script>
import MenuTitle from "./MenuTitle";
import SubMenu from "./SubMenu";
import MenuVoice from "./MenuVoice";
import MenuHead from "./MenuHead";

import { ref, computed } from "vue";
import store from "@/store";

export default {
  components: { MenuTitle, SubMenu, MenuVoice, MenuHead },
  props: {
    voices: Array,
    menuType: {
      type: String,
      default: "vertical",
    },
  },
  setup() {
    const activeDropdown = ref("");
    const subActive = ref("");

    const semidark = computed(() => store.getters.getSemidarkStatus);
    const changeActiveDropdown = ($event) => {
      activeDropdown.value = $event;
    };
    const changeSubActive = ($event) => {
      subActive.value = $event;
    };
    const checkIcon = computed(function () {
      return (icon) => (typeof icon === "string" ? JSON.parse(icon) : icon);
    });
    const logout = () => {
      store.dispatch("loaderPresent");
      store.dispatch("logout");
    };
    return {
      activeDropdown,
      subActive,
      semidark,
      checkIcon,
      changeActiveDropdown,
      changeSubActive,
      logout,
    };
  },
};
</script>

<style>
</style>