<template>
  <div :class="firstDivClass">
    <select name="" id="" @change="changeSystemColor">
      <option value="blue" selected>Blue</option>
      <option value="green">Green</option>
      <option value="red">Red</option>
      <option value="yellow">Yellow</option>
      <option value="purple">Purple</option>
    </select>
    <div
      v-for="(header, index) in config.headers"
      :class="secondDivClass"
      :key="header.text"
    >
      <button
        type="button"
        class="p-4 w-full flex items-center text-white-dark dark:bg-[#1b2e4b]"
        :class="isOpened(index) ? accordionActiveClass : null"
        @click="toggleAccordion(index)"
      >
        <Icon
          type="svg"
          v-if="config.hasIcon"
          :name="header.icon"
          :class-name="isOpened(index) ? iconActiveClass : iconClass"
          class="mr-4"
        />
        {{ header.text }}
        <div
          class="ltr:ml-auto rtl:mr-auto"
          :class="{ 'rotate-180': isOpened(index) }"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="w-4 h-4"
          >
            <path
              d="M19 9L12 15L5 9"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </button>
      <vue-collapsible :isOpen="isOpened(index)">
        <div :class="contentDivClass">
          <slot :name="header.name"></slot>
        </div>
      </vue-collapsible>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import { ref, reactive, watch, computed } from "vue";
import { validate } from "@/modules/routines.cjs";
import VueCollapsible from "vue-height-collapsible/vue3";
import { ColorClasses } from "@/components/ColorClasses";
import {
  backgroundClasses,
  textClasses,
  addClass,
} from "@/components/CompoundClasses.js";
export default {
  components: { VueCollapsible },
  props: {
    config: {
      type: Object,
      validator(v) {
        const validationObj = {
          spaced: {
            type: Boolean,
            default: true,
          },
          reverse: {
            type: Boolean,
            default: false,
          },
          hasIcon: {
            type: Boolean,
            default: false,
          },
          themeColor: {
            // if enabled uses the COMPONENTS_DEFAULT_COLOR global variable as default color
            type: Boolean,
            default: false,
          },
          color: {
            // only works if themeColor is equal to false
            type: String,
            default: "blue",
          },
          headers: {
            type: Array,
            default: [
              { name: "firstAccordion", text: "accordion1", icon: "Home" },
              { name: "secondAccordion", text: "accordion2", icon: "Settings" },
            ],
          },
          mutuallyExclusive: {
            type: Boolean,
            default: true,
          },
        };
        return validate(v, validationObj);
      },
      default: reactive({}),
    },
  },
  setup(props) {
    const color = ref(
      ColorClasses[
        props.config.themeColor
          ? store.getters.componentsDefaultColor
          : props.config.color
      ]
    );
    watch(
      () => props.config.themeColor,
      (newValue) => {
        color.value =
          ColorClasses[
            newValue ? store.getters.componentsDefaultColor : props.config.color
          ];
      }
    );
    watch(
      () => props.config.color,
      (newValue) => {
        color.value =
          ColorClasses[
            props.config.themeColor
              ? store.getters.componentsDefaultColor
              : newValue
          ];
      }
    );
    watch(
      () => store.getters.componentsDefaultColor,
      (newValue) => {
        color.value =
          ColorClasses[props.config.themeColor ? newValue : props.config.color];
      }
    );
    const activeAccordion = ref(null);
    const activeAccordions = ref([]);
    const accordionClass = computed(() => {
      if (!props.reverse) return " text-white-dark dark:bg-[#1b2e4b]";
      else return addClass({}, textClasses, "normal", color.value);
    });
    const accordionActiveClass = computed(() => {
      if (!props.config.reverse) {
        return addClass({}, textClasses, "important", color.value);
      } else
        return [
          "!text-white",
          addClass({}, backgroundClasses, "important", color.value),
        ];
    });
    const firstDivClass = computed(() => {
      let outClass = "font-semibold";
      if (!props.config.spaced)
        return `${outClass} border border-[#d3d3d3] dark:border-[#3b3f5c] rounded`;
      else return `${outClass} space-y-2`;
    });
    const secondDivClass = computed(() => {
      let outClass = "border-[#d3d3d3]";
      if (!props.config.spaced)
        return `${outClass} border-b dark:border-[#3b3f5c]`;
      else return `${outClass} border rounded dark:border-[#1b2e4b]`;
    });
    const contentDivClass = computed(() => {
      let outClass = "space-y-2 p-4 text-white-dark text-[13px]";
      if (!props.config.spaced) return outClass;
      else return `${outClass} border-t border-[#d3d3d3] dark:border-[#1b2e4b]`;
    });
    const iconClass = computed(() => {
      if (!props.config.hasIcon) return "";
      else return "text-white-dark";
    });
    const iconActiveClass = computed(() => {
      if (!props.config.hasIcon) return "";
      else if (props.config.reverse) {
        return "!text-white font-bold";
      } else
        return Object.keys(
          addClass({}, textClasses, "important", color.value)
        )[0];
    });
    const isOpened = computed(function () {
      return (index) => {
        return props.config.mutuallyExclusive
          ? activeAccordion.value === index
          : activeAccordions.value.includes(index);
      };
    });
    const toggleAccordion = (index) => {
      if (props.config.mutuallyExclusive) {
        activeAccordion.value === index
          ? (activeAccordion.value = null)
          : (activeAccordion.value = index);
      } else {
        if (activeAccordions.value.includes(index))
          activeAccordions.value = activeAccordions.value.reduce((o, n) => {
            if (n !== index) return [...o, n];
            else return o;
          }, []);
        else activeAccordions.value.push(index);
      }
    };
    const changeSystemColor = (e) => {
      const nc = e.target.children[e.target.selectedIndex].value;
      store.dispatch("setComponentsDefaultColor", nc);
    };
    return {
      activeAccordion,
      accordionClass,
      accordionActiveClass,
      color,
      secondDivClass,
      firstDivClass,
      contentDivClass,
      iconClass,
      iconActiveClass,
      isOpened,
      toggleAccordion,
      changeSystemColor,
    };
  },
};
</script>
