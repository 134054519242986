export default {
    userSession(state) {

        let accessToken;
        let refreshToken;
        let timestamp;
        let expiration;
        let loggedIn;
        if (state.accessToken === '') {
            const tokens = JSON.parse(localStorage.getItem("tokens"));
            if (tokens) {
                accessToken = (tokens.accessToken) ? tokens.accessToken : state.loginData.accessToken;
                refreshToken = (tokens.refreshToken) ? tokens.refreshToken : state.loginData.refreshToken;
                timestamp = (tokens.timestamp) ? tokens.timestamp : state.loginData.timestamp;
                expiration = (tokens.expiration) ? tokens.expiration : state.loginData.expiration;
                loggedIn = (tokens.loggedIn) ? tokens.loggedIn : state.loggedIn;
            } else {
                accessToken = state.loginData.accessToken;
                refreshToken = state.loginData.refreshToken;
                timestamp = state.loginData.timestamp;
                expiration = state.loginData.expiration;
                loggedIn = state.loggedIn;

            }
        } else {

            accessToken = state.loginData.accessToken;
            refreshToken = state.loginData.refreshToken;
            timestamp = state.loginData.timestamp;
            expiration = state.loginData.expiration;
            loggedIn = state.loggedIn;
        }
        return {
            accessToken,
            refreshToken,
            timestamp,
            expiration,
            loggedIn,
        }
    },
    isLoggedIn(state) {
        return state.loggedIn;
    },
}