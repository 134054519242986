export default {
    getDarkModeStatus(state) {
        return state.isDarkMode;
    },
    getMainLayout(state) {
        return state.mainLayout;
    },
    getTheme(state) {
        return state.theme;
    },
    getMenuOrientation(state) {
        return state.menuOrientation;
    },
    getLayout(state) {
        return state.layout;
    },
    getRtlClass(state) {
        return state.rtlClass;
    },
    getThemeAnimation(state) {
        return state.animation;
    },
    getThemeNavbar(state) {
        return state.navbar;
    },
    getSideBarStatus(state) {
        return state.sidebar;
    },
    getSemidarkStatus(state) {
        return state.semidark;
    },
    getLanguagesList(state) {
        return state.languageList;
    },
    getLocale(state) {
        return state.locale;
    },
    componentsDefaultColor(state) {
        return state.componentsDefaultColor;
    },
}