<template>
  <div class="p-5">
    <div class="text-white dark:text-white-light text-center">
      <div class="rounded-full w-20 h-20 mx-auto mb-7 overflow-hidden">
        <img
          src="/assets/images/profile-16.jpeg"
          alt=""
          class="w-full h-full object-cover"
        />
      </div>
      <slot name="modalBody"></slot>
      <p class="font-semibold">Click on view to access <br />your profile.</p>
    </div>
    <div class="flex justify-center items-center mt-8 mb-5">
      <slot name="modalButtons"></slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>