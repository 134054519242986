export default {
    logs(state) {
        return state.logs;
    },
    log(state) {
        return state.log;
    },
    systemLog(state) {
        return state.systemLog;
    },
}