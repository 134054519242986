<template>
  <div class="px-5 pt-5 text-right" v-if="modalType === 'profile'">
    <button
      type="button"
      class="text-gray-400 hover:text-gray-800 dark:hover:text-gray-600 outline-none"
      @click="modalValue = false"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="w-5 h-5"
      >
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
      </svg>
    </button>
  </div>
  <button
    type="button"
    class="absolute outline-none"
    :class="closeButtonClass"
    @click="modalValue = false"
    v-else
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
      :class="closeButtonSVGClass"
    >
      <line x1="18" y1="6" x2="6" y2="18"></line>
      <line x1="6" y1="6" x2="18" y2="18"></line>
    </svg>
  </button>
</template>

<script>
import { computed } from "vue";
export default {
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    modalType: {
      type: String,
      default: "modal",
    },
  },
  setup(props, ctx) {
    const emit = ctx.emit;
    const modalValue = computed({
      get() {
        return props.modal;
      },
      set(value) {
        emit("update:modal", value);
      },
    });
    const closeButtonClass = computed(() => {
      switch (props.modalType) {
        case "slider":
          return "top-7 ltr:right-5 rtl:left-5 text-white-dark hover:text-dark";
        case "register":
        case "login":
          return "top-7 ltr:right-9 rtl:left-9 text-white-dark hover:text-dark";
        default:
          return "top-4 ltr:right-4 rtl:left-4 text-gray-400 hover:text-gray-800 dark:hover:text-gray-600";
      }
    });
    const closeButtonSVGClass = computed(() => {
      switch (props.modalType) {
        case "slider":
        case "register":
        case "login":
          return "w-5 h-5";
        default:
          return "w-6 h-6";
      }
    });
    return {
      modalValue,
      closeButtonClass,
      closeButtonSVGClass,
    };
  },
};
</script>

<style>
</style>