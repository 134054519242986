<template>
  <div :class="[mainClass, { 'mt-8': atLeastOneIcon }]">
    <div
      v-for="(slot, index) in config.slots"
      class="panel h-full"
      :class="panelClass(slot, index)"
      :key="'panel-' + index"
    >
      <div
        v-if="slot.hasIcon"
        class="absolute text-white-light ltr:left-6 rtl:right-6 -top-8 w-16 h-16 rounded-md flex items-center justify-center mb-5 mx-auto"
        :class="iconContainerClass(slot)"
      >
        <Icon
          type="svg"
          :name="slot.icon"
          iconStyle="width:36px;height:36px;"
        />
      </div>
      <CardContent :title="slot.title">
        <slot :name="slot.name"></slot>
      </CardContent>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { ColorClasses } from "@/components/ColorClasses";
import { backgroundClasses, addClass } from "@/components/CompoundClasses.js";
import { reactive, computed, ref } from "vue";
import { validate } from "@/modules/routines.cjs";
export default {
  props: {
    config: {
      type: Object,
      validator(v) {
        const validationObj = {
          colsNum: {
            // 1, 2 or 3
            type: Number,
            default: 1,
          },
          themeColor: {
            type: Boolean,
            default: true,
          },
          layout: {
            // default, largerOnLeft, largerOnRight. Works on 3 cols only
            type: String,
            default: "default",
          },
          slots: {
            type: Array,
            default: [
              {
                name: "slot1",
                hasIcon: true,
                title: "",
                icon: "Home",
                color: "blue",
              },
              {
                name: "slot2",
                hasIcon: false,
                title: "",
                icon: "",
                color: "",
              },
              {
                name: "slot3",
                hasIcon: false,
                icon: "",
                color: "",
              },
              {
                name: "slot4",
                hasIcon: false,
                title: "",
                icon: "",
                color: "",
              },
              {
                name: "slot5",
                hasIcon: false,
                title: "",
                icon: "",
                color: "",
              },
            ],
          },
        };
        return validate(v, validationObj);
      },
      default: reactive({}),
    },
  },
  setup(props) {
    const atLeastOneIcon = computed(() => {
      return props.config.slots.reduce((ov, nv) => {
        return ov || nv.hasIcon;
      }, false);
    });

    const mainClass = computed(() => {
      let outClass = "pt-5 grid grid-cols-1";
      const colsNumber = props.config.colsNum;
      const layout = props.config.layout;
      outClass +=
        colsNumber === 3
          ? " md:grid-cols-3"
          : colsNumber === 2
          ? " lg:grid-cols-2"
          : " lg:grid-cols-1";
      if (colsNumber === 2 || (colsNumber === 3 && layout !== "standard"))
        outClass += " gap-6";
      else if (colsNumber === 3) outClass += " gap-5";
      return outClass;
    });

    const panelClass = computed(function () {
      return (slot, index) => {
        let outClass = slot.hasIcon ? "pt-12" : "";
        const layout = props.config.layout;
        const colsNumber = props.config.colsNum;
        if (colsNumber === 1) return `${outClass} mb-4`;
        if (layout === "default" || colsNumber !== 3) return outClass;
        const divider = layout === "largerOnLeft" ? 0 : 1;
        return index % 2 === divider ? `${outClass} md:col-span-2` : outClass;
      };
    });

    const iconContainerClass = computed(function () {
      return (slot) => {
        const color = ref(
          ColorClasses[
            props.config.themeColor
              ? store.getters.componentsDefaultColor
              : slot.color
          ]
        );
        return addClass({}, backgroundClasses, "normal", color.value);
      };
    });

    return {
      atLeastOneIcon,
      mainClass,
      panelClass,
      iconContainerClass,
    };
  },
};
</script>

<style>
</style>