
import { getRequest, patchRequest, postRequest, deleteRequest, loaderDismiss, getObjResponse, encodeHash256 } from '@/modules/routines.cjs'
export default {

    retrieveUsers(context, loader = false) {
        return new Promise((resolve, reject) => {

            if (loader) context.dispatch('loaderPresent');
            getRequest(`${process.env.VUE_APP_SERVER_API_PATH}users`, null, (e) => {
                if (!e) resolve(false);
                else {
                    context.commit('setUsers', { users: e });
                    if (loader) context.dispatch('loaderDismiss');
                    resolve(true)
                }
            }, () => {
                if (loader) context.dispatch('loaderDismiss');
                reject(false)
            })
        })
    },
    retrieveAssociatedLabkeyBuildings(context, payload) {

        return new Promise((resolve, reject) => {
            const { id, loader } = payload;
            if (loader) context.dispatch('loaderPresent');
            getRequest(`${process.env.VUE_APP_SERVER_API_PATH}labkey/user-varcos/${id}`, null, (e) => {
                context.commit('setAssociatedLabkeyBuildings', e)
                if (loader) context.dispatch('loaderDismiss');
                resolve(true)
            }, () => {
                if (loader) context.dispatch('loaderDismiss');
                reject(false)
            })
        })
    },
    async selectUser(context, id = '') {
        if (!context.getters.users.length) await context.dispatch("retrieveUsers")
        if (context.getters.users.length) context.commit('setUser', id);
        else context.commit('setUser', '');
    },
    async retrieveExtendedUserData(context, loader = true) {
        const user = context.getters.user;
        if (!user) return;

        return new Promise((resolve, reject) => {
            if (loader) context.dispatch('loaderPresent');
            const { id } = user;
            getRequest(`${process.env.VUE_APP_SERVER_API_PATH}user-extended-data/${id}`, null, (e) => {
                if (!e) resolve(false);
                else {
                    console.log({ e, id: e.id, context })
                    context.state.user = { ...e };
                    //context.state.user.extendedUserData = e.extendedUserData;
                    context.commit('editUser', e);
                    /* 
                    context.dispatch("selectUser", e.id); */
                    if (loader) context.dispatch('loaderDismiss');
                    resolve(true)
                }
            }, () => {
                if (loader) context.dispatch('loaderDismiss');
                reject(false)
            },
                null,
                true
            )
        })

        /* if (!context.getters.users.length) await context.dispatch("retrieveUsers")
        if (context.getters.users.length) context.commit('setUser', id);
        else context.commit('setUser', ''); */
    },

    async editUser(context, user, loader = true) {

        return new Promise((resolve, reject) => {
            if (loader) context.dispatch('loaderPresent');
            let fd = new FormData();
            fd.append("user", user.value);
            const id = user.value.id;
            patchRequest(`${process.env.VUE_APP_SERVER_API_PATH}user/${id}`, null, { ...user.value }, (e) => {
                if (!e) resolve(false);
                else {
                    context.commit('editUser', user.value)
                    if (loader) context.dispatch('loaderDismiss');
                    resolve(true)
                }
            }, () => {
                if (loader) context.dispatch('loaderDismiss');
                reject(false)
            },
                null,
                true
            )
        })
    },
    async resendSMS(context, user, loader = true) {
        return new Promise((resolve, reject) => {
            if (loader) context.dispatch('loaderPresent');
            const id = user.id
            delete user.id;

            patchRequest(`${process.env.VUE_APP_SERVER_API_PATH}resendSMS/${id}`, null, { cellphone: user.contact }, (e) => {
                if (!e) resolve(false);
                else {
                    if (loader) context.dispatch('loaderDismiss');
                    resolve(true)
                }
            }, () => {
                if (loader) context.dispatch('loaderDismiss');
                reject(false)
            },
                null,
                true
            )
        })
    },
    async resendEmail(context, user, loader = true) {
        return new Promise((resolve, reject) => {
            if (loader) context.dispatch('loaderPresent');
            const id = user.id;
            delete user.id;
            patchRequest(`${process.env.VUE_APP_SERVER_API_PATH}resendEmail/${id}`, null, { email: user.contact }, (e) => {
                if (!e) {
                    if (loader) context.dispatch('loaderDismiss');
                    reject(false);
                }
                else {
                    if (loader) context.dispatch('loaderDismiss');
                    resolve(e); //return 'pippo';
                }
            }, () => {
                if (loader) context.dispatch('loaderDismiss');
                reject(false)
            },
                null,
                true
            )
        })
    },
    async createUser(context, user, loader = true) {
        if (user.userType === "operator") {
            user.password = await encodeHash256(user.password);
        }
        return new Promise((resolve, reject) => {
            if (loader) context.dispatch('loaderPresent');

            postRequest(`${process.env.VUE_APP_SERVER_API_PATH}user`, { ...user }, (e) => {
                if (!e) resolve(false);
                else {
                    context.commit('addUser', { id: e, ...user });
                    if (loader) context.dispatch('loaderDismiss');
                    resolve(true)
                }
            }, () => {
                if (loader) context.dispatch('loaderDismiss');
                reject(false)
            },
                null,
                true
            )
        })
    },
    async deleteUser(context, id, loader = true) {
        return new Promise((resolve, reject) => {
            if (loader) context.dispatch('loaderPresent');

            deleteRequest(`${process.env.VUE_APP_SERVER_API_PATH}user/${id}`, null, async (e) => {
                if (!e) resolve(false);
                else {
                    if (typeof e === 'string') context.commit("deleteUser", id);
                    else await context.dispatch("retrieveUsers", false)
                    if (loader) context.dispatch('loaderDismiss');
                    resolve(true)
                }
            }, () => {
                if (loader) context.dispatch('loaderDismiss');
                reject(false)
            },
                null,
                true)
        })
    },
    async deleteUsers(context, users, loader = true) {
        return new Promise((resolve, reject) => {
            if (loader) context.dispatch('loaderPresent');

            deleteRequest(`${process.env.VUE_APP_SERVER_API_PATH}users/${JSON.stringify(users)}`, null, async (e) => {
                if (!e || !e.length) reject(false);
                else {
                    if (Array.isArray(e)) {
                        let found = false;
                        e.forEach(user => {
                            if (typeof user === 'object') found = true;
                        });
                        if (found) await context.dispatch("retrieveUsers");
                        else context.commit("deleteUsers", e);
                    }
                    if (loader) context.dispatch('loaderDismiss');
                    resolve(true)
                }
            }, () => {
                if (loader) context.dispatch('loaderDismiss');
                reject(false)
            },
                null,
                true)
        })
    },
}