<template>
  <div
    :class="[containerClass, 'w-full !m-0', regexpClass]"
    v-if="type !== 'radio' && type !== 'checkbox'"
    v-show="config.type !== 'hidden'"
  >
    <label
      v-if="
        config.type !== 'hidden' &&
        config.hasLabel &&
        (!config.hasEmbeddedLabel || !!data.embeddedLabel)
      "
      :for="config.name || config.id || null"
      >{{ data.label }}</label
    >
    <div
      class="flex"
      v-if="config.hasEmbeddedLabel && (data.embeddedLabel || data.label)"
    >
      <div
        class="bg-[#eee] flex justify-center items-center ltr:rounded-l-md rtl:rounded-r-md px-3 font-semibold border ltr:border-r-0 rtl:border-l-0 border-[#e0e6ed] dark:border-[#17263c] dark:bg-[#1b2e4b]"
      >
        {{ data.embeddedLabel || data.label }}
      </div>
      <input
        :id="config.id || null"
        :name="config.name || null"
        :type="type"
        :placeholder="data.placeholder || null"
        :required="config.required"
        :min="minValue"
        :max="maxValue"
        :disabled="config.disabled"
        :readonly="config.readonly"
        :class="`${dynamicClass} ${extraInputClass}`"
        v-model="inputInnerValue"
        :data-regex="inputRegex"
        :data-ref="config.ref"
      />
    </div>
    <input
      v-else
      v-show="config.type !== 'hidden'"
      :id="config.id || null"
      :name="config.name || null"
      :type="type"
      :placeholder="data.placeholder || null"
      :required="config.required"
      :min="minValue"
      :max="maxValue"
      :disabled="config.disabled"
      :readonly="config.readonly"
      :class="[dynamicClass, extraInputClass]"
      v-model="inputInnerValue"
      :data-regex="inputRegex"
      :data-ref="config.ref"
    />
    <span v-if="config.hasHelper" :class="helperClass">{{
      data.helperText
    }}</span>
    <span
      v-if="regexpError"
      :class="errorsClass"
      v-html="data.errorText"
    ></span>
  </div>
  <div :class="[containerClass, 'w-full']" v-else>
    <label
      :for="config.name || config.id || null"
      :class="{
        'flex items-center': config.type !== 'switch',
        'w-12 h-6 relative': config.type === 'switch',
      }"
    >
      <input
        :id="config.id || null"
        :name="config.name || null"
        :type="type"
        :disabled="config.disabled"
        :class="[
          dynamicClass,
          extraInputClass,
          checkBoxInputClass,
          config.type !== 'switch' ? 'cursor-pointer' : '',
        ]"
        :checked="checked"
        v-model="inputInnerValue"
        :data-val="inputInnerValue"
        @change="switchChecked"
        :data-regex="inputRegex"
        :data-ref="config.ref"
      />

      <!-- data:{{ data }}<br /><br />
      config:{{ config }}<br /><br />
      value:{{ value }}<br /><br />
      checked:{{ checked }}<br /><br />
      innerChecked:{{ innerChecked }}<br /><br /> -->
      <!-- :checked="checked" -->
      <span
        :class="[`${spanSwitchClass}`, getSwitchAppearanceClasses]"
        v-if="config.type === 'switch'"
      ></span>
      <span :class="checkBoxLabelClass" v-else-if="config.hasLabel">{{
        data.label
      }}</span>
    </label>
    <span v-if="config.hasHelper" :class="helperClass">{{
      data.helperText
    }}</span>
    <span
      v-if="regexpError"
      :class="errorsClass"
      v-html="data.errorText"
    ></span>
  </div>
</template>

<script>
import { fieldsRegex } from "@/modules/regex";
import store from "@/store";
import { computed, ref, watch, onMounted } from "vue";
import { validate } from "@/modules/routines.cjs";
import { ColorClasses } from "../ColorClasses";
import {
  backgroundClasses,
  textClasses,
  outlineClasses,
  background90Classes,
  addClass,
} from "../CompoundClasses";
import {
  DefaultClasses,
  OutlineClasses,
  IconClasses,
  IconOutlineClasses,
} from "./SwitchColorClasses";
export default {
  emits: ["regexpError", "update:value"],
  props: {
    config: {
      type: Object,
      validator(v) {
        const validationObj = {
          type: {
            // text password, email, url, tel, search, range (if so, min and max become required), radio, checkbox
            type: String,
            default: "text",
          },
          toTest: {
            type: Boolean,
            default: true,
          },
          required: {
            type: Boolean,
            default: false,
          },
          disabled: {
            type: Boolean,
            default: false,
          },
          readonly: {
            type: Boolean,
            default: false,
          },
          checked: {
            type: Boolean,
            default: false,
          },
          hasLabel: {
            type: Boolean,
            default: false,
          },
          hasHelper: {
            type: Boolean,
            default: false,
          },
          isHorizontal: {
            type: Boolean,
            default: false,
          },
          isRounded: {
            type: Boolean,
            default: true,
          },
          hasEmbeddedLabel: {
            type: Boolean,
            default: false,
          },
          helperType: {
            // default, badge, fullBadge, inline
            type: String,
            default: "default",
          },
          errorType: {
            // default, badge, fullBadge, inline
            type: String,
            default: "default",
          },
          cAndRType: {
            //default, inverted, outline, invertedOutline
            type: String,
            default: "default",
          },
          color: {
            // blue, green, purple, red, yellow, lightBlue, darkGray
            type: String,
            default: "blue",
          },
          themeColor: {
            type: Boolean,
            default: false,
          },
          cAndRColoredLabel: {
            type: Boolean,
            default: false,
          },
          returnsInitialValue: {
            // only works on checkboxes
            type: Boolean,
            default: false,
          },
          size: {
            // s, m, l
            type: String,
            default: "m",
          },
          id: {
            type: String,
          },
          name: {
            type: String,
          },
        };
        return validate(v, validationObj);
      },
      default: () => {},
    },
    data: {
      type: Object,
      validator(v) {
        const validationObj = {
          label: {
            type: String,
          },
          embeddedLabel: {
            type: String,
          },
          placeholder: {
            type: String,
            default: "Place your text here...",
          },
          helperText: {
            type: String,
            default: "I am the helper text.",
          },
          errorText: {
            type: String,
            default: "This is an error.",
          },
          min: {
            type: Number,
            default: 0,
          },
          max: {
            type: Number,
            default: 100,
          },
          initialValue: {
            type: String,
            default: "",
          },
        };
        return validate(v, validationObj);
      },
      default: () => {},
    },
    value: {
      type: [String, Number, Boolean],
      default: null,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    isInError: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const emit = ctx.emit;
    const regexpError = ref(false);
    const color = ref(
      ColorClasses[
        props.config.themeColor
          ? store.getters.componentsDefaultColor
          : props.config.color
      ]
    );
    watch(
      () => props.config.themeColor,
      (newValue) => {
        color.value =
          ColorClasses[
            newValue ? store.getters.componentsDefaultColor : props.config.color
          ];
      }
    );
    watch(
      () => props.config.color,
      (newValue) => {
        color.value =
          ColorClasses[
            props.config.themeColor
              ? store.getters.componentsDefaultColor
              : newValue
          ];
      }
    );
    watch(
      () => store.getters.componentsDefaultColor,
      (newValue) => {
        color.value =
          ColorClasses[props.config.themeColor ? newValue : props.config.color];
      }
    );
    onMounted(() => {
      if (props.config.type === "checkbox") {
        let elem;
        if (document.querySelector(`#${props.config.id}`) !== null) {
          elem = document.querySelector(`#${props.config.id}`);
        } else if (
          document.querySelector(`[name="${props.config.name}"`) !== null
        ) {
          elem = document.querySelector(`[name="${props.config.name}"`);
        }
        if (!!elem) {
          elem.checked = props.checked;
        }
      }
      return props.checked;
    });
    const innerChecked = computed(() => {});
    const regexpClass = computed(() => {
      if (inputInnerValue !== "file") {
        if (type.value !== "file") {
          const test = testValue(inputInnerValue.value, type.value);
          const check = test === "has-error";
          regexpError.value = check || props.isInError;
          if (check || props.isInError) emit("regexpError", 400);
          else emit("regexpError", 200);
          return test;
        }
      }
      return "";
    });
    let valueChanged = false;

    const testValue = (value, type) => {
      const { required, toTest, id } = props.config;

      if ((value === undefined || value === null || value === "") && required) {
        if (valueChanged) return "has-error";
        else {
          valueChanged = true;
          return "";
        }
      }
      if (
        ((value !== undefined && value !== null && value !== "") || required) &&
        toTest
      ) {
        const fieldsToCheck = Object.keys(fieldsRegex);
        if (fieldsToCheck.includes(type)) {
          const regex = new RegExp(
            props.config.useCustomRegexp
              ? props.config.regExp
              : fieldsRegex[type]
          );
          if (regex.test(value)) {
            return "has-success";
          } else {
            return "has-error";
          }
        } else {
          return "";
        }
      }
      return "";
    };

    const type = computed(() =>
      props.config.type === "switch" ? "checkbox" : props.config.type
    );

    const minValue = computed(() => {
      return props.config.type !== "range" ? null : props.data.min;
    });
    const maxValue = computed(() => {
      return props.config.type !== "range" ? null : props.data.max;
    });

    const file = ref(null);
    const inputInnerValue = computed({
      get() {
        return props.config.type !== "radio"
          ? props.value
          : props.data.initialValue;
      },
      set(value) {
        if (props.config.type !== "radio" && props.config.type !== "checkbox")
          emit("update:value", value);
        else if (props.config.type === "checkbox") {
          const valueToEmit = !props.config.returnsInitialValue
            ? value
            : value
            ? props.data.initialValue
            : null;
          emit("update:value", valueToEmit);
        }
      },
    });

    watch(inputInnerValue, (newValue) => {
      if (props.config.type !== "file") return;
      if (newValue === null) file.value = null;
      const files =
        document.querySelector(`#${props.config.id}`)?.files || null;
      if (files === null) return;
      emit("fileChanged", files);
    });

    /********************* DYNAMIC CLASSES COMPUTED *********************/

    /* MAIN CONTAINER DYNAMIC CLASS */
    const containerClass = computed(() =>
      props.config.isHorizontal
        ? "sm:flex justify-between items-center gap-5 md:gap-20"
        : ""
    );
    /* BASE INPUT CLASS SETUP */
    const dynamicClass = computed(() => {
      let outClass = "";
      let outClassObj = null;
      switch (props.config.type) {
        case "file":
          outClass =
            "form-input file:py-2 file:px-4 file:border-0 file:font-semibold p-0 ltr:file:mr-5 rtl:file-ml-5 file:text-white cursor-pointer";
          outClassObj = addClass(
            outClassObj,
            background90Classes,
            "file",
            color.value
          );
          outClassObj = addClass(
            outClassObj,
            backgroundClasses,
            "fileHover",
            color.value
          );
          break;
        case "switch":
          outClass =
            "custom_switch absolute w-full h-full opacity-0 z-10 cursor-pointer peer";
          break;
        case "checkbox":
          outClass = "form-checkbox";
          break;
        case "radio":
          outClass = "form-radio";
          break;
        case "range":
          outClass = "w-full py-2.5";
          break;
        default:
          outClass =
            "form-input" +
            (props.config.hasEmbeddedLabel &&
            (props.data.embeddedLabel || props.data.label)
              ? " ltr:rounded-l-none rtl:rounded-r-none"
              : "");
          break;
      }
      switch (props.config.size) {
        case "l":
          return outClassObj !== null
            ? [`${outClass} form-input-lg`, outClassObj]
            : `${outClass} form-input-lg`;
        case "s":
          return outClassObj !== null
            ? [`${outClass} form-input-sm`, outClassObj]
            : `${outClass} form-input-sm`;
        default:
          return outClassObj !== null ? [outClass, outClassObj] : outClass;
      }
    });
    /* INPUT CLASS SETUP FOR HELPER */
    const extraInputClass = computed(() => {
      if (!props.config.hasHelper && !regexpError.value) return "";
      switch (props.config.helperType) {
        case "fullBadge":
        case "badge":
          return "mb-2";
        case "inline":
          return "w-3/5";
        default:
          return ``;
      }
    });
    /* HELPER'S DYNAMIC CLASS */
    const helperClass = computed(() => {
      let outClass = "text-xs";
      switch (props.config.helperType) {
        case "inline":
          return `${outClass} text-white-dark ltr:pl-2 rtl:pr-2`;
        case "fullBadge":
          outClass += " block";
        case "badge":
          outClass += " badge hover:top-0";
          return [
            outClass,
            addClass({}, backgroundClasses, "normal", color.value),
          ];
        default:
          return `text-white-dark ${outClass}`;
      }
    });
    /* ERROR'S DYNAMIC CLASS */
    const errorsClass = computed(() => {
      let outClass = "text-xs text-danger";
      switch (props.config.errorType) {
        case "inline":
          return `${outClass} text-white-dark ltr:pl-2 rtl:pr-2`;
        case "fullBadge":
          outClass += " block";
        case "badge":
          outClass += " badge hover:top-0";
          return [outClass, addClass({}, backgroundClasses, "normal", "red")];
        default:
          return `${outClass}`;
      }
    });
    /* COLOR CLASS SETUP FOR CHECKBOXES' LABELS */
    const checkBoxLabelClass = computed(() => {
      if (!props.config.cAndRColoredLabel) return "text-white-dark";
      return addClass({}, textClasses, "peerChecked", color.value);
    });
    /* APPEARANCE CLASS SETUP FOR CHECKBOXES AND RADIOS */
    const checkBoxInputClass = computed(() => {
      if (props.config.type === "switch") return "";
      let outClass = "";
      let outClassObj = null;
      switch (props.config.cAndRType) {
        case "invertedOutline":
          outClass +=
            props.config.type === "checkbox"
              ? ` rounded-full`
              : ` rounded-none`;
        case "outline":
          outClassObj = addClass(
            outClassObj,
            outlineClasses,
            "normal",
            color.value
          );
          break;
        case "inverted":
          outClass +=
            props.config.type === "checkbox"
              ? ` rounded-full`
              : ` rounded-none`;
        default:
          outClassObj = addClass(
            outClassObj,
            textClasses,
            "normal",
            color.value
          );
          break;
      }
      if (props.config.cAndRColoredLabel) outClass += " peer";

      return outClassObj !== null ? [outClass, outClassObj] : outClass;
    });
    /* BACKGROUND PICTURE CLASS SETUP FOR SWITCHES WITH ICON */
    const getSwitchAppearanceClasses = computed(() => {
      if (!props.config.cAndRType) return "";
      switch (props.config.cAndRType) {
        case "outline":
          return OutlineClasses[color.value];
        case "icon":
          return IconClasses[color.value];
        case "iconOutline":
          return IconOutlineClasses[color.value];
        default:
          return DefaultClasses[color.value];
      }
    });
    /* APPEARANCE CLASS SETUP FOR SWITCHES */
    const spanSwitchClass = computed(() => {
      if (!props.config.cAndRType) return "";
      /* COMMON */
      let outClass = `block h-full before:absolute before:left-1 dark:before:bg-white-dark before:bottom-1 before:w-4 before:h-4 peer-checked:before:left-7  before:transition-all before:duration-300 `;

      /* OUTLINE COMMON */
      const outlineCommonClass = `outline_checkbox border-2 border-[#ebedf2] dark:border-white-dark before:bg-[#ebedf2]`;
      switch (props.config.cAndRType) {
        case "iconOutline":
          outClass += `bg-icon before:bg-[url(/assets/images/close.svg)] before:bg-no-repeat before:bg-center ${outlineCommonClass}`;
          break;
        case "outline":
          outClass += `${outlineCommonClass}`;
          break;
        case "icon":
          outClass += `bg-icon before:bg-[url(/assets/images/close.svg)] dark:bg-dark before:bg-no-repeat before:bg-center`;
          break;
        default:
          outClass += `bg-[#ebedf2] dark:bg-dark before:bg-white`;
          break;
      }
      if (props.config.isRounded)
        outClass += ` rounded-full before:rounded-full`;
      return outClass;
    });

    /********************* END OF DYNAMIC CLASSES COMPUTED *********************/

    const inputRegex = computed(() =>
      props.config.useCustomRegexp
        ? props.config.regExp
        : fieldsRegex[props.config.type]
    );
    const switchChecked = (e) => {
      if (props.config.type === "switch") {
        e.stopPropagation();
        emit("update:value", e.target.checked);
        emit("change", e);
      }
      if (props.config.type === "radio") {
        e.stopPropagation();
        emit("update:value", e.target.dataset.val);
        emit("change", e);
      }
    };

    return {
      minValue,
      maxValue,
      dynamicClass,
      helperClass,
      extraInputClass,
      containerClass,
      inputInnerValue,
      testValue,
      file,
      checkBoxLabelClass,
      checkBoxInputClass,
      type,
      spanSwitchClass,
      getSwitchAppearanceClasses,
      switchChecked,
      regexpClass,
      regexpError,
      errorsClass,
      inputRegex,
      innerChecked,
    };
  },
};
</script>

<style>
</style>