import getters from "./getters"
import actions from "./actions"
import mutations from "./mutations"
export default {
  state:{
    menu:null,
    menuOpened: true,
  },
  getters,
  mutations,
  actions
}
