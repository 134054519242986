export default {
    breadcrumsType(state) {
        return state.breadcrumsType;
    },
    breadcrumbsThemeColor(state) {
        return state.breadcrumbsThemeColor;
    },
    breadcrumbsColor(state) {
        return state.breadcrumbsColor;
    },
}