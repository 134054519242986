<template>
  <div>
    <div class="panel px-0 pb-1.5 border-[#e0e6ed] dark:border-[#1b2e4b]">
      <div class="datatable">
        <MyDataTable
          :config="configuration"
          @row-clicked="clickedRow"
          :data="data"
          v-model:cols="calculateCols"
          v-model:modalOpened="innerModalOpened"
          @deleteButtonDisabled="deleteButtonDisabled = $event"
          ><template v-slot:modalBody>
            <slot name="modalBody"></slot>
          </template>
        </MyDataTable>
      </div>
    </div>
  </div>
</template>
<script>
import { validate } from "@/modules/routines.cjs";
import { computed, reactive, ref, watch } from "vue";
import { useMeta } from "@/composables/use-meta";

export default {
  props: {
    config: {
      type: Object,
      validator(v) {
        const validationObj = {
          title: {
            type: String,
            default: "Title",
          },
          canAdd: {
            type: Boolean,
            default: false,
          },
          canView: {
            type: Boolean,
            default: false,
          },
          canEdit: {
            type: Boolean,
            default: false,
          },
          canDelete: {
            type: Boolean,
            default: false,
          },
          baseViewIdentifier: {
            type: String,
            default: "id",
          },
          baseEditIdentifier: {
            type: String,
            default: "id",
          },
          baseAddRoute: {
            type: String,
            default: "",
          },
          baseViewRoute: {
            type: String,
            default: "",
          },
          baseEditRoute: {
            type: String,
            default: "/",
          },
          baseSingleDeleteAction: {
            type: String,
            default: "/",
          },
          baseMultipleDeleteAction: {
            type: String,
            default: "/",
          },
          downloads: {
            type: Boolean,
            default: false,
          },
          customCols: {
            type: Boolean,
            default: true,
          },
          search: {
            type: Boolean,
            default: true,
          },
          sortable: {
            type: Boolean,
            default: true,
          },
          hasCheckbox: {
            type: Boolean,
            default: true,
          },
          stickyHeader: {
            type: Boolean,
            default: true,
          },
          selectRowOnClick: {
            type: Boolean,
            default: true,
          },
          colsFields: {
            type: Array,
            default: [
              {
                field: "id",
                title: "Id",
              },
              {
                field: "firstName",
                title: "Firstname",
              },
              {
                field: "lastName",
                title: "Lastname",
              },
              {
                field: "company",
                title: "Company",
              },
              {
                field: "age",
                title: "Age",
              },
              {
                field: "dob",
                title: "Start Date",
              },
              {
                field: "email",
                title: "Email",
              },
              {
                field: "phone",
                title: "Phone No.",
              },
              {
                field: "actions",
                title: "Actions",
                headerClass: "justify-center",
              },
            ],
          },
          hiddenFields: {
            type: Array,
            default: [],
          },
          unsortableFields: {
            type: Array,
            default: ["actions"],
          },
          uniqueFields: {
            type: Array,
            default: ["id"],
          },
          modalData: {
            type: Object,
            default: reactive({}),
          },
        };
        return validate(v, validationObj);
      },
      default: reactive({}),
    },
    data: {
      type: Object,
      validator(v) {
        const validationObj = {
          tableContent: {
            type: Array,
            default: [
              {
                id: 1,
                firstName: "Caroline",
                lastName: "Jensen",
                email: "carolinejensen@zidant.com",
                dob: "2004-05-28",
                address: {
                  street: "529 Scholes Street",
                  city: "Temperanceville",
                  zipcode: 5235,
                  geo: {
                    lat: 23.806115,
                    lng: 164.677197,
                  },
                },
                phone: "+1 (821) 447-3782",
                isActive: false,
                age: 39,
                company: "POLARAX",
              },
              {
                id: 2,
                firstName: "Pippo",
                lastName: "Grant",
                email: "celestegrant@polarax.com",
                dob: "1989-11-19",
                address: {
                  street: "639 Kimball Street",
                  city: "Bascom",
                  zipcode: 8907,
                  geo: {
                    lat: 65.954483,
                    lng: 98.906478,
                  },
                },
                phone: "+1 (838) 515-3408",
                isActive: false,
                age: 32,
                company: "MANGLO",
              },
              {
                id: 3,
                firstName: "Tillman",
                lastName: "Forbes",
                email: "tillmanforbes@manglo.com",
                dob: "2016-09-05",
                address: {
                  street: "240 Vandalia Avenue",
                  city: "Thynedale",
                  zipcode: 8994,
                  geo: {
                    lat: -34.949388,
                    lng: -82.958111,
                  },
                },
                phone: "+1 (969) 496-2892",
                isActive: false,
                age: 26,
                company: "APPLIDECK",
              },
              {
                id: 4,
                firstName: "Daisy",
                lastName: "Whitley",
                email: "daisywhitley@applideck.com",
                dob: "1987-03-23",
                address: {
                  street: "350 Pleasant Place",
                  city: "Idledale",
                  zipcode: 9369,
                  geo: {
                    lat: -54.458809,
                    lng: -127.476556,
                  },
                },
                phone: "+1 (861) 564-2877",
                isActive: true,
                age: 21,
                company: "VOLAX",
              },
              {
                id: 5,
                firstName: "Weber",
                lastName: "Bowman",
                email: "weberbowman@volax.com",
                dob: "1983-02-24",
                address: {
                  street: "154 Conway Street",
                  city: "Broadlands",
                  zipcode: 8131,
                  geo: {
                    lat: 54.501351,
                    lng: -167.47138,
                  },
                },
                phone: "+1 (962) 466-3483",
                isActive: false,
                age: 26,
                company: "ORBAXTER",
              },
              {
                id: 6,
                firstName: "Buckley",
                lastName: "Townsend",
                email: "buckleytownsend@orbaxter.com",
                dob: "2011-05-29",
                address: {
                  street: "131 Guernsey Street",
                  city: "Vallonia",
                  zipcode: 6779,
                  geo: {
                    lat: -2.681655,
                    lng: 3.528942,
                  },
                },
                phone: "+1 (884) 595-2643",
                isActive: true,
                age: 40,
                company: "OPPORTECH",
              },
              {
                id: 7,
                firstName: "Latoya",
                lastName: "Bradshaw",
                email: "latoyabradshaw@opportech.com",
                dob: "2010-11-23",
                address: {
                  street: "668 Lenox Road",
                  city: "Lowgap",
                  zipcode: 992,
                  geo: {
                    lat: 36.026423,
                    lng: 130.412198,
                  },
                },
                phone: "+1 (906) 474-3155",
                isActive: true,
                age: 24,
                company: "GORGANIC",
              },
              {
                id: 8,
                firstName: "Kate",
                lastName: "Lindsay",
                email: "katelindsay@gorganic.com",
                dob: "1987-07-02",
                address: {
                  street: "773 Harrison Avenue",
                  city: "Carlton",
                  zipcode: 5909,
                  geo: {
                    lat: 42.464724,
                    lng: -12.948403,
                  },
                },
                phone: "+1 (930) 546-2952",
                isActive: true,
                age: 24,
                company: "AVIT",
              },
              {
                id: 9,
                firstName: "Marva",
                lastName: "Sandoval",
                email: "marvasandoval@avit.com",
                dob: "2010-11-02",
                address: {
                  street: "200 Malta Street",
                  city: "Tuskahoma",
                  zipcode: 1292,
                  geo: {
                    lat: -52.206169,
                    lng: 74.19452,
                  },
                },
                phone: "+1 (927) 566-3600",
                isActive: false,
                age: 28,
                company: "QUILCH",
              },
              {
                id: 10,
                firstName: "Decker",
                lastName: "Russell",
                email: "deckerrussell@quilch.com",
                dob: "1994-04-21",
                address: {
                  street: "708 Bath Avenue",
                  city: "Coultervillle",
                  zipcode: 1268,
                  geo: {
                    lat: -41.550295,
                    lng: -146.598075,
                  },
                },
                phone: "+1 (846) 535-3283",
                isActive: false,
                age: 27,
                company: "MEMORA",
              },
              {
                id: 11,
                firstName: "Odom",
                lastName: "Mills",
                email: "odommills@memora.com",
                dob: "2010-01-24",
                address: {
                  street: "907 Blake Avenue",
                  city: "Churchill",
                  zipcode: 4400,
                  geo: {
                    lat: -56.061694,
                    lng: -130.238523,
                  },
                },
                phone: "+1 (995) 525-3402",
                isActive: true,
                age: 34,
                company: "ZORROMOP",
              },
              {
                id: 12,
                firstName: "Sellers",
                lastName: "Walters",
                email: "sellerswalters@zorromop.com",
                dob: "1975-11-12",
                address: {
                  street: "978 Oakland Place",
                  city: "Gloucester",
                  zipcode: 3802,
                  geo: {
                    lat: 11.732587,
                    lng: 96.118099,
                  },
                },
                phone: "+1 (830) 430-3157",
                isActive: true,
                age: 28,
                company: "ORBOID",
              },
              {
                id: 13,
                firstName: "Wendi",
                lastName: "Powers",
                email: "wendipowers@orboid.com",
                dob: "1979-06-02",
                address: {
                  street: "376 Greenpoint Avenue",
                  city: "Elliott",
                  zipcode: 9149,
                  geo: {
                    lat: -78.159578,
                    lng: -9.835103,
                  },
                },
                phone: "+1 (863) 457-2088",
                isActive: true,
                age: 31,
                company: "SNORUS",
              },
              {
                id: 14,
                firstName: "Sophie",
                lastName: "Horn",
                email: "sophiehorn@snorus.com",
                dob: "2018-09-20",
                address: {
                  street: "343 Doughty Street",
                  city: "Homestead",
                  zipcode: 330,
                  geo: {
                    lat: 65.484087,
                    lng: 137.413998,
                  },
                },
                phone: "+1 (885) 418-3948",
                isActive: true,
                age: 22,
                company: "XTH",
              },
              {
                id: 15,
                firstName: "Levine",
                lastName: "Rodriquez",
                email: "levinerodriquez@xth.com",
                dob: "1973-02-08",
                address: {
                  street: "643 Allen Avenue",
                  city: "Weedville",
                  zipcode: 8931,
                  geo: {
                    lat: -63.185586,
                    lng: 117.327808,
                  },
                },
                phone: "+1 (999) 565-3239",
                isActive: true,
                age: 27,
                company: "COMTRACT",
              },
              {
                id: 16,
                firstName: "Little",
                lastName: "Hatfield",
                email: "littlehatfield@comtract.com",
                dob: "2012-01-03",
                address: {
                  street: "194 Anthony Street",
                  city: "Williston",
                  zipcode: 7456,
                  geo: {
                    lat: 47.480837,
                    lng: 6.085909,
                  },
                },
                phone: "+1 (812) 488-3011",
                isActive: false,
                age: 33,
                company: "ZIDANT",
              },
              {
                id: 17,
                firstName: "Larson",
                lastName: "Kelly",
                email: "larsonkelly@zidant.com",
                dob: "2010-06-14",
                address: {
                  street: "978 Indiana Place",
                  city: "Innsbrook",
                  zipcode: 639,
                  geo: {
                    lat: -71.766732,
                    lng: 150.854345,
                  },
                },
                phone: "+1 (892) 484-2162",
                isActive: true,
                age: 20,
                company: "SUREPLEX",
              },
              {
                id: 18,
                firstName: "Kendra",
                lastName: "Molina",
                email: "kendramolina@sureplex.com",
                dob: "2002-07-19",
                address: {
                  street: "567 Charles Place",
                  city: "Kimmell",
                  zipcode: 1966,
                  geo: {
                    lat: 50.765816,
                    lng: -117.106499,
                  },
                },
                phone: "+1 (920) 528-3330",
                isActive: false,
                age: 31,
                company: "DANJA",
              },
              {
                id: 19,
                firstName: "Ebony",
                lastName: "Livingston",
                email: "ebonylivingston@danja.com",
                dob: "1994-10-18",
                address: {
                  street: "284 Cass Place",
                  city: "Navarre",
                  zipcode: 948,
                  geo: {
                    lat: 65.271256,
                    lng: -83.064729,
                  },
                },
                phone: "+1 (970) 591-3039",
                isActive: false,
                age: 33,
                company: "EURON",
              },
              {
                id: 20,
                firstName: "Kaufman",
                lastName: "Rush",
                email: "kaufmanrush@euron.com",
                dob: "2011-07-10",
                address: {
                  street: "408 Kingsland Avenue",
                  city: "Beaulieu",
                  zipcode: 7911,
                  geo: {
                    lat: 41.513153,
                    lng: 54.821641,
                  },
                },
                phone: "+1 (924) 463-2934",
                isActive: false,
                age: 39,
                company: "ILLUMITY",
              },
              {
                id: 21,
                firstName: "Frank",
                lastName: "Hays",
                email: "frankhays@illumity.com",
                dob: "2005-06-15",
                address: {
                  street: "973 Caton Place",
                  city: "Dargan",
                  zipcode: 4104,
                  geo: {
                    lat: 63.314988,
                    lng: -138.771323,
                  },
                },
                phone: "+1 (930) 577-2670",
                isActive: false,
                age: 31,
                company: "SYBIXTEX",
              },
              {
                id: 22,
                firstName: "Carmella",
                lastName: "Mccarty",
                email: "carmellamccarty@sybixtex.com",
                dob: "1980-03-06",
                address: {
                  street: "919 Judge Street",
                  city: "Canby",
                  zipcode: 8283,
                  geo: {
                    lat: 9.198597,
                    lng: -138.809971,
                  },
                },
                phone: "+1 (876) 456-3218",
                isActive: true,
                age: 21,
                company: "ZEDALIS",
              },
              {
                id: 23,
                firstName: "Massey",
                lastName: "Owen",
                email: "masseyowen@zedalis.com",
                dob: "2012-03-01",
                address: {
                  street: "108 Seaview Avenue",
                  city: "Slovan",
                  zipcode: 3599,
                  geo: {
                    lat: -74.648318,
                    lng: 99.620699,
                  },
                },
                phone: "+1 (917) 567-3786",
                isActive: false,
                age: 40,
                company: "DYNO",
              },
              {
                id: 24,
                firstName: "Lottie",
                lastName: "Lowery",
                email: "lottielowery@dyno.com",
                dob: "1982-10-10",
                address: {
                  street: "557 Meserole Avenue",
                  city: "Fowlerville",
                  zipcode: 4991,
                  geo: {
                    lat: 54.811546,
                    lng: -20.996515,
                  },
                },
                phone: "+1 (912) 539-3498",
                isActive: true,
                age: 36,
                company: "MULTIFLEX",
              },
              {
                id: 25,
                firstName: "Addie",
                lastName: "Luna",
                email: "addieluna@multiflex.com",
                dob: "1988-05-01",
                address: {
                  street: "688 Bulwer Place",
                  city: "Harmon",
                  zipcode: 7664,
                  geo: {
                    lat: -12.762766,
                    lng: -39.924497,
                  },
                },
                phone: "+1 (962) 537-2981",
                isActive: true,
                age: 32,
                company: "PHARMACON",
              },
            ],
          },
        };
        return validate(v, validationObj);
      },
      default: reactive({}),
    },
    modalOpened: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    useMeta({ title: props.config.title });

    const emit = ctx.emit;
    const clickedRow = (e) => {
      emit("rowClicked", e);
    };

    const check = ref(false);
    const innerCols = ref(false);
    const calculateCols = computed({
      get() {
        if (!check.value) {
          const ret = [];
          const canView = props.config.canView;
          const canEdit = props.config.canEdit;
          const canDelete = props.config.canDelete;
          const cantDoAnything = !canView && !canEdit && !canDelete;
          const allowedFieldKeys = ["field", "title", "headerClass"];
          props.config.colsFields.forEach((el) => {
            const reduced = Object.keys(el)
              .filter((key) => {
                if (cantDoAnything && el["field"] === "actions") return false;
                else return allowedFieldKeys.includes(key);
              })
              .reduce((obj, key) => {
                return Object.assign(obj, {
                  [key]: el[key],
                });
              }, {});
            if (!!Object.keys(reduced).length) {
              if (
                reduced.field !== undefined &&
                (reduced.field.includes(".") || reduced.field.includes("["))
              ) {
                const arrayFromString = stringToObj(reduced.field);
                reduced.field = arrayFromString[arrayFromString.length - 1];
              }
              reduced.hide = props.config.hiddenFields.includes(reduced.field);
              reduced.sort = !props.config.unsortableFields.includes(
                reduced.field
              );
              reduced.unique = props.config.uniqueFields.includes(
                reduced.field
              );
              ret.push(reduced);
            }
          });
          return ret;
        } else return innerCols.value;
      },
      set(value) {
        innerCols.value = value;
        check.value = true;
        return value;
      },
    });

    const deleteButtonDisabled = ref(true);
    const configuration = computed(() => {
      const canView = props.config.canView;
      const canAdd = props.config.canAdd;
      const canEdit = props.config.canEdit;
      const canDelete = props.config.canDelete;
      const pageConfiguration = {
        downloads: props.config.downloads,
        customCols: props.config.customCols,
        search: props.config.search,
        sortable: props.config.sortable,
        hasCheckbox: props.config.hasCheckbox,
        stickyHeader: props.config.stickyHeader,
        selectRowOnClick: props.config.selectRowOnClick,
        deleteCB: props.config.deleteCB,
        modalData: props.config?.modalData,
      };
      pageConfiguration.permissions = {
        read: canView,
        create: canAdd,
        edit: canEdit,
        delete: canDelete,
      };
      if (canView || canEdit) {
        const actions = {};
        if (canView) {
          actions.baseViewRoute = props.config.baseViewRoute;
          actions.baseViewField = props.config.baseViewIdentifier;
        }
        if (canEdit) {
          actions.baseEditRoute = props.config.baseEditRoute;
          actions.baseEditField = props.config.baseEditIdentifier;
        }
        pageConfiguration.actions = { ...actions };
      }
      pageConfiguration.addButton = {
        active: canAdd,
        route: props.config.baseAddRoute || null,
        routeName: props.config.baseAddRouteName || null,
        disabled: props.config.addButtonDisabled || null,
      };
      pageConfiguration.deleteButton = {
        active: canDelete,
        singleAction: props.config.baseSingleDeleteAction,
        multipleAction: props.config.baseMultipleDeleteAction,
        disabled:
          props.config.deleteButtonDisabled || deleteButtonDisabled || null,
      };
      return pageConfiguration;
    });

    const replacementString = "@#THS,89!!43s9696sdacb**-_#@";
    const stringToObj = (str) =>
      str
        .replaceAll(".", replacementString)
        .replaceAll("[", replacementString)
        .replaceAll("]", replacementString)
        .split(replacementString)
        .filter((el) => el !== "");

    const findValue = (source, arrayToSearch, previousKey = "", obj = {}) => {
      const innerArray = [...arrayToSearch];
      if (
        previousKey === "" &&
        ((Array.isArray(source) && !source[innerArray[0]].length) ||
          (!Array.isArray(source) &&
            !Object.keys(source[innerArray[0]]).length))
      )
        return "-";
      if (!innerArray.length) {
        const out = {};
        if (!source && previousKey !== "") return "-";
        const MAX_TABLE_STRINGS_LENGTH = parseInt(
          process.env.VUE_APP_MAX_TABLE_STRINGS_LENGTH
        );
        out[previousKey] =
          typeof source === "string" && source.length > MAX_TABLE_STRINGS_LENGTH
            ? source.slice(0, MAX_TABLE_STRINGS_LENGTH) + "..."
            : source;
        return out;
      } else
        return findValue(
          source[innerArray[0]],
          innerArray.splice(1),
          innerArray[0]
        );
    };
    const calculateRows = computed(() => {
      const fieldsObj = props.config.colsFields.reduce(
        (ov, nv) => {
          const { fieldsArray, fieldsToUnrollArray } = ov;

          if (
            nv.field !== undefined &&
            (nv.field.includes(".") || nv.field.includes("["))
          ) {
            const toUnrollArray = stringToObj(nv.field);
            const toUnrollObj = { ...fieldsToUnrollArray };
            if (!Object.keys(toUnrollObj).includes(toUnrollArray[0]))
              toUnrollObj[toUnrollArray[0]] = [];
            toUnrollObj[toUnrollArray[0]].push(toUnrollArray);
            return {
              fieldsArray: fieldsArray,
              fieldsToUnrollArray: { ...toUnrollObj },
            };
          } else
            return {
              fieldsArray: [...fieldsArray, nv.field],
              fieldsToUnrollArray,
            };
        },
        {
          fieldsArray: [],
          fieldsToUnrollArray: {},
          fieldsToUnrollIndexesArray: [],
          objectsFieldsObj: {},
          arrayFieldsObj: {},
        }
      );
      const { fieldsArray, fieldsToUnrollArray } = fieldsObj;
      const out = [];
      props.data.tableContent.forEach((el) => {
        const reduced = Object.keys(el)
          .filter(
            (key) =>
              fieldsArray.includes(key) ||
              Object.keys(fieldsToUnrollArray).includes(key)
          )
          .reduce((obj, key) => {
            const MAX_TABLE_STRINGS_LENGTH = parseInt(
              process.env.VUE_APP_MAX_TABLE_STRINGS_LENGTH
            );

            if (fieldsArray.includes(key))
              return Object.assign(obj, {
                [key]:
                  typeof el[key] === "string" &&
                  el[key].length > MAX_TABLE_STRINGS_LENGTH
                    ? el[key].slice(0, MAX_TABLE_STRINGS_LENGTH) + "..."
                    : el[key],
              });
            else if (Object.keys(fieldsToUnrollArray).includes(key)) {
              if (Array.isArray(fieldsToUnrollArray[key][0])) {
                const len = fieldsToUnrollArray[key].length;
                for (let i = 0; i < len; i++) {
                  const toUnroll = fieldsToUnrollArray[key][i];
                  obj = Object.assign(obj, findValue(el, toUnroll));
                }
                return obj;
              } else
                return Object.assign(
                  obj,
                  findValue(el, fieldsToUnrollArray[key])
                );
            }
          }, {});
        out.push(reduced);
      });
      return out;
    });
    const data = reactive({ rows: calculateRows });

    const innerModalOpened = computed({
      get() {
        return props.modalOpened;
      },
      set(value) {
        emit("update:modalOpened", value);
      },
    });
    return {
      configuration,
      clickedRow,
      data,
      calculateCols,
      innerModalOpened,
      deleteButtonDisabled,
    };
  },
};
</script>
