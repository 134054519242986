
export default {
    setPlans(state, payload) {
        state.plans = payload.plans
    },
    setPlan(state, id) {
        if (id === '') state.plan = {};
        else state.plan = state.plans.filter(plan => {
            return plan.id === id
        })[0]
    },
    setCurrentPlan(state, plan) {
        state.plan = plan;
    },
    setCurrentPlanServiceSetups(state, serviceSetups) {
        state.plan.serviceSetups = serviceSetups;
    },
    addPlan(state, newPlan) {
        state.plans.push(newPlan);
    },

    setServices(state, payload) {
        state.services = payload.services
    },
    addService(state, newService) {
        state.services.push(newService);
    },


    setTransactions(state, payload) {
        state.transactions = payload.transactions
    },
    setTransaction(state, id) {
        if (id === '') state.transaction = {};
        else state.transaction = state.transactions.filter(transaction => {
            return transaction.id === id
        })[0]
    },
    setService(state, id) {
        if (id === '') state.service = {};
        else state.service = state.services.filter(service => {
            return service.id === id
        })[0]
    },
    setTransactionStatus(state, payload) {
        state.transaction.status = payload.status
    },
    setActiveSubscriptionStartDate(state, payload) {
        state.transaction.activeSubscription.startDate = payload.startDate
    },
    setInactiveSubscriptionStartDate(state, payload) {
        state.transaction.inactiveSubscription.startDate = payload.startDate
    },
    setTransactionStatuses(state, payload) {
        state.transactionStatuses = payload.transactionStatuses
    },

    editService(state, service) {
        const id = service.id;
        state.services.forEach(innerService => {
            if (innerService.id === id) {
                innerService = { ...service }
            }
        });
        state.service = {}
    },
    editPlan(state, plan) {
        const id = plan.id;
        state.plans.forEach(innerPlan => {
            if (innerPlan.id === id) {
                innerPlan = { ...plan }
            }
        });
        state.plan = {}
    },
    deleteService(state, id) {
        state.services = state.services.filter(service => service.id !== id)
    },
    deleteServices(state, services) {
        state.services = state.services.filter(service => !services.includes(service.id))
    },
    deletePlan(state, id) {
        state.plans = state.plans.filter(plan => plan.id !== id)
    },
    deletePlans(state, plans) {
        state.plans = state.plans.filter(plan => !plans.includes(plan.id))
    },

}