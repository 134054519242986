export default {
    users(state) {
        return state.users;
    },
    user(state) {
        return state.user;
    },
    associatedLabkeyBuildings(state) {
        return state.associatedLabkeyBuildings;
    },
}