const ColorClasses = Object.freeze({
    blue: "primary",
    green: "success",
    purple: "secondary",
    red: "danger",
    yellow: "warning",
    lightBlue: "info",
    darkGray: "dark",
    white: "white",
})
const ColorHexCodes = Object.freeze({
    blue: "#4361ee",
    green: "#00ab55",
    purple: "#805dca",
    red: "#e7515a",
    yellow: "#e2a03f",
    lightBlue: "#2196f3",
    darkGray: "#3b3f5c",
    white: "#fff",
})

module.exports = { ColorClasses, ColorHexCodes }