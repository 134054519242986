<template>
  <form>
    <div class="space-y-5 grid grid-cols-1 md:grid-cols-2 gap-4">
      <template v-for="(config, i) in configs" :key="`forminput-${i}`">
        <FormInput
          v-if="config.type === 'input' && config.subtype === 'radio'"
          :config="config"
          :data="data(config)"
          :checked="data(config).checked"
          v-model:value="innerValue"
          @change="disableOthers"
          @regexpCompleteError="manageRegexpError"
          :errorList="errorList"
        >
        </FormInput>
        <FormInput
          v-else-if="config.type === 'input' && config.subtype === 'checkbox'"
          :config="config"
          :data="data(config)"
          :checked="data(config).checked"
          v-model:value="innerValue"
          @regexpCompleteError="manageRegexpError"
          :errorList="errorList"
        >
        </FormInput>
        <FormInput
          v-else
          :config="config"
          :data="data(config)"
          v-model:value="innerValue"
          @regexpCompleteError="manageRegexpError"
          :errorList="errorList"
        >
        </FormInput>
        <!-- FormGroup config: {{ config }} -->
        <!-- FormGroup data: {{ data(config) }}<br /><br />
        FormGroup value: {{ innerValue }}<br /><br /> -->
      </template>
    </div>
    <div class="mt-5 grid grid-cols-1">
      <MyButton
        class="w-full md:w-3/4 mx-auto"
        v-if="buttonConfig.submitCb"
        :config="innerButtonConfig"
        :text="buttonConfig.text"
      />
    </div>
  </form>
</template>

<script>
import { validate } from "@/modules/routines.cjs";
import { reactive, ref, computed } from "vue";
import FormInput from "./FormInput.vue";
import { inputData, selectData, textareaData } from "./inputConfig.cjs";
export default {
  components: {
    FormInput,
  },
  props: {
    configs: {
      type: Array,
      default: reactive([]),
    },
    value: {
      type: [Number, String, Boolean, Array, Object],
      default: {},
    },
    errorList: {
      type: Array,
      default: reactive([]),
    },
    buttonConfig: {
      type: Object,
      validator(v) {
        const validationObj = {
          submitCb: {
            type: Function,
            default: null,
          },
          text: {
            type: String,
            default: "Save",
          },
          disabled: {
            type: Boolean,
            default: false,
          },
        };
        return validate(v, validationObj);
      },
      default: reactive({}),
    },
  },
  setup(props, ctx) {
    const emit = ctx.emit;
    const values = ref(null);

    const toPush = ref(null);
    const innerValue = computed({
      get() {
        return props.value;
      },
      set(newEntry) {
        let unrolled = props.value;
        unrolled = setUnrolled(unrolled, newEntry.key, newEntry.value);
        emit("update:value", unrolled);
      },
    });
    const setUnrolled = (obj, key, val) => {
      const keys = key.split(".");
      keys.reduce((currentObj, currentKey, index) => {
        if (currentKey.includes("[")) {
          const [arrayKey, arrayIndex] = currentKey.split("[");
          const arrayIndexInt = parseInt(arrayIndex.replace("]", ""), 10);

          if (!currentObj[arrayKey]) {
            currentObj[arrayKey] = [];
          }

          if (!currentObj[arrayKey][arrayIndexInt]) {
            currentObj[arrayKey][arrayIndexInt] = {};
          }

          if (index === keys.length - 1) {
            currentObj[arrayKey][arrayIndexInt] = val;
          } else {
            if (
              typeof currentObj[arrayKey][arrayIndexInt] !== "object" ||
              currentObj[arrayKey][arrayIndexInt] === null
            ) {
              currentObj[arrayKey][arrayIndexInt] = {};
            }
            currentObj = currentObj[arrayKey][arrayIndexInt];
          }
        } else {
          if (index === keys.length - 1) {
            currentObj[currentKey] = val;
          } else {
            if (
              typeof currentObj[currentKey] !== "object" ||
              currentObj[currentKey] === null
            ) {
              currentObj[currentKey] = {};
            }
            currentObj = currentObj[currentKey];
          }
        }
        return currentObj;
      }, obj);
      return obj;
    };

    const unrollConfig = (obj, configObj, forbiddenFields) => {
      let out = {};
      for (const key in configObj) {
        if (Object.hasOwnProperty.call(configObj, key)) {
          if (!obj) return {};
          const el =
            Object.hasOwnProperty.call(obj, key) &&
            !forbiddenFields.includes(key)
              ? obj[key]
              : configObj[key];
          out[key] = el;
        }
      }
      return out;
    };
    const data = computed(function () {
      return (config) => {
        let configObj = {};
        if (config.type === "textarea") {
          configObj = textareaData;
        } else if (config.type === "select") {
          configObj = selectData[config.subtype];
        } else {
          configObj = inputData[config.subtype];
        }
        return unrollConfig(config, configObj, []);
      };
    });
    const disableOthers = (evt) => {
      if (evt.subtype === "radio") {
        const name = evt.name;
        const id = evt.id;
        const idx = parseInt(id.split(`${name}-`)[1]);
        const separator = "skdjfh@$%";
        const elmts = document.getElementsByName(name);
        const len = elmts.length;
        let originalRef = evt.ref;
        let originalRefArray = originalRef.split(`[${idx}]`);
        let genericRef =
          originalRefArray.length <= 2
            ? originalRefArray.join(`[${separator}]`)
            : originalRefArray
                .slice(0, originalRefArray.length - 1)
                .join(`[${idx}]`) + `[${separator}]`;
        originalRefArray.slice(originalRefArray.length - 1);
        for (let i = 0; i < len; i++) {
          let valueToSet = i === idx;
          if (!toPush.value) toPush.value = [];

          const unrolled = props.value;
          setUnrolled(unrolled, genericRef.replace(separator, i), valueToSet);
          emit("update:value", unrolled);
        }
      }
    };

    const innerButtonConfig = computed(() => {
      return {
        type: "default",
        themeColor: true,
        disabled: props.buttonConfig.disabled,
        outline: false,
        hasIcon: false,
        iconPosition: "right",
        fullWidth: true,
        size: "m",
        text: props.buttonConfig.text,
        callback: () => {
          props.buttonConfig.submitCb();
        },
      };
    });
    const manageRegexpError = (e) => {
      const regexpErrors = ref(props.errorList);
      const { code } = e;
      const myRef = e.ref;
      const firstCheck = code === 200 && regexpErrors.value.includes(myRef);
      const secondCheck = code === 400 && !regexpErrors.value.includes(myRef);
      if (firstCheck) {
        regexpErrors.value = regexpErrors.value.reduce((ov, nv) => {
          if (nv !== myRef) {
            ov.push(nv);
          } else {
          }
          return ov;
        }, []);
      } else if (secondCheck) regexpErrors.value.push(myRef);
      if (firstCheck || secondCheck)
        emit("update:errorList", regexpErrors.value);
    };

    return {
      innerValue,
      values,
      data,
      disableOthers,
      innerButtonConfig,
      manageRegexpError,
    };
  },
};
</script>

<style>
</style>