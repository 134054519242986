export default {
    isLoaderTextEnabled(state) {
        return !!state.loaderTextEnabled;
    },
    isLoading(state) {
        return !!state.isLoading
    },
    logoText(state) {
        return state.logoText
    },
    loaderText(state) {
        return state.loaderText
    },
    loaderType(state) {
        return state.loaderType
    },
    loaderColor(state) {
        return state.loaderColor
    },
    loaderSize(state) {
        return state.loaderSize
    },
    loaderThemeColor(state) {
        return state.loaderThemeColor
    },
    getErrors(state) {
        return state.errors
    },
    getColorMode(state) {
        return state.colorMode
    },
    getDetailsPanelStatus(state) {
        return state.showEditPanel
    },
    getEditingField(state) {
        return state.editingField
    },
    getSelectedField(state) {
        return state.selectedField
    },
    getFieldEditMode(state) {
        return state.fieldEditMode;
    },
}