<template>
  <div
    class="main-section antialiased relative font-nunito text-sm font-normal"
    :class="[
      sidebarStatus ? 'toggle-sidebar' : '',
      menuOrientation,
      layout,
      rtlClass,
    ]"
  >
    <component v-bind:is="mainLayout"></component>
  </div>
</template>

<script>
import { computed } from "vue";
 
import appLayout from "@/layouts/app-layout.vue";
import authLayout from "@/layouts/auth-layout.vue";
import store from "@/store";
import { useMeta } from "@/composables/use-meta";

export default {
  setup() {
    // meta
    useMeta({ title: "The Spaze Management System" });
    const menuOrientation = computed(() => store.getters.getMenuOrientation);
    const sidebarStatus = computed(() => store.getters.getSideBarStatus);
    const layout = computed(() => store.getters.getLayout);
    const rtlClass = computed(() => store.getters.getRtlClass);

    const mainLayout = computed(() => {
      return store.getters.getMainLayout === "auth" ? authLayout : appLayout;
    });
    store.commit("restoreUserSession");
    store.dispatch("retrieveMenu");

    const isLoading = computed(() => store.getters.isLoading);
    return {
      menuOrientation,
      sidebarStatus,
      layout,
      rtlClass,
      mainLayout,
      isLoading,
    };
  },
};
</script>
