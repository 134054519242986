

const DefaultClasses = Object.freeze({
    primary: {
        "peer-checked:before:bg-white": true,
        "dark:peer-checked:before:bg-white": true,
        "peer-checked:bg-primary": true,
    },
    success: {
        "peer-checked:before:bg-white": true,
        "dark:peer-checked:before:bg-white": true,
        "peer-checked:bg-success": true,
    },
    secondary: {
        "peer-checked:before:bg-white": true,
        "dark:peer-checked:before:bg-white": true,
        "peer-checked:bg-secondary": true,
    },
    danger: {
        "peer-checked:before:bg-white": true,
        "dark:peer-checked:before:bg-white": true,
        "peer-checked:bg-danger": true,
    },
    warning: {
        "peer-checked:before:bg-white": true,
        "dark:peer-checked:before:bg-white": true,
        "peer-checked:bg-warning": true,
    },
    info: {
        "peer-checked:before:bg-white": true,
        "dark:peer-checked:before:bg-white": true,
        "peer-checked:bg-info": true,
    },
    dark: {
        "peer-checked:before:bg-white": true,
        "dark:peer-checked:before:bg-white": true,
        "peer-checked:bg-dark": true,
    }
})
const IconClasses = Object.freeze({
    primary: {
        "dark:peer-checked:bg-primary": true,
        "peer-checked:before:bg-white": true,
        "dark:peer-checked:before:bg-white ": true,
        "peer-checked:before:bg-[url(/assets/images/checked-primary.svg)]": true,
        "peer-checked:bg-primary": true,
    },
    success: {
        "dark:peer-checked:bg-success": true,
        "peer-checked:before:bg-white": true,
        "dark:peer-checked:before:bg-white ": true,
        "peer-checked:before:bg-[url(/assets/images/checked-success.svg)]": true,
        "peer-checked:bg-success": true,
    },
    secondary: {
        "dark:peer-checked:bg-secondary": true,
        "peer-checked:before:bg-white": true,
        "dark:peer-checked:before:bg-white ": true,
        "peer-checked:before:bg-[url(/assets/images/checked-secondary.svg)]": true,
        "peer-checked:bg-secondary": true,
    },
    danger: {
        "dark:peer-checked:bg-danger": true,
        "peer-checked:before:bg-white": true,
        "dark:peer-checked:before:bg-white ": true,
        "peer-checked:before:bg-[url(/assets/images/checked-danger.svg)]": true,
        "peer-checked:bg-danger": true,
    },
    warning: {
        "dark:peer-checked:bg-warning": true,
        "peer-checked:before:bg-white": true,
        "dark:peer-checked:before:bg-white ": true,
        "peer-checked:before:bg-[url(/assets/images/checked-warning.svg)]": true,
        "peer-checked:bg-warning": true,
    },
    info: {
        "dark:peer-checked:bg-info": true,
        "peer-checked:before:bg-white": true,
        "dark:peer-checked:before:bg-white ": true,
        "peer-checked:before:bg-[url(/assets/images/checked-info.svg)]": true,
        "peer-checked:bg-info": true,
    },
    dark: {
        "dark:peer-checked:bg-dark": true,
        "peer-checked:before:bg-white": true,
        "dark:peer-checked:before:bg-white ": true,
        "peer-checked:before:bg-[url(/assets/images/checked-dark.svg)]": true,
        "peer-checked:bg-dark": true,
    }
})
const IconOutlineClasses = Object.freeze({
    primary: {
        "peer-checked:before:bg-[url(/assets/images/checked.svg)]": true,
        "peer-checked:before:bg-primary": true,
        "peer-checked:border-primary": true,
    },
    success: {
        "peer-checked:before:bg-[url(/assets/images/checked.svg)]": true,
        "peer-checked:before:bg-success": true,
        "peer-checked:border-success": true,
    },
    secondary: {
        "peer-checked:before:bg-[url(/assets/images/checked.svg)]": true,
        "peer-checked:before:bg-secondary": true,
        "peer-checked:border-secondary": true,
    },
    danger: {
        "peer-checked:before:bg-[url(/assets/images/checked.svg)]": true,
        "peer-checked:before:bg-danger": true,
        "peer-checked:border-danger": true,
    },
    warning: {
        "peer-checked:before:bg-[url(/assets/images/checked.svg)]": true,
        "peer-checked:before:bg-warning": true,
        "peer-checked:border-warning": true,
    },
    info: {
        "peer-checked:before:bg-[url(/assets/images/checked.svg)]": true,
        "peer-checked:before:bg-info": true,
        "peer-checked:border-info": true,
    },
    dark: {
        "peer-checked:before:bg-[url(/assets/images/checked.svg)]": true,
        "peer-checked:before:bg-dark": true,
        "peer-checked:border-dark": true,
    }
})
const OutlineClasses = Object.freeze({
    primary: {
        "peer-checked:before:bg-primary": true,
        "peer-checked:border-primary": true,
    },
    success: {
        "peer-checked:before:bg-success": true,
        "peer-checked:border-success": true,
    },
    secondary: {
        "peer-checked:before:bg-secondary": true,
        "peer-checked:border-secondary": true,
    },
    danger: {
        "peer-checked:before:bg-danger": true,
        "peer-checked:border-danger": true,
    },
    warning: {
        "peer-checked:before:bg-warning": true,
        "peer-checked:border-warning": true,
    },
    info: {
        "peer-checked:before:bg-info": true,
        "peer-checked:border-info": true,
    },
    dark: {
        "peer-checked:before:bg-dark": true,
        "peer-checked:border-dark": true,
    }
})
module.exports = { DefaultClasses, OutlineClasses, IconOutlineClasses, IconClasses }