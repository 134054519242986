export default {
    setSNotificationsCloseButton(context, cbStatus = true) {
        context.commit('setSNotificationsCloseButton', cbStatus);
    },
    setSNotificationsOkButton(context, obStatus = false) {
        context.commit('setSNotificationsOkButton', obStatus);
    },
    setSNotificationsVPosition(context, vPosition = 'bottom') {
        context.commit('setSNotificationsVPosition', vPosition);
    },
    setSNotificationsHPosition(context, hPosition = 'left') {
        context.commit('setSNotificationsHPosition', hPosition);
    },
    setSNotificationsDuration(context, duration = 3000) {
        context.commit('setSNotificationsDuration', duration);
    },
    setSystemNotificationActiveStatus(context, activeStatus = false) {
        context.commit('setSystemNotificationActiveStatus', activeStatus);
    },
    setSystemNotificationMessage(context, msg = 'This is a system notification!') {
        context.commit('setSystemNotificationMessage', msg);
    },
    setSystemNotificationType(context, type = 'success') {
        context.commit('setSystemNotificationType', type);
    },
}