<template>
  <span v-if="type === 'svg'" v-html="content"></span>
</template>

<script>
import { getSvg } from "@/modules/templateRoutines.cjs";
import { ColorHexCodes } from "@/components/ColorClasses.js";
import { computed } from "vue";
export default {
  props: {
    type: String,
    name: String,
    fill: String,
    className: {
      type: String,
      default: "",
    },
    iconStyle: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    let content = computed(() => {
      const fill = props.fill ? ColorHexCodes[props.fill] : "currentColor";
      const classname = props.className;
      const iconStyle = props.iconStyle;
      if (props.type === "svg") {
        return getSvg({ fill, classname, iconStyle, name: props?.name });
      }
    });
    return {
      content,
    };
  },
};
</script>

<style>
</style>


