<template>
  <span v-if="readText" :class="badgeClass">{{ text }}</span>
  <span v-else :class="badgeClass"><slot></slot></span>
</template>
<script>
import store from "@/store";
import { reactive, ref, watch, computed } from "vue";
import { validate } from "@/modules/routines.cjs";
import { ColorClasses } from "@/components/ColorClasses.js";

import {
  badgeClasses,
  backgroundClasses,
  addClass,
} from "@/components/CompoundClasses.js";
export default {
  props: {
    config: {
      type: Object,
      validator(v) {
        const validationObj = {
          type: {
            type: String,
            default: "rounded", // squared, rounded, pill
          },
          outline: {
            type: Boolean,
            default: false,
          },
          themeColor: {
            type: Boolean,
            default: false,
          },
          color: {
            type: String,
            default: "blue",
          },
          readText: {
            type: Boolean,
            default: true,
          },
        };
        return validate(v, validationObj);
      },
      default: reactive({}),
    },
    text: { type: String, default: "Badge" },
  },
  setup(props) {
    const color = ref(
      ColorClasses[
        props.config.themeColor
          ? store.getters.componentsDefaultColor
          : props.config.color
      ]
    );
    watch(
      () => props.config.themeColor,
      (newValue) => {
        color.value =
          ColorClasses[
            newValue ? store.getters.componentsDefaultColor : props.config.color
          ];
      }
    );
    watch(
      () => props.config.color,
      (newValue) => {
        color.value =
          ColorClasses[
            props.config.themeColor
              ? store.getters.componentsDefaultColor
              : newValue
          ];
      }
    );
    watch(
      () => store.getters.componentsDefaultColor,
      (newValue) => {
        color.value =
          ColorClasses[props.config.themeColor ? newValue : props.config.color];
      }
    );
    const readText = computed(() => props.config.readText);
    const badgeClass = computed(() => {
      let outClass = "badge";
      switch (props.config.type) {
        case "squared":
          outClass += " rounded-none";
          break;
        case "pill":
          outClass += " rounded-full";
          break;
        default:
          break;
      }
      if (props.config.outline)
        return [outClass, addClass({}, badgeClasses, "outline", color.value)];
      else
        return [
          outClass,
          addClass({}, backgroundClasses, "normal", color.value),
        ];
    });
    return { badgeClass, readText };
  },
};
</script>
