<template>
  <div v-if="loading" :class="[config.hasText ? 'flex flex-col' : '']">
    <Icon
      type="svg"
      :iconStyle="iconSizeSyle"
      :class-name="spinnerClass"
      class="mx-auto"
      :fill="color"
      :name="config.type"
    />
    <span
      v-if="config.hasText"
      class="text-center"
      :class="[spinnerTextClass, sizeStyle]"
      >{{ config.loaderText }}</span
    >
  </div>
</template>

<script>
import store from "@/store";
import { reactive, ref, watch, computed } from "vue";
import { validate } from "@/modules/routines.cjs";
import { ColorClasses } from "@/components/ColorClasses.js";

import { textClasses, addClass } from "@/components/CompoundClasses.js";
export default {
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    config: {
      type: Object,
      validator(v) {
        const validationObj = {
          type: {
            // loader, sunRaysLoader, dualRingLoader, ballLoader, infinityLoader, pulseLoader, spinnerLoader, eclipseLoader, spinLoader, rollingLoader
            type: String,
            default: "loader",
          },
          color: {
            type: String,
            default: "blue",
          },
          themeColor: {
            // if enabled uses the COMPONENTS_DEFAULT_COLOR global variable as default color
            type: Boolean,
            default: false,
          },
          hasText: {
            type: Boolean,
            default: false,
          },
          loaderText: {
            type: String,
            default: "Loading",
          },
          size: {
            type: String,
            default: "m",
          },
        };
        return validate(v, validationObj);
      },
      default: reactive({}),
    },
  },
  setup(props) {
    const color = ref(
      props.config.themeColor
        ? store.getters.componentsDefaultColor
        : props.config.color
    );

    const colorClass = ref(
      ColorClasses[
        props.config.themeColor
          ? store.getters.componentsDefaultColor
          : props.config.color
      ]
    );
    watch(
      () => props.config.themeColor,
      (newValue) => {
        color.value = newValue
          ? store.getters.componentsDefaultColor
          : props.config.color;
        colorClass.value =
          ColorClasses[
            newValue ? store.getters.componentsDefaultColor : props.config.color
          ];
      }
    );
    watch(
      () => props.config.color,
      (newValue) => {
        color.value = props.config.themeColor
          ? store.getters.componentsDefaultColor
          : newValue;
        colorClass.value =
          ColorClasses[
            props.config.themeColor
              ? store.getters.componentsDefaultColor
              : newValue
          ];
      }
    );

    watch(
      () => store.getters.componentsDefaultColor,
      (newValue) => {
        color.value = props.config.themeColor ? newValue : props.config.color;
        colorClass.value =
          ColorClasses[props.config.themeColor ? newValue : props.config.color];
      }
    );

    const spinnerClass = computed(() => `text-${colorClass.value}`);
    const spinnerTextClass = computed(() =>
      addClass({}, textClasses, "normal", colorClass.value)
    );

    const sizeStyle = computed(() => {
      switch (props.config.size) {
        case "s":
          return " mt-2 text-sm";
        case "l":
          return " mt-4 text-lg";
        case "xl":
          return " mt-4 text-xl";
        default:
          return " mt-2 text-md";
      }
    });

    const iconSizeSyle = computed(() => {
      switch (props.config.size) {
        case "s":
          return "height:auto; width:32px";
        case "l":
          return "height:auto; width:84px";
        case "xl":
          return "height:auto; width:100px";
        default:
          return "height:auto; width:64px";
      }
    });
    return {
      color,
      colorClass,
      spinnerClass,
      spinnerTextClass,
      sizeStyle,
      iconSizeSyle,
    };
  },
};
</script>

<style>
</style>