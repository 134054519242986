import {
  createRouter,
  createWebHistory
} from 'vue-router'
import {
  Trans
} from '../modules/Translation'
import store from '@/store';
import appSetting from '@/app-setting';
import { defineAsyncComponent } from 'vue'
function load(view) {
  try {

    return () => import(`@/views/${view}.vue`)
  } catch (e) {
    console.clear();
    console.log("ERROR WHILE TRYING TO IMPORT ", view, e)
  }
}
async function asyncLoad(view) {

  return defineAsyncComponent(() =>
    import(`@/views/${view}.vue`)
  )
}

const routes = [{
  path: '/',
  redirect: '/:locale'
},
{
  path: '/:locale',
  component: {
    template: "<router-view></router-view>"
  },
  beforeEnter: Trans.routeMiddleware,
  children: [
    {
      path: '',
      name: 'Home',
      component: load('Transactions'),
      meta: {
        requiresAuth: true,
        requiresUnauth: false,
        layout: 'app'
      }
    },
    {
      path: 'login',
      name: 'Login',
      beforeEnter: Trans.filterAuth,
      component: load('Login'),
      meta: {
        requiresAuth: false,
        requiresUnauth: true,
        layout: 'auth'
      }
    },
    {
      path: 'modals',
      name: 'Modals',
      beforeEnter: Trans.filterAuth,
      component: load('Modals'),
      meta: {
        requiresAuth: true,
        requiresUnauth: false,
        layout: 'app'
      }
    },
    {
      path: 'users',
      name: 'UsersContainer',
      component: {
        template: "<router-view></router-view>"
      },
      meta: {
        requiresAuth: true,
        requiresUnauth: false,
        layout: 'app'
      },
      children: [
        {
          path: '',
          name: 'Users',
          beforeEnter: Trans.filterAuth,
          component: load('Users'),
          meta: {
            requiresAuth: true,
            requiresUnauth: false,
            layout: 'app'
          },
        },
        {
          path: 'edit/:id',
          name: 'EditUser',
          beforeEnter: Trans.filterAuth,
          component: load('EditUser'),
          meta: {
            requiresAuth: true,
            requiresUnauth: false,
            layout: 'app'
          },
        },
        {
          path: 'add',
          name: 'NewUser',
          beforeEnter: Trans.filterAuth,
          component: load('NewUser'),
          meta: {
            requiresAuth: true,
            requiresUnauth: false,
            layout: 'app'
          },
        },
        {
          path: ':id',
          name: 'User',
          beforeEnter: Trans.filterAuth,
          component: load('User'),
          meta: {
            requiresAuth: true,
            requiresUnauth: false,
            layout: 'app'
          },
        },
      ]
    },
    {
      path: 'plans',
      name: 'PlansContainer',
      component: {
        template: "<router-view></router-view>"
      },
      meta: {
        requiresAuth: true,
        requiresUnauth: false,
        layout: 'app'
      },
      children: [
        {
          path: '',
          name: 'Plans',
          beforeEnter: Trans.filterAuth,
          component: load('Plans'),
          meta: {
            requiresAuth: true,
            requiresUnauth: false,
            layout: 'app'
          },
        },
        {
          path: 'edit/:id',
          name: 'EditPlan',
          beforeEnter: Trans.filterAuth,
          component: () => asyncLoad('EditPlan'),
          meta: {
            requiresAuth: true,
            requiresUnauth: false,
            layout: 'app'
          },
        },
        {
          path: 'add',
          name: 'NewPlan',
          beforeEnter: Trans.filterAuth,
          component: load('NewPlan'),
          meta: {
            requiresAuth: true,
            requiresUnauth: false,
            layout: 'app'
          },
        },
        {
          path: ':id',
          name: 'Plan',
          beforeEnter: Trans.filterAuth,
          component: load('Plan'),
          meta: {
            requiresAuth: true,
            requiresUnauth: false,
            layout: 'app'
          },
        },
      ]
    },
    {
      path: 'transactions',
      name: 'TransactionsContainer',
      component: {
        template: "<router-view></router-view>"
      },
      meta: {
        requiresAuth: true,
        requiresUnauth: false,
        layout: 'app'
      },
      children: [

        {
          path: '',
          name: 'Transactions',
          beforeEnter: Trans.filterAuth,
          component: load('Transactions'),
          meta: {
            requiresAuth: true,
            requiresUnauth: false,
            layout: 'app'
          },
        },
        {
          path: ':id',
          name: 'Transaction',
          beforeEnter: Trans.filterAuth,
          component: load('Transaction'),
          meta: {
            requiresAuth: true,
            requiresUnauth: false,
            layout: 'app'
          },
        },
      ]
    },
    {
      path: 'services',
      name: 'ServicesContainer',
      component: {
        template: "<router-view></router-view>"
      },
      meta: {
        requiresAuth: true,
        requiresUnauth: false,
        layout: 'app'
      },
      children: [
        {
          path: '',
          name: 'Services',
          beforeEnter: Trans.filterAuth,
          component: load('Services'),
          meta: {
            requiresAuth: true,
            requiresUnauth: false,
            layout: 'app'
          },
        },
        {
          path: 'edit/:id',
          name: 'EditService',
          beforeEnter: Trans.filterAuth,
          component: load('EditService'),
          meta: {
            requiresAuth: true,
            requiresUnauth: false,
            layout: 'app'
          },
        },
        {
          path: 'add',
          name: 'NewService',
          beforeEnter: Trans.filterAuth,
          component: load('NewService'),
          meta: {
            requiresAuth: true,
            requiresUnauth: false,
            layout: 'app'
          },
        },
      ]
    },
    {
      path: 'logs',
      name: 'LogsContainer',
      component: {
        template: "<router-view></router-view>"
      },
      meta: {
        requiresAuth: true,
        requiresUnauth: false,
        layout: 'app'
      },
      children: [

        {
          path: '',
          name: 'Logs',
          beforeEnter: Trans.filterAuth,
          component: load('Logs'),
          meta: {
            requiresAuth: true,
            requiresUnauth: false,
            layout: 'app'
          },
        },
        {
          path: 'labkey/:id',
          name: 'Log',
          beforeEnter: Trans.filterAuth,
          component: load('Log'),
          meta: {
            requiresAuth: true,
            requiresUnauth: false,
            layout: 'app'
          },
        },
        {
          path: 'system/:id',
          name: 'SystemLog',
          beforeEnter: Trans.filterAuth,
          component: load('SystemLog'),
          meta: {
            requiresAuth: true,
            requiresUnauth: false,
            layout: 'app'
          },
        },
      ]
    },

  ]
},
{
  path: '/:pathMatch(.*)*',
  redirect() {
    return Trans.defaultLocale
  }
}
]
let router
try {

  router = createRouter({
    history: createWebHistory(), //process.env.BASE_URL
    linkExactActiveClass: 'active',
    routes,
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { left: 0, top: 0 };
      }
    },
  })
} catch (e) {
  console.log(e)
}

router.beforeEach((to, from, next) => {
  if (to?.meta?.layout == 'auth') {
    store.dispatch('setMainLayout', 'auth');
  } else {
    store.dispatch('setMainLayout', 'app');
  }
  next(true);
});

router.afterEach((to, from, next) => {
  appSetting.changeAnimation();
});

export default router