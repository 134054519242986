import getters from "./getters"
import actions from "./actions"
import mutations from "./mutations"
export default {
  state: {
    logs: [],
    log: {},
    systemLog: {},
  },
  getters,
  mutations,
  actions,
}