import router from '@/router';
import { Trans } from '@/modules/Translation'
import { postRequest, getRequest, encodeHash256, encodeBase64 } from '@/modules/routines.cjs'
export default {
    async login(context, payload, loader = true) {

        return new Promise(async (resolve, reject) => {
            if (loader) context.dispatch('loaderPresent');
            payload.password = await encodeHash256(payload.password);
            postRequest(`${process.env.VUE_APP_SERVER_API_PATH}login`, { ...payload }, (e) => {
                if (!e || !e.auth) {
                    if (loader) context.dispatch('loaderDismiss');
                    reject(false);
                }
                else {
                    context.commit('setUserSession', { ...e.tokens });
                    if (loader) context.dispatch('loaderDismiss');
                    resolve(true)
                }
            }, () => {
                if (loader) context.dispatch('loaderDismiss');
                reject(false)
            },
                {
                    headers: {
                        Authorization: `Basic ${await encodeBase64(`${payload.username}:${payload.password}`)}`
                    }
                },
                false
            )
        })
    },
    async autoLogin(context) {
        return new Promise((resolve, reject) => {
            const tokens = context.getters.userSession;
            const { accessToken, refreshToken } = tokens;
            if (accessToken !== undefined && accessToken !== "" && refreshToken !== undefined && refreshToken !== "") {
                getRequest(`${process.env.VUE_APP_SERVER_API_PATH}check-token/accessToken`, null, (e) => {
                    if (e.error_code) reject(false);
                    else resolve(true)
                }, () => {
                    reject(false)
                })
            } else {
                reject(false);
            }
        })
    },
    async logout(context) {

        return new Promise((resolve, reject) => {

            postRequest(`${process.env.VUE_APP_SERVER_API_PATH}logout`, {}, (e) => {
                context.dispatch('localLogout');
                router.replace(Trans.i18nRoute({ name: 'Login' }));
                resolve(true)
            }, (e) => {
                context.dispatch('localLogout');
                router.replace(Trans.i18nRoute({ name: 'Login' }));
                resolve(false)
            },
                null,
                false
            )

        })
    },
    async localLogout(context) {
        context.dispatch('loaderPresent');
        context.commit('unsetUserSession');
        localStorage.removeItem('tokens');
    }

}