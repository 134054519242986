<template>
  <span>
    <RegistrationForm />
    <div
      v-if="false"
      class="my-4 text-center text-xs text-white-dark dark:text-white-dark/70"
    >
      OR
    </div>
    <ExternalProviders v-if="false" />

    <div class="p-5 border-t border-[#ebe9f1] dark:border-white/10 text-sm">
      <p class="text-center text-white-dark dark:text-white-dark/70">
        Already have
        <a
          href="javascript:;"
          class="text-[#515365] hover:underline dark:text-white-dark"
          >Login?</a
        >
      </p>
    </div>
  </span>
</template>

<script>
import RegistrationForm from "./RegistrationForm.vue";
import ExternalProviders from "./ExternalProviders.vue";
export default {
  components: { RegistrationForm, ExternalProviders },
};
</script>

<style>
</style>