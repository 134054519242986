<template>
  <li class="menu nav-item">
    <router-link
      v-if="destination"
      :to="$i18nRoute({ name: destination })"
      class="nav-link group"
      @click="toggleMobileMenu"
    >
      <div class="flex items-center">
        <Icon
          :type="icon.iconType ?? 'svg'"
          :name="icon.name"
          class-name="ltr:mr-2 rtl:ml-2"
        />
        <span
          class="ltr:pl-3 rtl:pr-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark"
          >{{ t(localeKey) }}</span
        >
      </div>
    </router-link>
    <a
      v-else
      class="nav-link group cursor-pointer"
      @click="
        () => {
          callback();
          toggleMobileMenu();
        }
      "
    >
      <div class="flex items-center">
        <Icon
          :type="icon.iconType ?? 'svg'"
          :name="icon.name"
          class-name="ltr:mr-2 rtl:ml-2"
        />
        <span
          class="ltr:pl-3 rtl:pr-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark"
          >{{ t(localeKey) }}</span
        >
      </div>
    </a>
  </li>
</template>

<script>
import { i18n } from "@/i18n";
import store from "@/store";
export default {
  props: {
    localeKey: String,
    destination: String,
    callback: Function,
    icon: {
      iconType: {
        type: String,
        default: "svg",
      },
      name: {
        type: String,
        default: "",
      },
      default: () => {
        return {
          iconType: "svg",
          name: "",
        };
      },
    },
  },
  setup() {
    const { t } = i18n.global;
    const toggleMobileMenu = (e) => {
      if (window.innerWidth < 1024) {
        store.dispatch("toggleSidebar");
      }
    };
    return {
      t,
      toggleMobileMenu,
    };
  },
};
</script>
<style>
</style>