import { i18n } from '../i18n'
import axios from 'axios'
import store from '@/store'
import router from '../router'
const Trans = {
    get defaultLocale() {
        return process.env.VUE_APP_I18N_LOCALE
    },
    get supportedLocales() {
        return process.env.VUE_APP_I18N_SUPPORTED_LOCALES.split(',')
    },
    get currentLocale() {
        if (typeof i18n.global.locale === 'string') return i18n.global.locale
        else return i18n.global.locale.value
    },
    set currentLocale(locale) {
        if (typeof i18n.global.locale === 'string') i18n.global.locale = locale
        else i18n.global.locale.value = locale
    },
    async changeLocale(locale) {
        if (!Trans.isLocaleSupported(locale)) return Promise.reject(
            new Error('Locale not supported')
        )
        if (i18n.global.locale.value === locale) return Promise.resolve(locale)
        return Trans.loadLocalFile(locale).then(msgs => {
            i18n.global.setLocaleMessage(locale, msgs.default || msgs)
            return Trans.setI18nLocaleServices(locale)
        })
    },
    isLocaleSupported(locale) {
        return Trans.supportedLocales.includes(locale)
    },
    getLocaleFolder(locale) {
        switch (locale) {
            case 'it': return 'it-IT'
            default: return 'en-EN'
        }

    },
    loadLocalFile(locale) {
        let folder = Trans.getLocaleFolder(locale)
        return import(`@/locales/${folder}/${locale}.json`)
    },
    setI18nLocaleServices(locale) {
        Trans.currentLocale = locale;
        axios.defaults.headers.common['Accept-language'] = locale
        document.querySelector('html').setAttribute('lang', locale)
        return locale
    },
    routeMiddleware(to, _, next) {
        const locale = to.params.locale;
        if (!Trans.isLocaleSupported(locale)) return next(Trans.getUserSupportedLocale())
        return Trans.changeLocale(locale).then(() => next())
    },
    async filterAuth(to, from, next) {
        store.dispatch('closeDetailsPanel');
        const lastVisitedPage = to.name;
        const AUTH_REDIRECT = process.env.VUE_APP_AUTH_REDIRECT
        const UNAUTH_REDIRECT = process.env.VUE_APP_UNAUTH_REDIRECT
        store.commit("restoreUserSession");
        if (from.name === UNAUTH_REDIRECT) store.dispatch("retrieveMenu");
        if (lastVisitedPage !== UNAUTH_REDIRECT) localStorage.setItem('lastVisitedPage', lastVisitedPage);
        if (!store.getters.isLoggedIn) {
            try {

                await store.dispatch('autoLogin');
                if (to.meta.requiresAuth && !store.getters.isLoggedIn) {
                    next(false)
                    return router.replace(Trans.i18nRoute({ name: UNAUTH_REDIRECT }))
                } else if (to.meta.requiresUnauth && store.getters.isLoggedIn) {
                    next(false)
                    return router.replace(Trans.i18nRoute({ name: AUTH_REDIRECT }))
                } else {
                    return next()
                }
            } catch (e) {
                if (lastVisitedPage === UNAUTH_REDIRECT) return next();
                else {
                    next(false)
                    return router.replace(Trans.i18nRoute({ name: UNAUTH_REDIRECT }))
                }
            }
        } else {
            return next()
        }
    },
    i18nRoute(to) {
        return {
            ...to,
            params: { locale: Trans.currentLocale, ...to.params }
        }
    },
    getUserSupportedLocale() {
        const userPreferredLocale = Trans.getUserLocale()
        if (Trans.isLocaleSupported(userPreferredLocale.locale)) return userPreferredLocale.locale;
        if (Trans.isLocaleSupported(userPreferredLocale.localeNoISO)) return userPreferredLocale.localeNoISO;
        return Trans.defaultLocale;
    },
    getUserLocale() {
        const locale = Trans.defaultLocale; // da disattivare per il multi-language 
        //const locale = window.navigator.language || window.navigator.userLanguage || Trans.defaultLocale; // da riattivare per il multi-language  
        return {
            locale: locale,
            localeNoISO: locale.split('-')[0]
        }
    }
}

export { Trans }