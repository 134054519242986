import { createStore } from 'vuex'
import auth from './auth'
import menu from './menu'
import layout from './layout'
import breadcrumbs from './breadcrumbs'
import notifications from './notifications'
import user from './user'
import plan from './plan'
import logs from './logs'
import getters from "./getters"
import actions from "./actions"
import mutations from "./mutations"
export default createStore({
  namespaced: true,
  state: {
    userId: null,
    serial: null,
    errors: [],
    isLoading: true,
    loaderTextEnabled: false,
    loaderText: 'root.spinnerText',
    loaderType: 'loader',// loader, sunRaysLoader, dualRingLoader, ballLoader, infinityLoader, pulseLoader, spinnerLoader, eclipseLoader, spinLoader, rollingLoader
    loaderColor: 'blue', // blue, red, green, purple, lightBlue, darkGray
    loaderSize: 'm',
    loaderThemeColor: true,
    colorMode: null,
    showEditPanel: false,
    editingField: {},
    selectedField: '',
    fieldEditMode: false,
    activeTableRow: {},
    logoText: 'The Spaze',
  },
  getters,
  mutations,
  actions,
  modules: {
    auth,
    menu,
    layout,
    breadcrumbs,
    notifications,
    user,
    plan,
    logs,
  }
})
