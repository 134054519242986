export default {
    toggleLoader(state) {
        state.isLoading = !state.isLoading
    },
    setLoading(state, payload) {
        state.isLoading = payload.loading
    },
    setLoginData(state, payload) {
        state.userId = payload.userId,
            state.serial = payload.serial
    },
    setLoaderText(state, payload) {
        state.loaderText = payload.text
    },
    setLoaderType(state, payload) {
        state.loaderType = payload.type
    },
    setLoaderColor(state, payload) {
        state.loaderColor = payload.color
    },
    setLoaderSize(state, payload) {
        state.loaderSize = payload.size
    },
    toggleLoaderText(state) {
        state.loaderTextEnabled = !state.loaderTextEnabled
    },
    setLoaderTextEnableStatus(state, payload) {
        state.loaderTextEnabled = payload.enabled
    },
    setLoaderThemeColor(state, status) {
        state.loaderThemeColor = status;
    },
    setError(state, payload) {
        state.errors.push(payload.error)
    },
    unsetError(state, payload) {
        state.errors.splice(payload.index, 1)
    },
    clearErrors(state) {
        state.errors = []
    },
    setColorMode(state, payload) {
        state.colorMode = payload.colorMode
    },
    setEditPanelStatus(state, payload) {
        state.showEditPanel = payload.status;
    },
    setEditingField(state, payload) {
        state.editingField = payload.data;
    },
    setEditingFieldSections(state, payload) {
        state.editingField.sections = payload.data;
    },
    setSelectedField(state, payload) {
        state.selectedField = payload.field;
    },
    setFieldEditMode(state, payload) {
        state.fieldEditMode = payload.editing;
    },
}