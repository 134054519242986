<template>
  <header :class="{ dark: semidark }">
    <div class="shadow-sm">
      <TopBar />
    </div>
  </header>
</template>

<script>
import { onMounted, computed, watch } from "vue";
import { i18n } from "@/i18n";

import appSetting from "@/app-setting";

import { useRoute } from "vue-router";

import store from "@/store";
export default {
  setup() {
    const semidark = computed(() => store.getters.getSemidarkStatus);
    const menuOrientation = computed(() => store.getters.getMenuOrientation);

    const route = useRoute();
    const { t } = i18n.global;

    onMounted(() => {
      setActiveDropdown();
    });

    watch(route, (to, from) => {
      setActiveDropdown();
    });

    const setActiveDropdown = () => {
      const selector = document.querySelector(
        'ul.horizontal-menu a[href="' + window.location.pathname + '"]'
      );
      if (selector) {
        selector.classList.add("active");
        const all = document.querySelectorAll(
          "ul.horizontal-menu .nav-link.active"
        );
        for (let i = 0; i < all.length; i++) {
          all[0]?.classList.remove("active");
        }
        const ul = selector.closest("ul.sub-menu");
        if (ul) {
          let ele = ul.closest("li.menu").querySelectorAll(".nav-link");
          if (ele) {
            ele = ele[0];
            setTimeout(() => {
              ele?.classList.add("active");
            });
          }
        }
      }
    };

    return {
      semidark,
      menuOrientation,
      t,
    };
  },
};
</script>
