<template>
  <span>
    <LoginForm />
    <div
      v-if="false"
      class="my-4 text-center text-xs text-white-dark dark:text-white-dark/70"
    >
      OR
    </div>
    <ExternalProviders v-if="false" />
    <div
      class="p-5 border-t border-[#ebe9f1] dark:border-white/10 text-sm"
      v-if="false"
    >
      <p class="text-center text-white-dark dark:text-white-dark/70">
        Looking to
        <a
          href="javascript:;"
          class="text-[#515365] hover:underline dark:text-white-dark"
          >create an account?</a
        >
      </p>
    </div>
  </span>
</template>

<script>
import LoginForm from "./LoginForm.vue";
import ExternalProviders from "./ExternalProviders.vue";
export default {
  components: { LoginForm, ExternalProviders },
};
</script>

<style>
</style>