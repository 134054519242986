import getters from "./getters"
import actions from "./actions"
import mutations from "./mutations"
export default {
  state: {
    isDarkMode: false,
    mainLayout: 'app',
    theme: 'light',
    menuOrientation: 'vertical',
    layout: 'full',
    rtlClass: 'ltr',
    animation: '',
    navbar: 'navbar-sticky',
    locale: 'en',
    sidebar: false,
    languageList: [
      { code: 'en', name: 'English' },
      { code: 'it', name: 'Italian' },
    ],
    semidark: false,
    componentsDefaultColor: 'blue',
  },
  getters,
  mutations,
  actions
}

