<template>
  <div class="p-5">
    <iframe :src="url" class="w-full h-[250px] md:h-[550px]"></iframe>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>
</style>