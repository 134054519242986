
import { getRequest, patchRequest, postRequest, deleteRequest } from '@/modules/routines.cjs';
export default {

    retrievePlans(context, loader = false) {
        return new Promise((resolve, reject) => {

            if (loader) context.dispatch('loaderPresent');
            getRequest(`${process.env.VUE_APP_SERVER_API_PATH}packages`, null, (e) => {
                context.commit('setPlans', { plans: e })
                if (loader) context.dispatch('loaderDismiss');
                resolve(true)
            }, () => {
                if (loader) context.dispatch('loaderDismiss');
                reject(false)
            })
        })
    },

    async retrieveServices(context, loader = false) {
        return new Promise((resolve, reject) => {
            if (loader) context.dispatch('loaderPresent');
            getRequest(`${process.env.VUE_APP_SERVER_API_PATH}services`, null, (e) => {
                context.commit('setServices', { services: e })
                if (loader) context.dispatch('loaderDismiss');
                resolve(true)
            }, () => {
                if (loader) context.dispatch('loaderDismiss');
                reject(false)
            })
        })
    },
    retrieveTransactions(context, loader = false) {
        return new Promise((resolve, reject) => {

            if (loader) context.dispatch('loaderPresent');
            getRequest(`${process.env.VUE_APP_SERVER_API_PATH}transactions`, null, (e) => {
                context.commit('setTransactions', { transactions: e })
                if (loader) context.dispatch('loaderDismiss');
                resolve(true)
            }, (e) => {
                if (loader) context.dispatch('loaderDismiss');
                reject(false)
            })
        })
    },
    retrieveTransactionStatuses(context, loader = false) {
        return new Promise((resolve, reject) => {

            if (loader) context.dispatch('loaderPresent');
            getRequest(`${process.env.VUE_APP_SERVER_API_PATH}transaction-statuses`, null, (e) => {
                context.commit('setTransactionStatuses', { transactionStatuses: e })
                if (loader) context.dispatch('loaderDismiss');
                resolve(true)
            }, () => {
                if (loader) context.dispatch('loaderDismiss');
                reject(false)
            })
        })
    },
    updateTransactionStatus(context, data, loader = true) {
        return new Promise((resolve, reject) => {
            const { id, status } = data;
            const pathUri = status === 'REFUND' ? `transaction/${id}/refund` : `transaction/${id}`;
            if (loader) context.dispatch('loaderPresent');
            let fd = null;
            let body;
            if (status !== 'REFUND') {
                fd = new FormData();
                fd.append("status", status);
                body = {
                    status
                }
            }
            patchRequest(`${process.env.VUE_APP_SERVER_API_PATH}${pathUri}`, null, body, (e) => {
                if (!e) resolve(false);
                else {
                    context.commit('setTransactionStatus', { status })
                    if (loader) context.dispatch('loaderDismiss');
                    resolve(true)
                }
            }, () => {
                if (loader) context.dispatch('loaderDismiss');
                reject(false)
            },
                null,
                true
            )
        })
    },
    updateActiveSubscriptionStartDate(context, data, loader = true) {
        return new Promise((resolve, reject) => {

            const { id, startDate } = data;
            if (!/^(?:\d{2}\/\d{2}\/\d{4}){1}$/gm.test(startDate)) return;
            if (loader) context.dispatch('loaderPresent');
            let body = {
                startDate
            }
            patchRequest(`${process.env.VUE_APP_SERVER_API_PATH}update-active-subscription/${id}`, null, body, (e) => {
                if (!e) {
                    if (loader) context.dispatch('loaderDismiss');
                    resolve(false);
                }
                else {
                    context.commit('setActiveSubscriptionStartDate', { startDate })
                    if (loader) context.dispatch('loaderDismiss');
                    resolve(true)
                }
            }, () => {
                if (loader) context.dispatch('loaderDismiss');
                reject(false)
            },
                null,
                true
            )
        })
    },
    updateInactiveSubscriptionStartDate(context, data, loader = true) {
        return new Promise((resolve, reject) => {

            const { id, startDate } = data;
            if (!/^(?:\d{2}\/\d{2}\/\d{4}){1}$/gm.test(startDate)) return;
            if (loader) context.dispatch('loaderPresent');
            let body = {
                startDate
            }
            patchRequest(`${process.env.VUE_APP_SERVER_API_PATH}update-inactive-subscription/${id}`, null, body, (e) => {
                if (!e) resolve(false);
                else {
                    //context.commit('setInactiveSubscriptionStartDate', { startDate })
                    if (loader) context.dispatch('loaderDismiss');
                    resolve(true)
                }
            }, () => {
                if (loader) context.dispatch('loaderDismiss');
                reject(false)
            },
                null,
                true
            )
        })
    },
    async createPlan(context, plan, loader = true) {
        return new Promise((resolve, reject) => {
            if (loader) context.dispatch('loaderPresent');
            postRequest(`${process.env.VUE_APP_SERVER_API_PATH}plan-complete`, { ...plan }, async (e) => {
                await new Promise((res) => {
                    setTimeout(() => res(true), 500)
                })
                if (!e) resolve(false);
                else {
                    context.commit('addPlan', { id: e, ...plan });
                    if (loader) context.dispatch('loaderDismiss');
                    resolve(true)
                }
            }, () => {
                if (loader) context.dispatch('loaderDismiss');
                reject(false)
            },
                null,
                true
            )
        })
    },
    async createService(context, service, loader = true) {

        return new Promise((resolve, reject) => {
            if (loader) context.dispatch('loaderPresent');
            postRequest(`${process.env.VUE_APP_SERVER_API_PATH}service`, { ...service }, (e) => {
                if (!e) resolve(false);
                else {
                    context.commit('addService', { id: e, ...service });
                    if (loader) context.dispatch('loaderDismiss');
                    resolve(true)
                }
            }, () => {
                if (loader) context.dispatch('loaderDismiss');
                reject(false)
            },
                null,
                true
            )
        })
    },

    async selectPlan(context, id = '') {
        if (!context.getters.plans.length) await context.dispatch("retrievePlans")
        if (context.getters.plans.length) context.commit('setPlan', id);
        else context.commit('setPlan', '');
    },
    async selectTransaction(context, id = '') {
        if (!context.getters.transactions.length) await context.dispatch("retrieveTransactions")
        if (context.getters.transactions.length) context.commit('setTransaction', id);
        else context.commit('setTransaction', '');
    },
    async selectService(context, id = '') {
        if (!context.getters.services.length) await context.dispatch("retrieveServices")
        if (context.getters.services.length) context.commit('setService', id);
        else context.commit('setService', '');
    },
    async editService(context, service, loader = true) {

        return new Promise((resolve, reject) => {
            if (loader) context.dispatch('loaderPresent');
            let fd = new FormData();
            fd.append("service", service.value);
            const id = service.value.id;
            patchRequest(`${process.env.VUE_APP_SERVER_API_PATH}service/${id}`, null, { ...service.value }, (e) => {
                if (!e) resolve(false);
                else {
                    context.commit('editService', service.value)
                    if (loader) context.dispatch('loaderDismiss');
                    resolve(true)
                }
            }, () => {
                if (loader) context.dispatch('loaderDismiss');
                reject(false)
            },
                null,
                true
            )
        })
    },
    async editPlan(context, plan, loader = true) {

        return new Promise((resolve, reject) => {
            if (loader) context.dispatch('loaderPresent');
            const id = plan.value.id;
            if (loader) context.dispatch('loaderDismiss');
            patchRequest(`${process.env.VUE_APP_SERVER_API_PATH}plan-complete/${id}`, null, { ...plan.value }, async (e) => {
                await new Promise((res) => {
                    setTimeout(() => res(true), 500)
                })
                if (!e) resolve(false);
                else {
                    context.commit('editPlan', plan.value);
                    if (loader) context.dispatch('loaderDismiss');
                    resolve(true)
                }
            }, () => {
                if (loader) context.dispatch('loaderDismiss');
                reject(false)
            },
                null,
                true)
        })
    },
    async deleteService(context, id, loader = true) {
        return new Promise((resolve, reject) => {
            if (loader) context.dispatch('loaderPresent');

            deleteRequest(`${process.env.VUE_APP_SERVER_API_PATH}service/${id}`, null, (e) => {
                if (!e) resolve(false);
                else {
                    context.commit("deleteService", id);
                    if (loader) context.dispatch('loaderDismiss');
                    resolve(true)
                }
            }, () => {
                if (loader) context.dispatch('loaderDismiss');
                reject(false)
            },
                null,
                true)
        })
    },
    async deleteServices(context, services, loader = true) {
        return new Promise((resolve, reject) => {
            if (loader) context.dispatch('loaderPresent');

            deleteRequest(`${process.env.VUE_APP_SERVER_API_PATH}services/${JSON.stringify(services)}`, null, (e) => {
                if (!e || !e.length) reject(false);
                else {
                    context.commit("deleteServices", e);
                    if (loader) context.dispatch('loaderDismiss');
                    resolve(true)
                }
            }, () => {
                if (loader) context.dispatch('loaderDismiss');
                reject(false)
            },
                null,
                true)
        })
    },
    async deletePlan(context, id, loader = true) {
        return new Promise((resolve, reject) => {
            if (loader) context.dispatch('loaderPresent');

            deleteRequest(`${process.env.VUE_APP_SERVER_API_PATH}package/${id}`, null, (e) => {
                if (!e) resolve(false);
                else {
                    context.commit("deletePlan", id);
                    if (loader) context.dispatch('loaderDismiss');
                    resolve(true)
                }
            }, () => {
                if (loader) context.dispatch('loaderDismiss');
                reject(false)
            },
                null,
                true)
        })
    },
    async deletePlans(context, plans, loader = true) {
        return new Promise((resolve, reject) => {
            if (loader) context.dispatch('loaderPresent');
            deleteRequest(`${process.env.VUE_APP_SERVER_API_PATH}packages/${JSON.stringify(plans)}`, null, (e) => {
                if (!e || !e.length) reject(false);
                else {
                    context.commit("deletePlans", e);
                    if (loader) context.dispatch('loaderDismiss');
                    resolve(true)
                }
            }, () => {
                if (loader) context.dispatch('loaderDismiss');
                reject(false)
            },
                null,
                true)
        })
    },
}