<template>
  <!-- <span> -->
  <MySelect
    v-if="
      config.type === 'dropdown' ||
      config.type === 'multiple' ||
      config.type === 'multiselect'
    "
    :config="config"
    :data="data"
    v-model:value="innerValue"
    :ref="refsArray"
    @change="config.changeCB"
  />

  <MyTextarea
    v-else-if="config.type === 'textarea'"
    :config="config"
    class="w-full w-max-full"
    :textareaData="data"
    v-model:value="innerValue"
    :ref="refsArray"
    @change="config.changeCB"
  />
  <MyInput
    v-else
    :config="config"
    :data="data"
    v-model:value="innerValue"
    :checked="checked"
    :ref="refsArray"
    @change="emitChangeEvt"
    @file-changed="config.fileCB"
    @regexpError="manageError"
  />
  <!-- </span> -->
</template>

<script>
import store from "@/store";
import { ref, computed, inject, reactive } from "vue";
import { validate } from "@/modules/routines.cjs";
import {
  inputConfig,
  selectConfig,
  textAreaConfig,
  uneditableInputConfigFields,
  uneditableSelectConfigFields,
  uneditableTextareaConfigFields,
  inputData,
  selectData,
  textareaData,
} from "./inputConfig.cjs";
export default {
  emits: ["regexpCompleteError"],
  props: {
    config: {
      type: Object,
      validator(v) {
        const validationObj = {
          id: {
            type: String,
            default: undefined,
          },
          name: {
            type: String,
            default: undefined,
          },
          label: {
            type: String,
            default: "",
          },
          placeholder: {
            type: String,
            default: "",
          },
          type: {
            type: String,
            default: "input", // input, select, textarea
          },
          subtype: {
            // for input: text password, email, url, tel, search, range (if so, min and max become required), radio, checkbox, file, switch
            // for select: dropdown, multiple, multiselect
            // uneffective for textarea
            type: String,
            default: "text",
          },
          ref: {
            type: String,
            default: undefined,
          },
          regExp: {
            type: Object,
            default: reactive(/^[a-zA-Z0-9\s.,!@#$%^&*()_+:;"'=?/|\\-]*$/gm),
          },
          changeCB: {
            type: Function,
            default: () => {},
          },
          fileCB: {
            type: Function,
            default: () => {},
          },
        };
        return validate(v, validationObj);
      },
      default: reactive({}),
    },
    data: {
      type: Object,
      default: reactive({}),
    },
    value: {
      type: [Number, String, Boolean, Array, Object],
      default: reactive(-1),
    },
    checked: {
      type: Boolean,
      default: false,
    },
    errorList: {
      type: Array,
      default: reactive([]),
    },
  },
  emits: ["inputChanged", "update:value", "update:checked", "change"],
  setup(props, ctx) {
    const emit = ctx.emit;
    const loader = ref(store.getters.isLoading);
    const toggleLoader = () => {
      store.dispatch("toggleLoader");
      loader.value = !loader.value;
    };
    const toggleLoaderText = () => {
      store.dispatch("toggleLoaderText");
    };

    const unrollValue = (obj, key) => {
      const keys = key.split(".");
      let currentObj = obj;
      for (const k of keys) {
        if (k.includes("[")) {
          const [arrayKey, arrayIndex] = k.split("[");
          const index = parseInt(arrayIndex.replace("]", ""), 10);
          if (currentObj[arrayKey] === undefined) {
            return undefined; // Array non definito
          }
          currentObj =
            props.config.subtype === "checkbox"
              ? !!currentObj[arrayKey][index]
              : currentObj[arrayKey][index];
        } else {
          if (currentObj[k] === undefined) {
            return undefined; // Chiave non definita
          }
          currentObj = currentObj[k];
        }
        if (currentObj === undefined) {
          return undefined; // Chiave non trovata
        }
      }
      return currentObj;
    };

    const innerValue = computed({
      get() {
        const inValue = unrollValue(props.value, props.config.ref);
        return inValue;
      },
      set(value) {
        emit("update:value", { key: props.config.ref, value });
        if (props.config.subtype === "checkbox")
          emit("update:checked", { key: props.config.ref, value });
      },
    });

    const unrollConfig = (obj, configObj, forbiddenFields = []) => {
      let out = {};
      for (const key in configObj) {
        if (Object.hasOwnProperty.call(configObj, key)) {
          const el =
            Object.hasOwnProperty.call(obj, key) &&
            !forbiddenFields.includes(key)
              ? obj[key]
              : configObj[key];
          out[key] = el;
        }
      }
      return out;
    };

    const config = computed(() => {
      let configObj = {};
      let forbiddenFields = [];
      if (props.config.type === "textarea") {
        configObj = textAreaConfig;
        forbiddenFields = uneditableTextareaConfigFields;
      } else if (props.config.type === "select") {
        configObj = selectConfig[props.config.subtype];
        forbiddenFields = uneditableSelectConfigFields[props.config.subtype];
      } else {
        configObj = inputConfig[props.config.subtype];
        forbiddenFields = uneditableInputConfigFields[props.config.subtype];
      }
      return unrollConfig(props.config, configObj, forbiddenFields);
    });

    const innerChecked = computed({
      get() {
        return props.checked;
      },
      set(value) {
        emit("update:checked", value);
      },
    });

    const options = ref([
      //standard structure
      { value: "id_oowe943nv", text: "Pippo" },
      { value: "id_8usdIKJhnx98", text: "Pluto" },
      { value: "id_omsJJDKF847", text: "Paperino" },
    ]);
    const options2 = ref([
      // isGrouped=true Structure
      {
        groupName: "Group 1",
        list: [
          { value: 1, text: "Orange" },
          { value: 2, text: "Plppo" },
          { value: 3, text: "White" },
          { value: 4, text: "Purple" },
        ],
      },
      {
        groupName: "Group 2",
        list: [
          { value: 5, text: "Yellow" },
          { value: 6, text: "Red" },
          { value: 7, text: "Green" },
        ],
      },
      {
        groupName: "Group 3",
        list: [
          { value: 8, text: "Aqua" },
          { value: 9, text: "Black" },
          { value: 10, text: "Blue" },
        ],
      },
    ]);

    const emitChangeEvt = (e) => {
      emit("change", {
        id: e.target.id,
        name: e.target.name,
        ref: props.config.ref,
        value:
          props.config.subtype === "radio" ? e.target.checked : e.target.value,
        type: props.config.type,
        subtype: props.config.subtype,
      });

      props.config.changeCB(e);
    };
    const refsArray = ref([]);
    const manageError = (e) => {
      emit("regexpCompleteError", { code: e, ref: props.config.ref });
    };
    const isInError = computed(() =>
      props.errorList.includes(props.config.ref)
    );
    return {
      ref,
      innerChecked,
      options,
      options2,
      selectData,
      textareaData,
      inputData,
      config,
      innerValue,
      emitChangeEvt,
      refsArray,
      manageError,
      isInError,
      reactive,
    };
  },
};
</script>

<style>
</style>