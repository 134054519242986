export default {
    loaderPresent(context) {
        context.commit('setLoading', { loading: true })
    },
    loaderDismiss(context) {
        setTimeout(() => {
            context.commit('setLoading', { loading: false })
        }, 400)
    },

    toggleLoader(context) {
        context.commit('toggleLoader')
    },
    changeLoaderText(context, payload) {
        context.commit('setLoaderText', { text: payload })
    },
    toggleLoaderText(context) {
        context.commit('toggleLoaderText')
    },
    enableLoaderText(context) {
        context.commit('setLoaderTextEnableStatus', { enabled: true })
    },
    disableLoaderText(context) {
        context.commit('setLoaderTextEnableStatus', { enabled: false })
    },
    setLoaderText(context, payload) {
        context.commit('setLoaderText', { text: payload })
    },
    setLoaderType(context, payload) {
        context.commit('setLoaderType', { type: payload })
    },
    setLoaderColor(context, payload) {
        context.commit('setLoaderColor', { color: payload })
    },
    enableLoaderThemeColor(context) {
        context.commit('setLoaderThemeColor', true)
    },
    disableLoaderThemeColor(context) {
        context.commit('setLoaderThemeColor', false)
    },
    setLoaderSize(context, payload) {
        context.commit('setLoaderSize', { size: payload })
    },
    setError(context, payload) {
        context.commit('setError', { error: payload })
    },
    unsetError(context, payload) {
        context.commit('unsetError', { index: payload })
    },
    clearErrors(context) {
        context.commit('clearErrors')
    },
    setColorMode(context, payload) {
        context.commit('setColorMode', { colorMode: payload })
    },
    openDetailsPanel(context) {
        context.commit('setEditPanelStatus', { status: true });
    },
    closeDetailsPanel(context) {
        context.commit('setEditPanelStatus', { status: false });
    },
    switchSection(context) {
        let command = "";
        if (context.getters.getDetailsPanelStatus) {
            command = "closeDetailsPanel";
        } else {
            command = "openDetailsPanel";
        }
        context.dispatch(command);
    },
    setEditingField(context, payload) {
        context.commit('setEditingField', { data: payload })
    },
    setSelectedField(context, payload) {
        context.commit('setSelectedField', { field: payload })
    },
    setFieldEditMode(context, payload) {
        context.commit('setFieldEditMode', { editing: payload })
    },
    setActiveTableRow(context, payload) {
        context.commit('setActiveTableRow', payload)
    }
}