import getters from "./getters"
import actions from "./actions"
import mutations from "./mutations"
export default {
    state: {
        loggedIn: false,
        loginData: {
            accessToken: '',
            refreshToken: '',
            timestamp: null,
            expiration: 0,
        }
    },
    getters,
    actions,
    mutations
}