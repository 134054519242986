
import { i18n } from '@/i18n';
import appSetting from '@/app-setting';
export default {
    setMainLayout(context, payload = null) {
        context.commit('setMainLayout', payload); //app , auth
    },
    toggleTheme(context, payload = null) {
        payload = payload || context.getters.getTheme; // light|dark|system
        localStorage.setItem('theme', payload);
        context.commit('setTheme', payload);
        if (payload == 'light') {
            //this.isDarkMode = false;
            context.commit('unsetDarkMode')
        } else if (payload == 'dark') {
            //this.isDarkMode = true;
            context.commit('setDarkMode')
        } else if (payload == 'system') {
            if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                //this.isDarkMode = true;
                context.commit('setDarkMode')
            } else {
                //this.isDarkMode = false;
                context.commit('unsetDarkMode')
            }
        }

        if (context.getters.getDarkModeStatus) {
            document.querySelector('body')?.classList.add('dark');
        } else {
            document.querySelector('body')?.classList.remove('dark');
        }
    },
    toggleMenu(context, payload = null) {
        payload = payload || context.getters.getMenuOrientation; // vertical, collapsible-vertical, horizontal
        context.commit('unsetSidebar')
        //this.sidebar = false; // reset sidebar state
        localStorage.setItem('menu', payload);
        context.commit('setMenuOrientation', payload);
    },
    toggleLayout(context, payload = null) {
        payload = payload || context.getters.getMenuOrientation; // full, boxed-layout
        localStorage.setItem('layout', payload);
        context.commit('setLayout', payload);
    },
    toggleRTL(context, payload = null) {
        payload = payload || context.getters.getRtlClass; // rtl, ltr
        localStorage.setItem('rtlClass', payload);
        context.commit('setRtlClass', payload);
        document.querySelector('html')?.setAttribute('dir', context.getters.getRtlClass || 'ltr');
    },
    toggleAnimation(context, payload = null) {
        payload = payload || context.getters.getThemeAnimation; // animate__fadeIn, animate__fadeInDown, animate__fadeInUp, animate__fadeInLeft, animate__fadeInRight, animate__slideInDown, animate__slideInLeft, animate__slideInRight, animate__zoomIn
        payload = payload?.trim();
        localStorage.setItem('animation', payload);
        context.commit('setAnimation', payload);
        appSetting.changeAnimation();
    },
    toggleNavbar(context, payload = null) {
        payload = payload || context.getters.getThemeNavbar; // navbar-sticky, navbar-floating, navbar-static
        localStorage.setItem('navbar', payload);
        context.commit('setNavbar', payload);
    },
    toggleSemidark(context, payload = null) {
        payload = payload || false;
        localStorage.setItem('semidark', payload);
        context.commit('setSemidarkStatus', payload);
    },
    toggleLocale(context, payload = null) {
        payload = payload || context.getters.getLocale;
        if (typeof i18n.global.locale === 'string') i18n.global.locale = payload;
        else i18n.global.locale.value = payload;
        localStorage.setItem('i18n_locale', payload);
        context.commit('setLocale', payload);
    },
    toggleSidebar(context) {
        context.commit('toggleSidebar');
    },
    setComponentsDefaultColor(context, color) {
        context.commit('setComponentsDefaultColor', color);
    },
}