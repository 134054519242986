export default {
    setSNotificationsCloseButton(state, cbStatus) {
        state.sNotificationsCloseButton = cbStatus;
    },
    setSNotificationsOkButton(state, obStatus) {
        state.sNotificationsOkButton = obStatus;
    },
    setSNotificationsVPosition(state, vPosition) {
        state.sNotificationsVPosition = vPosition;
    },
    setSNotificationsHPosition(state, hPosition) {
        state.sNotificationsHPosition = hPosition;
    },
    setSNotificationsDuration(state, duration) {
        state.sNotificationsDuration = duration;
    },
    setSystemNotificationActiveStatus(state, activeStatus) {
        state.systemNotificationActiveStatus = activeStatus;
    },
    setSystemNotificationMessage(state, msg) {
        state.systemNotificationMessage = msg;
    },
    setSystemNotificationType(state, type) {
        state.systemNotificationType = type;
    },
}