<template>
  <TabList :class="tabListDynamicClass">
    <template v-for="(label, i) in config.labels" :key="`${label.text}_${i}`">
      <Tab as="template" v-slot="{ selected }" :disabled="label.disabled">
        <a
          href="javascript:;"
          :class="[
            'gap-2',
            aTagTabDynamicClass(selected, label.disabled),
            aTagTabDynamicColorClass(selected, label.disabled),
          ]"
        >
          <Icon
            type="svg"
            :name="label.icon"
            :class-name="
              config.tabType === 'circle' ? 'w-6 h-6 !m-0' : 'w-5 h-5'
            "
            v-if="config.hasIcon || config.tabType === 'circle'"
          />
          <template v-if="config.tabType !== 'circle'">{{
            label.text
          }}</template>
          <!-- {{ aTagTabDynamicColorClass(selected, label.disabled) }} -->
        </a>
      </Tab>
    </template>
  </TabList>
</template>

<script>
import store from "@/store";
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue";
import { reactive, computed, ref, watch } from "vue";
import { validate } from "@/modules/routines.cjs";
import Icon from "@/components/baseComponents/Icon.vue";
import { ColorClasses } from "../../ColorClasses";
import {
  backgroundClasses,
  textClasses,
  borderClasses,
  addClass,
} from "@/components/CompoundClasses.js";
export default {
  components: {
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    Icon,
  },
  props: {
    config: {
      type: Object,
      validator(v) {
        const validationObj = {
          labels: {
            type: Array,
            default: reactive([
              { text: "Home", disabled: false, icon: "home" },
              { text: "Profile", disabled: true, icon: "profile" },
              { text: "Contact", disabled: false, icon: "contact" },
            ]),
          },
          isVertical: {
            type: Boolean,
            default: false,
          },
          pillsTabs: {
            type: Boolean,
            default: false,
          },
          tabType: {
            type: String,
            default: "tab", // tab, topLine(isVertical must be equal to false), line (for tabs). tab, circle (for pills)
          },
          borderedPanels: {
            type: Boolean,
            default: false,
          },
          animatedLine: {
            type: Boolean,
            default: false,
          },
          hasIcon: {
            type: Boolean,
            default: false,
          },
          justify: {
            // isVertical must be equal to false for this to work
            type: String,
            default: "left", //left, right, center, full
          },
          themeColor: {
            // if enabled uses the COMPONENTS_DEFAULT_COLOR global variable as default color
            type: Boolean,
            default: false,
          },
          color: {
            // only works if themeColor is equal to false
            type: String,
            default: "blue",
          },
          verticalIcon: {
            // works on pills with icons only
            type: Boolean,
            default: false,
          },
        };
        return validate(v, validationObj);
      },
      default: reactive({}),
    },
  },
  setup(props) {
    const color = ref(
      ColorClasses[
        props.config.themeColor
          ? store.getters.componentsDefaultColor
          : props.config.color
      ]
    );
    watch(
      () => props.config.themeColor,
      (newValue) => {
        color.value =
          ColorClasses[
            newValue ? store.getters.componentsDefaultColor : props.config.color
          ];
      }
    );
    watch(
      () => props.config.color,
      (newValue) => {
        color.value =
          ColorClasses[
            props.config.themeColor
              ? store.getters.componentsDefaultColor
              : newValue
          ];
      }
    );

    watch(
      () => store.getters.componentsDefaultColor,
      (newValue) => {
        color.value =
          ColorClasses[props.config.themeColor ? newValue : props.config.color];
      }
    );
    /* CLASS COMPUTED METHODS */
    const tabListDynamicClass = computed(() => {
      if (props.config.pillsTabs) {
        let outClass = "";
        switch (props.config.tabType) {
          case "circle":
            if (props.config.isVertical)
              return "flex flex-col justify-center items-center space-y-3";
            else
              outClass =
                "flex flex-wrap mt-3 mb-5 space-x-3 rtl:space-x-reverse";
            break;
          default:
            if (props.config.isVertical)
              return "ltr:pr-4 rtl:pl-4 space-y-2 sm:flex-[0_0_20%] mb-5 sm:mb-0";
            else {
              outClass =
                "flex flex-wrap sm:justify-center mt-3 mb-5 sm:space-x-3 rtl:space-x-reverse";
            }
            break;
        }

        if (!props.config.isVertical) {
          switch (props.config.justify) {
            case "center":
              outClass += " flex flex-wrap justify-center";
              break;
            case "right":
              outClass += " flex flex-wrap justify-end";
              break;
            case "full":
              outClass += " flex flex-wrap justify-between text-center";
              break;
            default:
              break;
          }
        }
        return outClass;
      } else {
        let outClass = "";

        switch (props.config.tabType) {
          case "topLine":
            if (props.config.isVertical) return "w-24 m-auto text-center";
            else outClass = "flex flex-wrap mt-3";
            break;
          case "line":
            if (props.config.isVertical)
              return "w-24 m-auto text-center font-semibold";
            else
              outClass =
                "flex flex-wrap mt-3 border-b border-white-light dark:border-[#191e3a]";
            break;
          default:
            if (props.config.isVertical) return "w-24 m-auto text-center";
            else
              outClass =
                "flex flex-wrap mt-3 border-b border-white-light dark:border-[#191e3a]";
            break;
        }

        if (!props.config.isVertical) {
          switch (props.config.justify) {
            case "center":
              outClass += " flex flex-wrap justify-center";
              break;
            case "right":
              outClass += " flex flex-wrap justify-end";
              break;
            case "full":
              outClass += " flex flex-wrap justify-between text-center";
              break;
            default:
              break;
          }
        }
        return outClass;
      }
    });
    const aTagTabDynamicClass = computed(function () {
      return (selected, disabled = false) => {
        if (props.config.pillsTabs) {
          let outClass = "";

          switch (props.config.tabType) {
            case "circle":
              if (props.config.isVertical)
                outClass =
                  "w-16 h-16 flex flex-col items-center justify-center rounded-full bg-[#f1f2f3] dark:bg-[#191e3a] hover:text-white hover:shadow-[0_5px_15px_0_rgba(0,0,0,0.30)] transition-all duration-300 !outline-none" +
                  (selected ? " text-white" : "");
              else
                outClass =
                  "w-16 h-16 flex flex-col items-center justify-center rounded-full bg-[#f1f2f3] dark:bg-[#191e3a] hover:text-white hover:shadow-[0_5px_15px_0_rgba(0,0,0,0.30)] transition-all duration-300 !outline-none" +
                  (selected ? " text-white" : "");
              break;
            default:
              if (props.config.isVertical)
                outClass =
                  "p-3.5 py-2 block rounded-md hover:text-white transition-all duration-300 !outline-none" +
                  (selected ? " text-white" : "");
              else {
                if (props.config.verticalIcon && props.config.hasIcon)
                  outClass =
                    "p-7 py-3 mr-3 flex flex-col items-center justify-center rounded-lg bg-[#f1f2f3] dark:bg-[#191e3a] hover:text-white hover:shadow-[0_5px_15px_0_rgba(0,0,0,0.30)] !outline-none transition duration-300" +
                    (selected ? " text-white" : "");
                else
                  outClass =
                    "p-3.5 py-2 -mb-[1px] ltr:mr-2 rtl:ml-2 block rounded hover:text-white !outline-none transition duration-300" +
                    (selected ? " text-white" : "");
                break;
              }
          }
          if (!props.config.isVertical) {
            switch (props.config.justify) {
              case "center":
                outClass += " block"; /* JUSTIFY CENTER */
                break;
              case "right":
                outClass += " block"; /* JUSTIFY RIGHT */
                break;
              case "full":
                outClass += " flex-auto justify-center"; /* JUSTIFY */
                break;
              default:
                break;
            }
          }

          if (props.config.hasIcon && !props.config.verticalIcon)
            outClass += " flex items-center rounded hover:text-white";
          else if (props.config.hasIcon && props.config.verticalIcon)
            outClass += " flex flex-col items-center rounded hover:text-white";

          if (disabled)
            outClass +=
              " pointer-events-none rounded text-white-light dark:text-dark";
          return outClass;
        } else {
          let outClass = "";

          switch (props.config.tabType) {
            case "topLine":
              if (props.config.isVertical)
                outClass =
                  "p-3.5 py-2 -mb-[1px] block border border-white-light dark:border-[#191e3a] hover:text-white !outline-none transition duration-300" +
                  (selected ? " text-white" : "") /* VERTICAL BORDER */;
              else
                outClass =
                  "p-7 py-3 flex items-center bg-[#f6f7f8] dark:bg-transparent dark:hover:bg-[#191e3a] border-transparent border-t-2 !outline-none transition duration-300" +
                  (selected ? " dark:bg-[#191e3a]" : ""); /* BORDER TOP */
              break;
            case "line":
              if (props.config.isVertical)
                outClass =
                  "p-3.5 py-4 -mb-[1px] block ltr:border-r rtl:border-l border-white-light dark:border-[#191e3a] relative before:transition-all before:duration-700 before:absolute before:w-[1px] before:bottom-0 before:top-0 ltr:before:-right-[1px] rtl:before:-left-[1px] before:m-auto before:h-0 hover:before:h-[80%] !outline-none transition duration-300" +
                  (selected ? " before:!h-[80%]" : "") /* VERTICAL LINE */;
              else
                outClass = props.config.animatedLine
                  ? "p-5 py-3 -mb-[1px] flex items-center relative before:transition-all before:duration-700 before:absolute before:bottom-0 before:w-0 before:left-0 before:right-0 before:m-auto before:h-[1px] !outline-none transition duration-300" +
                    (selected
                      ? " before:!w-full border-b"
                      : " hover:before:w-full")
                  : "p-5 py-3 -mb-[1px] flex items-center hover:border-b border-transparent !outline-none transition duration-300" +
                    (selected ? " border-b" : ""); /* LINE */
              break;
            default:
              if (props.config.isVertical)
                outClass =
                  "p-3.5 py-2 -mb-[1px] block border border-white-light dark:border-[#191e3a] hover:text-white !outline-none transition duration-300" +
                  (selected ? " text-white" : "") /* VERTICAL BORDER */;
              else
                outClass =
                  "p-3.5 py-2 -mb-[1px] block border border-transparent dark:hover:border-b-black !outline-none transition duration-300" +
                  (selected
                    ? " !border-white-light !border-b-white dark:!border-[#191e3a] dark:!border-b-black"
                    : ""); /* SIMPLE TABS */
              break;
          }

          if (!props.config.isVertical) {
            switch (props.config.justify) {
              case "center":
                outClass += " block"; /* JUSTIFY CENTER */
                break;
              case "right":
                outClass += " block"; /* JUSTIFY RIGHT */
                break;
              case "full":
                outClass += " flex-auto justify-center"; /* JUSTIFY */
                break;
              default:
                break;
            }
          }
          if (props.config.hasIcon) outClass += " flex items-center";

          if (disabled)
            outClass += " pointer-events-none text-white-light dark:text-dark";
          return outClass;
        }
      };
    });
    const aTagTabDynamicColorClass = computed(function () {
      return (selected, disabled = false) => {
        const innerColor = color.value;
        if (props.config.pillsTabs) {
          let outClass = {};
          switch (props.config.tabType) {
            case "circle":
              outClass = addClass(
                outClass,
                backgroundClasses,
                "importantHover",
                innerColor,
                selected,
                backgroundClasses,
                "important",
                innerColor
              );
              break;
            default:
              if (props.config.isVertical) {
                outClass = addClass(
                  outClass,
                  backgroundClasses,
                  "hover",
                  innerColor,
                  selected,
                  backgroundClasses,
                  "important",
                  innerColor
                );
              } else {
                if (props.config.verticalIcon && props.config.hasIcon) {
                  outClass = addClass(
                    outClass,
                    backgroundClasses,
                    "importantHover",
                    innerColor
                  );
                } else {
                  outClass = addClass(
                    outClass,
                    backgroundClasses,
                    "hover",
                    innerColor
                  );
                }
                if (selected) {
                  outClass = addClass(
                    outClass,
                    backgroundClasses,
                    "important",
                    innerColor
                  );
                }
              }
              break;
          }
          if (props.config.hasIcon)
            outClass = addClass(
              outClass,
              backgroundClasses,
              "hover",
              innerColor
            );
          return outClass;
        } else {
          let outClass = {};

          switch (props.config.tabType) {
            case "topLine":
              if (props.config.isVertical) {
                outClass = addClass(
                  outClass,
                  backgroundClasses,
                  "hover",
                  innerColor,
                  selected,
                  backgroundClasses,
                  "normal",
                  innerColor
                );
              } else
                outClass = addClass(
                  outClass,
                  borderClasses,
                  "hover",
                  innerColor
                );
              outClass = addClass(outClass, textClasses, "hover", innerColor);
              if (selected) {
                outClass = addClass(
                  outClass,
                  borderClasses,
                  "important",
                  innerColor
                );
                outClass = addClass(
                  outClass,
                  textClasses,
                  "normal",
                  innerColor
                );
              }
              break;
            case "line":
              if (props.config.isVertical) {
                outClass = addClass(outClass, textClasses, "hover", innerColor);
                outClass = addClass(
                  outClass,
                  backgroundClasses,
                  "before",
                  innerColor
                );
                if (selected) {
                  outClass = addClass(
                    outClass,
                    textClasses,
                    "normal",
                    innerColor
                  );
                }
              } else if (props.config.animatedLine) {
                outClass = addClass(outClass, textClasses, "hover", innerColor);
                outClass = addClass(
                  outClass,
                  backgroundClasses,
                  "before",
                  innerColor
                );
                if (selected) {
                  outClass = addClass(
                    outClass,
                    textClasses,
                    "normal",
                    innerColor
                  );
                  outClass = addClass(
                    outClass,
                    borderClasses,
                    "important",
                    innerColor
                  );
                }
              } else {
                outClass = addClass(
                  outClass,
                  borderClasses,
                  "importantHover",
                  innerColor
                );
                outClass = addClass(outClass, textClasses, "hover", innerColor);
                if (selected) {
                  outClass = addClass(
                    outClass,
                    borderClasses,
                    "important",
                    innerColor
                  );
                  outClass = addClass(
                    outClass,
                    textClasses,
                    "normal",
                    innerColor
                  );
                }
              }

              break;
            default:
              if (props.config.isVertical) {
                outClass = addClass(
                  outClass,
                  backgroundClasses,
                  "hover",
                  innerColor
                );
                if (selected) {
                  outClass = addClass(
                    outClass,
                    backgroundClasses,
                    "normal",
                    innerColor
                  );
                }
              } else {
                outClass = addClass(outClass, textClasses, "hover", innerColor);
                if (selected) {
                  outClass = addClass(
                    outClass,
                    textClasses,
                    "normal",
                    innerColor
                  );
                }
              }
              break;
          }

          if (props.config.hasIcon)
            outClass = addClass(outClass, textClasses, "hover", innerColor);

          return outClass;
        }
      };
    });
    /* END OF CLASS COMPUTED METHODS */
    return {
      tabListDynamicClass,
      aTagTabDynamicClass,
      aTagTabDynamicColorClass,
      color,
    };
  },
};
</script>

<style>
</style>

