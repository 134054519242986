import getters from "./getters"
import actions from "./actions"
import mutations from "./mutations"
export default {
    state: {
        sNotificationsCloseButton: true,
        sNotificationsOkButton: false,
        sNotificationsVPosition: 'bottom',
        sNotificationsHPosition: 'left',
        sNotificationsDuration: 3000,
        systemNotificationActiveStatus: false,
        systemNotificationMessage: 'This a system notification!',
        systemNotificationType: "success"

    },
    getters,
    mutations,
    actions
}