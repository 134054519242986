const { fieldsRegex, regexErrors } = require('@/modules/regex');
const inputConfig = Object.freeze({
    text: {
        id: undefined,
        name: undefined,
        label: '',
        placeholder: '',
        type: 'text',
        required: false,
        toTest: true,
        disabled: false,
        readonly: false,
        hasLabel: true,
        hasHelper: false,
        isHorizontal: false,
        isRounded: true,
        hasEmbeddedLabel: false,
        helperType: "default", // default, badge, fullBadge, inline
        color: "blue", // blue, green, purple, red, yellow, lightBlue, darkGray
        themeColor: true,
        size: "m",
        useCustomRegexp: false,
        regExp: fieldsRegex.text,
        ref: '',
        changeCB: () => { },
    },
    number: {
        id: undefined,
        name: undefined,
        label: '',
        placeholder: '',
        type: 'number',
        required: false,
        toTest: true,
        disabled: false,
        readonly: false,
        hasLabel: true,
        hasHelper: false,
        isHorizontal: false,
        isRounded: true,
        hasEmbeddedLabel: false,
        helperType: "default", // default, badge, fullBadge, inline
        color: "blue", // blue, green, purple, red, yellow, lightBlue, darkGray
        themeColor: true,
        size: "m",
        min: '0',
        useCustomRegexp: false,
        regExp: fieldsRegex.number,
        ref: '',
        changeCB: () => { },
    },
    price: {
        id: undefined,
        name: undefined,
        label: '',
        placeholder: '',
        type: 'number',
        required: false,
        toTest: true,
        disabled: false,
        readonly: false,
        hasLabel: true,
        hasHelper: false,
        isHorizontal: false,
        isRounded: true,
        hasEmbeddedLabel: false,
        helperType: "default", // default, badge, fullBadge, inline
        color: "blue", // blue, green, purple, red, yellow, lightBlue, darkGray
        themeColor: true,
        size: "m",
        min: '0',
        step: '0.1',
        useCustomRegexp: true,
        regExp: fieldsRegex.price,
        ref: '',
        changeCB: () => { },
    },
    hidden: {
        id: undefined,
        name: undefined,
        label: '',
        placeholder: '',
        type: 'hidden',
        required: false,
        toTest: true,
        disabled: false,
        readonly: false,
        hasLabel: true,
        hasHelper: false,
        isHorizontal: false,
        isRounded: true,
        hasEmbeddedLabel: false,
        helperType: "default", // default, badge, fullBadge, inline
        color: "blue", // blue, green, purple, red, yellow, lightBlue, darkGray
        themeColor: true,
        size: "m",
        useCustomRegexp: false,
        regExp: fieldsRegex.text,
        ref: '',
        changeCB: () => { },
    },
    password: {
        id: undefined,
        name: undefined,
        label: '',
        placeholder: '',
        type: 'password',
        required: false,
        toTest: true,
        disabled: false,
        readonly: false,
        hasLabel: true,
        hasHelper: false,
        isHorizontal: false,
        isRounded: true,
        hasEmbeddedLabel: false,
        helperType: "default", // default, badge, fullBadge, inline
        color: "blue", // blue, green, purple, red, yellow, lightBlue, darkGray
        themeColor: true,
        size: "m",
        useCustomRegexp: false,
        regExp: fieldsRegex.password,
        ref: '',
        changeCB: () => { },
    },
    email: {
        id: undefined,
        name: undefined,
        label: '',
        placeholder: '',
        type: 'email',
        required: false,
        toTest: true,
        disabled: false,
        readonly: false,
        hasLabel: true,
        hasHelper: false,
        isHorizontal: false,
        isRounded: true,
        hasEmbeddedLabel: false,
        helperType: "default", // default, badge, fullBadge, inline
        color: "blue", // blue, green, purple, red, yellow, lightBlue, darkGray
        themeColor: true,
        size: "m",
        useCustomRegexp: false,
        regExp: fieldsRegex.email,
        ref: '',
        changeCB: () => { },
    },
    url: {
        id: undefined,
        name: undefined,
        label: '',
        placeholder: '',
        type: 'url',
        required: false,
        toTest: true,
        disabled: false,
        readonly: false,
        hasLabel: true,
        hasHelper: false,
        isHorizontal: false,
        isRounded: true,
        hasEmbeddedLabel: false,
        helperType: "default", // default, badge, fullBadge, inline
        color: "blue", // blue, green, purple, red, yellow, lightBlue, darkGray
        themeColor: true,
        size: "m",
        useCustomRegexp: false,
        regExp: fieldsRegex.url,
        ref: '',
        changeCB: () => { },
    },
    tel: {
        id: undefined,
        name: undefined,
        label: '',
        placeholder: '',
        type: 'tel',
        required: false,
        toTest: true,
        disabled: false,
        readonly: false,
        hasLabel: true,
        hasHelper: false,
        isHorizontal: false,
        isRounded: true,
        hasEmbeddedLabel: false,
        helperType: "default", // default, badge, fullBadge, inline
        color: "blue", // blue, green, purple, red, yellow, lightBlue, darkGray
        themeColor: true,
        size: "m",
        useCustomRegexp: false,
        regExp: fieldsRegex.tel,
        ref: '',
        changeCB: () => { },
    },
    search: {
        id: undefined,
        name: undefined,
        label: '',
        placeholder: '',
        type: 'search',
        required: false,
        toTest: true,
        disabled: false,
        readonly: false,
        hasLabel: true,
        hasHelper: false,
        isHorizontal: false,
        isRounded: true,
        hasEmbeddedLabel: false,
        helperType: "default", // default, badge, fullBadge, inline
        color: "blue", // blue, green, purple, red, yellow, lightBlue, darkGray
        themeColor: true,
        size: "m",
        useCustomRegexp: false,
        regExp: fieldsRegex.text,
        ref: '',
        changeCB: () => { },
    },
    range: {
        id: undefined,
        name: undefined,
        label: '',
        placeholder: '',
        type: 'range',
        required: false,
        toTest: true,
        disabled: false,
        readonly: false,
        hasLabel: true,
        hasHelper: false,
        isHorizontal: false,
        isRounded: true,
        hasEmbeddedLabel: false,
        helperType: "default", // default, badge, fullBadge, inline
        color: "blue", // blue, green, purple, red, yellow, lightBlue, darkGray
        themeColor: true,
        size: "m",
        min: 0,
        max: 1400,
        useCustomRegexp: false,
        regExp: fieldsRegex.number,
        ref: '',
        changeCB: () => { },
    },
    radio: {
        id: undefined,
        name: undefined,
        label: '',
        placeholder: '',
        type: 'radio',
        required: false,
        toTest: true,
        disabled: false,
        readonly: false,
        hasLabel: true,
        hasHelper: false,
        isHorizontal: false,
        isRounded: true,
        hasEmbeddedLabel: false,
        helperType: "default", // default, badge, fullBadge, inline
        color: "blue", // blue, green, purple, red, yellow, lightBlue, darkGray
        themeColor: true,
        size: "m",
        checked: false,
        cAndRType: "default", //default, inverted, outline, invertedOutline
        cAndRColoredLabel: false,
        ref: '',
        changeCB: () => { },
    },
    checkbox: {
        id: undefined,
        name: undefined,
        label: '',
        placeholder: '',
        type: 'checkbox',
        required: false,
        toTest: true,
        disabled: false,
        readonly: false,
        hasLabel: true,
        hasHelper: false,
        isHorizontal: false,
        isRounded: true,
        hasEmbeddedLabel: false,
        helperType: "default", // default, badge, fullBadge, inline
        color: "blue", // blue, green, purple, red, yellow, lightBlue, darkGray
        themeColor: true,
        size: "m",
        checked: false,
        returnsInitialValue: false,
        cAndRType: "default", //default, inverted, outline, invertedOutline
        cAndRColoredLabel: false,
        ref: '',
        changeCB: () => { },
    },
    file: {
        id: undefined,
        name: undefined,
        label: '',
        placeholder: '',
        type: 'file',
        required: false,
        toTest: true,
        disabled: false,
        readonly: false,
        hasLabel: true,
        hasHelper: false,
        isHorizontal: false,
        isRounded: true,
        hasEmbeddedLabel: false,
        helperType: "default", // default, badge, fullBadge, inline
        color: "blue", // blue, green, purple, red, yellow, lightBlue, darkGray
        themeColor: true,
        size: "m",
        ref: '',
        fileCB: () => { },
    },
    switch: {
        id: undefined,
        name: undefined,
        label: '',
        placeholder: '',
        type: 'switch',
        required: false,
        toTest: true,
        disabled: false,
        readonly: false,
        hasLabel: true,
        hasHelper: false,
        isHorizontal: false,
        isRounded: true,
        hasEmbeddedLabel: false,
        helperType: "default", // default, badge, fullBadge, inline
        color: "blue", // blue, green, purple, red, yellow, lightBlue, darkGray
        themeColor: true,
        size: "m",
        checked: false,
        cAndRType: "default", //default, inverted, outline, invertedOutline
        cAndRColoredLabel: false,
        ref: '',
        changeCB: () => { },
    },
})


const inputData = Object.freeze({
    text: {
        label: '',
        placeholder: '',
        helperText: "Hello! I am an helper text",
        embeddedLabel: "",
        value: "",
        errorText: regexErrors.text,
    },
    number: {
        label: '',
        placeholder: 0,
        helperText: "Hello! I am an helper text",
        embeddedLabel: "",
        value: 0,
        min: 0,
        errorText: regexErrors.number,
    },
    price: {
        label: '',
        placeholder: 0,
        helperText: "Hello! I am an helper text",
        embeddedLabel: "",
        value: 0,
        min: 0,
        errorText: regexErrors.price,
    },
    hidden: {
        label: '',
        placeholder: '',
        helperText: "Hello! I am an hidden helper text",
        embeddedLabel: "",
        value: "",
    },
    password: {
        label: '',
        placeholder: '',
        helperText: "Hello! I am an helper text",
        embeddedLabel: "",
        value: "",
        errorText: regexErrors.password,
    },
    email: {
        label: '',
        placeholder: '',
        helperText: "Hello! I am an helper text",
        embeddedLabel: "",
        value: "",
        errorText: regexErrors.email,
    },
    url: {
        label: '',
        placeholder: '',
        helperText: "Hello! I am an helper text",
        embeddedLabel: "",
        value: "",
        errorText: regexErrors.url,
    },
    tel: {
        label: '',
        placeholder: '',
        helperText: "Hello! I am an helper text",
        embeddedLabel: "",
        value: "",
        errorText: regexErrors.tel,
    },
    search: {
        label: '',
        placeholder: '',
        helperText: "Hello! I am an helper text",
        embeddedLabel: "",
        value: "",
        errorText: regexErrors.text,
    },
    range: {
        label: '',
        placeholder: '',
        helperText: "Hello! I am an helper text",
        embeddedLabel: "",
        value: "",
        min: 0,
        max: 1000,
    },
    radio: {
        label: '',
        placeholder: '',
        helperText: "Hello! I am an helper text",
        embeddedLabel: "",
        value: "On",
        checked: false,
    },
    checkbox: {
        label: '',
        placeholder: '',
        helperText: "Hello! I am an helper text",
        embeddedLabel: "",
        value: "",
        initialValue: "",
        checked: false,
    },
    file: {
        label: '',
        placeholder: '',
        helperText: "Hello! I am an helper text",
        embeddedLabel: "",
        value: "",
    },
    switch: {
        label: '',
        placeholder: '',
        helperText: "Hello! I am an helper text",
        embeddedLabel: "",
        value: "",
    },
})

const selectConfig = Object.freeze({
    dropdown: {
        id: undefined,
        name: undefined,
        label: '',
        placeholder: '',
        type: 'dropdown',
        size: "m",
        required: false,
        hasLabel: true,
        selectableDefault: false,
        disabled: false,
        isMultiple: false,
        changeCB: () => { },
    },
    multiple: {
        id: undefined,
        name: undefined,
        label: '',
        placeholder: '',
        type: 'multiple',
        size: "m",
        required: false,
        hasLabel: true,
        selectableDefault: false,
        disabled: false,
        isMultiple: true,
        changeCB: () => { },
    },
    multiselect: {
        id: undefined,
        name: undefined,
        label: '',
        placeholder: '',
        type: 'multiselect',
        size: "m",
        required: false,
        hasLabel: true,
        selectableDefault: false,
        disabled: false,
        isGrouped: false,
        isMultiple: true,
        isSearchable: false,
        atLeastOneSelected: true,
        changeCB: () => { },
    }
})

const selectData = Object.freeze({
    dropdown: {
        label: '',
        placeholder: '',
        options: [],
    },
    multiple: {
        label: '',
        placeholder: '',
        options: [],
    },
    multiselect: {
        label: '',
        placeholder: '',
        options: [],
        selectedLabel: "Selected",
        selectLabel: "",
        deselectLabel: "",
    }
})

const textAreaConfig = Object.freeze({
    hasLabel: true,
    required: false,
    rows: 3,
    type: 'textarea',
    changeCB: () => { },
})

const uneditableInputConfigFields = Object.freeze({
    text: ["type"],
    number: ["type"],
    price: ["type"],
    hidden: ["type"],
    password: ["type"],
    email: ["type"],
    url: ["type"],
    tel: ["type"],
    search: ["type"],
    range: ["type"],
    radio: ["type"],
    checkbox: ["type"],
    file: ["type"],
    switch: ["type"],

});
const uneditableSelectConfigFields = Object.freeze({
    dropdown: ["type", "isMultiple"],
    multiple: ["type", "isMultiple"],
    multiselect: ["type", "isMultiple"],
});
const uneditableTextareaConfigFields = ["type"];
const textareaData = Object.freeze({
    label: '',
    placeholder: '',
})

module.exports = {

    inputConfig,
    selectConfig,
    textAreaConfig,
    uneditableInputConfigFields,
    uneditableSelectConfigFields,
    uneditableTextareaConfigFields,
    inputData,
    selectData,
    textareaData,
}




