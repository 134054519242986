export default {
    setUserSession(state, payload) {
        state.loginData.accessToken = payload.accessToken;
        state.loginData.refreshToken = payload.refreshToken;
        state.loginData.timestamp = payload.timestamp;
        state.loginData.expiration = payload.expiration;
        state.loggedIn = true;
        if (localStorage.tokens) localStorage.removeItem("tokens")
        localStorage.setItem("tokens", JSON.stringify({ ...payload, loggedIn: true }));
    },
    unsetUserSession(state) {
        state.loginData.accessToken = '';
        state.loginData.refreshToken = '';
        state.loginData.timestamp = null;
        state.loginData.expiration = 0;
        state.loggedIn = false;
        localStorage.removeItem("tokens");
    },

    restoreUserSession(state) {
        if (state.loginData.accessToken === '') {
            const tokens = JSON.parse(localStorage.getItem("tokens"));
            if (tokens) {
                if (!!tokens.accessToken) state.loginData.accessToken = tokens.accessToken;
                if (!!tokens.refreshToken) state.loginData.refreshToken = tokens.refreshToken;
                if (!!tokens.timestamp) state.loginData.timestamp = tokens.timestamp;
                if (!!tokens.expiration) state.loginData.expiration = tokens.expiration;
                if (!!tokens.accessToken && tokens.accessToken !== '') state.loggedIn = true;
            }
        }
        if (!state.loginData.accessToken || state.loginData.accessToken === '') {
            state.loginData.accessToken = '';
            state.loginData.refreshToken = '';
            state.loginData.timestamp = null;
            state.loginData.expiration = 0;
            state.loggedIn = false;
            localStorage.removeItem("tokens");
        }
        return {
            accessToken: state.loginData.accessToken,
            refreshToken: state.loginData.refreshToken,
            timestamp: state.loginData.timestamp,
            expiration: state.loginData.expiration,
            loggedIn: state.loggedIn,
        }
    },
}