<template>
  <div
    v-show="isMainLoaderShown"
    @click="toggleLoader"
    class="screen_loader fixed inset-0 bg-[#fafafa] dark:bg-[#060818] z-[60] grid place-content-center animate__animated"
  >
    <Loader loading :config="loaderConfig" />
  </div>
</template>

<script>
import Loader from "./baseComponents/Loader.vue";
import store from "@/store";
import { computed, ref } from "vue";
export default {
  components: {
    Loader,
  },
  setup() {
    const isMainLoaderShown = computed(() => store.getters.isLoading);
    const isLoaderTextEnabled = computed(
      () => store.getters.isLoaderTextEnabled
    );

    const loaderText = computed(() => store.getters.loaderText);
    const loaderType = computed(() => store.getters.loaderType);
    const loaderColor = computed(() =>
      store.getters.loaderThemeColor
        ? store.getters.componentsDefaultColor
        : store.getters.loaderColor
    );
    const loaderSize = computed(() => store.getters.loaderSize);
    const toggleLoader = () => {
      //TO REMOVE ONCE IN PRODUCTION
      store.dispatch("toggleLoader");
    };
    const loaderConfig = ref({
      themeColor: false, // if enabled uses the COMPONENTS_DEFAULT_COLOR global variable as default color
      color: loaderColor, // only works if themeColor is equal to false -- TO REMOVE FROM THIS FILE
      hasText: isLoaderTextEnabled,
      type: loaderType, // loader, sunRaysLoader, dualRingLoader, ballLoader, infinityLoader, pulseLoader, spinnerLoader, eclipseLoader, spinLoader, rollingLoader
      loaderText: loaderText,
      size: loaderSize,
    });

    return {
      toggleLoader,
      isMainLoaderShown,
      loaderConfig,
    };
  },
};
</script>

<style>
</style>