<template>
  <span>
    <!-- {{ tabsConfig }} -->
    <MyWizard
      :config="wizardConfig"
      :data="wizardData"
      @stepChanged="stepChanged"
      @wizardCompleted="wizardCompleted"
    >
      <template v-slot:home> </template>
      <template v-slot:about
        >Questo è il contenuto dello step 'about' del Wizard</template
      >
      <template v-slot:success
        >Questo è il contenuto dello step 'success' del Wizard</template
      >
    </MyWizard>
  </span>
</template>

<script>
import {
  inputDefaultTypes,
  selectDefaultTypes,
  textareaDefaultTypes,
  typeToDefault,
} from "./defaultTypes.js";
import { reactive, computed, ref, watch } from "vue";
import { validate } from "@/modules/routines.cjs";
export default {
  props: {
    /* new config */

    wizardConfig: {
      type: Object,
      validator(v) {
        const validationObj = {
          type: {
            type: String,
            default: "icon", // icon, text, textAndIcon
          },
          shape: {
            type: String,
            default: "square", // square, pills, circle
          },
          color: {
            type: String,
            default: "blue",
          },
          themeColor: {
            type: Boolean,
            default: false,
          },
          nextButtonText: {
            type: String,
            default: "Next",
          },
          backButtonText: {
            type: String,
            default: "Back",
          },
          finishButtonText: {
            type: String,
            default: "Finish",
          },
          size: {
            type: String,
            default: "m", // xs, s, m, l
          },
          disableBackOnClickStep: {
            type: Boolean,
            default: false,
          },
          disableBack: {
            type: Boolean,
            default: false,
          },
        };
        return validate(v, validationObj);
      },
      default: reactive({}),
    },

    wizardData: {
      type: Object,
      validator(v) {
        const validationObj = {
          steps: {
            type: Array,
            default: [
              {
                icon: "home",
                text: "Home",
                name: "home",
                subtitle: "This is a home subtitle test",
              },
              {
                icon: "user",
                text: "About",
                name: "about",
                subtitle: "This is an about subtitle test",
              },
              {
                icon: "thumbUp",
                text: "Success",
                name: "success",
                subtitle: "This is a success subtitle test",
              },
            ],
          },
        };
        return validate(v, validationObj);
      },
      default: reactive({}),
    },
    stepChanged: {
      type: Function,
      default: () => {},
    },
    wizardCompleted: {
      type: Function,
      default: () => {},
    },

    /* end of new config */
    config: {
      type: Object,
      validator(v) {
        const validationObj = {
          tabsConfig: {
            type: Object,
            default: {
              labels: [
                {
                  text: "Summary",
                  disabled: false,
                  icon: "profile",
                  sectionData: {
                    type: "single",
                    title: "Personal Data",
                    sectionSetup: {},
                    details: {
                      slot1: [
                        {
                          name: "name",
                          id: "name",
                          label: "Name",
                          placeholder: "Insert the name here...",
                          type: "input",
                          subtype: "text",
                          ref: "name",
                        },
                        {
                          name: "surname",
                          id: "surname",
                          label: "Surame",
                          placeholder: "Insert the surname here...",
                          type: "input",
                          subtype: "text",
                          ref: "surname",
                        },
                      ],
                    },
                  },
                },
                {
                  text: "Cellphones",
                  disabled: false,
                  icon: "contact",

                  sectionData: {
                    type: "multiple",
                    title: "Cellphone Data",
                    sectionSetup: {},
                    details: {
                      slot1: [
                        {
                          name: "cellphone",
                          id: "cellphone",
                          label: "Cellphone",
                          placeholder: "Insert the cellphone here",
                          type: "input",
                          subtype: "tel",
                          ref: "cellphones[].cellphone",
                        },
                        {
                          name: "isDefault",
                          id: "isDefault",
                          label: "Is Default",
                          placeholder: "",
                          type: "input",
                          subtype: "checkbox",
                          ref: "cellphones[].isDefault",
                        },
                      ],
                    },
                  },
                },
                {
                  text: "Emails",
                  disabled: false,
                  icon: "mailboxoutline",

                  sectionData: {
                    type: "multiple",
                    title: "Email Data",
                    sectionSetup: {},
                    details: {
                      slot1: [
                        {
                          name: "email",
                          id: "email",
                          label: "Email",
                          placeholder: "Insert the email here",
                          type: "input",
                          subtype: "email",
                          ref: "emails[].email",
                        },
                        {
                          name: "isDefault",
                          id: "isDefault",
                          label: "Is Default",
                          placeholder: "",
                          type: "input",
                          subtype: "checkbox",
                          ref: "emails[].isDefault",
                        },
                      ],
                    },
                  },
                },
              ],
              themeColor: true, // if enabled uses the COMPONENTS_DEFAULT_COLOR global variable as default color
              color: "purple", // only works if themeColor is equal to false
              isVertical: false,
              pillsTabs: false,
              tabType: "line", // tab, topLine(isVertical must be equal to false), line (for tabs). tab, circle (for pills)
              animatedLine: true, // only works for line tab type
              borderedPanels: false,
              hasIcon: true,
              justify: "left", // isVertical must be equal to false for this to work. Possible values: left, right, center, full
              verticalIcon: false,
              unpaddedContent: true,
            },
          },
        };
        return validate(v, validationObj);
      },
      default: reactive({}),
    },

    value: {
      type: Object,
      default: () => {},
    },
    buttonConfig: {
      type: Object,
      validator(v) {
        const validationObj = {
          submitCb: {
            type: Function,
            default: null,
          },
          text: {
            type: String,
            default: "Save",
          },
        };
        return validate(v, validationObj);
      },
      default: reactive({}),
    },
  },
  setup(props, ctx) {
    const emit = ctx.emit;
    let configs = [];
    let unrolledObj = reactive({});
    let toAdd = ref({});
    watch(unrolledObj, (nv) => {
      emit("update:value", nv);
    });
    const innerValue = computed({
      get() {
        props.config.tabsConfig.labels.forEach((label) => {
          Object.values(label.sectionData.details).forEach((el) => {
            configs.push(
              ...el.map((detail) => {
                return {
                  ref: detail.ref,
                  type: detail.type,
                  subtype: detail.subtype,
                };
              })
            );
          });
        });
        configs.forEach((conf) => {
          if (props.value)
            unrollObjWithValue(
              conf,
              unrolledObj,
              conf.type,
              conf.subtype,
              props.value
            );
          else unrollObj(conf, unrolledObj, conf.type, conf.subtype);
        });
        return unrolledObj;
      },
      set(value) {
        unrolledObj = value;
      },
    });

    const unrollObjWithValue = (checker, out, type, subtype, value) => {
      let key = typeof checker === "string" ? checker : checker.ref;
      if (value === undefined || value === null) return "";
      if (key === "") return value;
      if (!/[(\[\])*(.)*]/gm.test(key)) {
        out[key] =
          subtype === "checkbox" || subtype === "radio"
            ? !!value[key]
            : value[key];
        return out;
      }
      const fsPosition = key.indexOf(".");
      const spPosition = key.indexOf("[");
      if (fsPosition <= spPosition) {
        const fsArray = key.split(".");
        const firstKey = fsArray[0];
        const newKey = fsArray.slice(1).join(".");
        if (typeof checker === "object") {
          checker.ref = newKey;
        } else {
          checker = newKey;
        }
        if (/[(\[\])*(.)*]/gm.test(key)) {
          unrollObjWithValue(
            checker,
            out[firstKey],
            type,
            subtype,
            value[firstKey]
          );
          return out;
        } else {
          out[key] = value[key];
          return out;
        }
      } else {
        if (key.indexOf("[") === 0) {
          const fsArray = key.split("[");
          let newKey = fsArray.slice(1).join("[");
          const newKeyArray = newKey.split("]");
          const idx = newKeyArray[0];
          newKey = newKeyArray.slice(1).join("]");
          if (/[(\[\])*(.)*]/gm.test(newKey)) {
            if (newKey.charAt(0) === ".") newKey = newKey.slice(1);
            if (typeof checker === "object") {
              checker.ref = newKey;
            } else {
              checker = newKey;
            }

            if (!out[idx]) out[idx] = {};
            unrollObjWithValue(checker, out[idx], type, subtype, value[idx]);
            return out;
          } else {
            console.log({ out, newKey, value });
          }
        } else {
          const fsArray = key.split("[");
          const firstKey = fsArray[0];
          let newKey = "[" + fsArray.slice(1).join("[");

          if (typeof checker === "object") {
            checker.ref = newKey;
          } else {
            checker = newKey;
          }
          if (!out[firstKey]) {
            out[firstKey] = [];
          }

          unrollObjWithValue(
            checker,
            out[firstKey],
            type,
            subtype,
            value[firstKey]
          );
          return out;
        }
        return out;
      }
    };

    const unrollAux = (key, obj, type, subtype) => {
      if (!/[(\[\])*]/gm.test(key))
        return unrollObj(
          key.charAt(0) === "." ? key.slice(1) : key,
          obj,
          type,
          subtype
        );
      const firstArr = key.split("[");
      const secondArr = firstArr[1].split("]");
      const len1 = firstArr.length;
      const len2 = secondArr.length;

      let firstKey = firstArr[0];
      let idx = secondArr[0];

      let firstCheck = len1 === 2;
      let secondCheck = len2 <= 2;

      let newKey =
        firstCheck && secondCheck
          ? secondArr[1]
          : !firstCheck && secondCheck
          ? firstArr.slice(1).join("[").split("]")[1]
          : firstCheck && !secondCheck
          ? firstArr[1].split("]").slice(1).join("]")
          : firstArr.slice(1).join("[").split("]").slice(1).join("]");
      newKey = newKey.charAt(0) === "." ? newKey.slice(1) : newKey;
      if (!obj[firstKey]) obj[firstKey] = [];
      if (!obj[firstKey][idx]) obj[firstKey][idx] = {};
      if (/[(\[\])*(.)*]/gm.test(newKey)) {
        if (/[(.)*]/gm.test(newKey)) {
          obj[firstKey][idx] = unrollObj(newKey, obj, type, subtype);
        } else {
          obj[firstKey][idx] = unrollAux(newKey, obj, type, subtype);
        }
      } else {
        obj[firstKey][idx][newKey] = getDefaultValue(type, subtype);
      }
      return obj;
    };

    const unrollObj = (checker, out, type, subtype) => {
      let key = typeof checker === "string" ? checker : checker.ref;
      if (key === "") return getDefaultValue(type, subtype);
      if (!/[(\[\])*(.)*]/gm.test(key)) {
        out[key] = getDefaultValue(type, subtype);
        return out;
      }
      let arr;
      if (/[(.)*]/gm.test(key)) {
        arr = key.split(".");
        if (
          arr.length == 1 ||
          (arr.length == 2 && (arr[0] === "" || arr[1] === ""))
        ) {
          let newKey = arr.join("");
          out[newKey] = getDefaultValue(type, subtype);
          return out;
        } else {
          let innerKey = arr[0];
          arr = arr.slice(1).join(".");
          if (/[(\[\])*]/gm.test(key)) {
            return unrollAux(key, out, type, subtype);
          } else {
            out[innerKey] = unrollObj(arr, out, type, subtype);
            return out;
          }
        }
      }
      if (/[(\[\])*]/gm.test(key)) {
        return unrollAux(key, out, type, subtype);
      }
    };

    const getDefaultValue = (type = "input", subtype = "text") => {
      let outType;
      switch (type) {
        case "select":
          outType = selectDefaultTypes[subtype];
          break;
        case "textarea":
          outType = textareaDefaultTypes;
          break;
        default:
          outType = inputDefaultTypes[subtype];
          break;
      }
      return typeToDefault[outType];
    };

    const section = computed(function () {
      return (label) => props.config.sections[label];
    });
    const sectionSetup = computed(function () {
      return (label) => props.config.sections[label].sectionSetup;
    });
    const sectionName = (label) =>
      props.config.sections[label].sectionSetup.name;
    const log = (msg) => console.log(msg);

    const setUnrolledAux = (obj, key, val) => {
      if (!/[(\[\])*]/gm.test(key))
        return setUnrolled(
          obj,
          key.charAt(0) === "." ? key.slice(1) : key,
          val
        );
      const firstArr = key.split("[");
      const secondArr = firstArr[1].split("]");
      const len1 = firstArr.length;
      const len2 = secondArr.length;

      let firstKey = firstArr[0];
      let idx = secondArr[0];

      let firstCheck = len1 === 2;
      let secondCheck = len2 <= 2;

      let newKey =
        firstCheck && secondCheck
          ? secondArr[1]
          : !firstCheck && secondCheck
          ? firstArr.slice(1).join("[").split("]")[1]
          : firstCheck && !secondCheck
          ? firstArr[1].split("]").slice(1).join("]")
          : firstArr.slice(1).join("[").split("]").slice(1).join("]");
      newKey = newKey.charAt(0) === "." ? newKey.slice(1) : newKey;
      if (!obj[firstKey]) obj[firstKey] = [];
      if (!obj[firstKey][idx]) obj[firstKey][idx] = {};
      if (/[(\[\])*(.)*]/gm.test(newKey)) {
        if (/[(.)*]/gm.test(newKey)) {
          obj[firstKey][idx] = setUnrolled(newKey, obj, val);
        } else {
          obj[firstKey][idx] = setUnrolledAux(newKey, obj, val);
        }
      } else {
        obj[firstKey][idx][newKey] = val;
      }
    };
    const setUnrolled = (obj, key, val) => {
      if (key === "") return val;
      if (!/[(\[\])*(.)*]/gm.test(key)) return (obj[key] = val);
      let arr;
      if (/[(.)*]/gm.test(key)) {
        arr = key.split(".");
        if (
          arr.length == 1 ||
          (arr.length == 2 && (arr[0] === "" || arr[1] === ""))
        ) {
          let newKey = arr.join("");

          return (obj[newKey] = val);
        } else {
          arr = arr.slice(1).join(".");
          if (/[(\[\])*]/gm.test(key)) return setUnrolledAux(obj, key, val);
          else return setUnrolled(obj, arr, val);
        }
      }
      if (/[(\[\])*]/gm.test(key)) return setUnrolledAux(obj, key, val);
    };
    const buttonConfig = computed(function () {
      return (slotName, slots, tab) => {
        return {
          type: "default",
          themeColor: true,
          disabled: false,
          outline: false,
          hasIcon: true,
          icon: "Add",
          iconPosition: "solo",
          fullWidth: false,
          size: "m",
          callback: function () {
            const toPush = [];
            Object.values(slots).forEach((slot) => {
              const idx = parseInt(slot.id.split(`${slot.name}-`)[1]);
              if (!!idx) return;
              const obj = {};
              for (const key in slot) {
                if (Object.hasOwnProperty.call(slot, key)) {
                  const el = slot[key];
                  if (key === "id" || key === "value") {
                    obj[key] = el.replace(idx, "");
                  } else if (key === "checked") {
                    obj[key] = false;
                  } else {
                    obj[key] = el;
                  }
                }
              }
              toPush.push(obj);
            });
            const idx = Object.values(slots).length / toPush.length;
            toPush.forEach((el, i) => {
              let refArr = el.ref.split(`[0]`);
              const len = refArr.length;
              el.ref =
                len > 2
                  ? refArr.slice(0, len - 1).join(`[0]`) +
                    `[${idx}]` +
                    refArr.slice(len - 1)
                  : refArr.join(`[${idx}]`);
              if (el.id && el.id !== "") el.id += idx;
              if (el.value && el.value !== "") el.value += idx;
            });
            toAdd.value = {
              tabId: tab.id,
              slotName,
              elements: toPush,
            };
          },
        };
      };
    });

    const tabsConfig = computed(() => {
      if (!toAdd.value || !Object.keys(toAdd.value).length)
        return props.config.tabsConfig;
      else {
        const { tabId, slotName, elements } = toAdd.value;
        props.config.tabsConfig.labels.forEach((label) => {
          if (label.id === tabId) {
            elements.forEach((el) => {
              label.sectionData.details[slotName].push(el);
              setUnrolled(unrolledObj, el.ref, "");
            });
          }
        });

        return props.config.tabsConfig;
      }
    });

    const fgButtonConfig = computed(() => props.buttonConfig);

    /* HERE STARTS THE WIZARD SECTION */
    const wizardConfig = ref({
      type: "textAndIcon", // icon, text, textAndIcon
      shape: "square", // square, pills, circle
      color: "blue",
      themeColor: false,
      nextButtonText: "Next",
      backButtonText: "Back",
      finishButtonText: "Finish",
      size: "l", // xs, s, m, l
      disableBackOnClickStep: true,
      disableBack: true,
    });
    const wizardData = ref({
      steps: [
        {
          icon: "home",
          text: "Home",
          name: "home",
          subtitle: "This is a home subtitle test",
        },
        {
          icon: "user",
          text: "About",
          name: "about",
          subtitle: "This is an about subtitle test",
        },
        {
          icon: "thumbUp",
          text: "Success",
          name: "success",
          subtitle: "This is a success subtitle test",
        },
      ],
    });
    const wizardChangeManage = (prev, next) => {
      console.log(`Managing change from ${prev} to ${next}`);
    };
    const wizardCompleteManage = () => {
      console.log("Managing completion step");
    };

    return {
      section,
      sectionSetup,
      sectionName,
      log,
      unrolledObj,
      buttonConfig,
      fgButtonConfig,
      tabsConfig,
      innerValue,
      wizardConfig,
      wizardData,
      wizardChangeManage,
      wizardCompleteManage,
    };
  },
};
</script>

<style>
</style>