<template>
  <form-wizard
    :shape="shape"
    :color="color"
    :class="wizardClass"
    :nextButtonText="config.nextButtonText"
    :backButtonText="config.backButtonText"
    :finishButtonText="config.finishButtonText"
    :disableBackOnClickStep="config.disableBackOnClickStep"
    :disableBack="config.disableBack"
    :stepSize="stepSize"
    @on-change="manageChange"
    @on-complete="manageComplete"
  >
    <!-- @beforeTabSwitch="() => false" -->
    <!-- @on-validate="() => false" -->
    <tab-content
      v-for="step in data.steps"
      :key="step.name"
      :customIcon="getCustomIcon(step)"
      :title="getCustomTitle(step)"
      :subtitle="getCustomSubtitle(step)"
      :beforeChange="
        () => (step.beforeChange !== undefined ? step.beforeChange() : true)
      "
    >
      <slot :name="step.name"></slot>
    </tab-content>
  </form-wizard>
</template>

<script>
import store from "@/store";
import { getSvg } from "@/modules/templateRoutines.cjs";
import { reactive, computed, ref, watch } from "vue";
import { validate } from "@/modules/routines.cjs";
import { FormWizard, TabContent } from "vue3-form-wizard";
import "vue3-form-wizard/dist/style.css";
import { ColorHexCodes } from "@/components/ColorClasses";
export default {
  components: { FormWizard, TabContent },
  props: {
    config: {
      type: Object,
      validator(v) {
        const validationObj = {
          type: {
            type: String,
            default: "icon", // icon, text, textAndIcon
          },
          shape: {
            type: String,
            default: "square", // square, pills, circle
          },
          color: {
            type: String,
            default: "blue",
          },
          themeColor: {
            type: Boolean,
            default: false,
          },
          nextButtonText: {
            type: String,
            default: "Next",
          },
          backButtonText: {
            type: String,
            default: "Back",
          },
          finishButtonText: {
            type: String,
            default: "Finish",
          },
          size: {
            type: String,
            default: "m", // xs, s, m, l
          },
          disableBackOnClickStep: {
            type: Boolean,
            default: false,
          },
          disableBack: {
            type: Boolean,
            default: false,
          },
        };
        return validate(v, validationObj);
      },
      default: reactive({}),
    },
    data: {
      type: Object,
      validator(v) {
        const validationObj = {
          steps: {
            type: Array,
            default: [
              {
                icon: "home",
                text: "Home",
                name: "home",
                subtitle: "This is a home subtitle test",
              },
              {
                icon: "useroutline",
                text: "About",
                name: "about",
                subtitle: "This is an about subtitle test",
              },
              {
                icon: "thumbupoutline",
                text: "Success",
                name: "success",
                subtitle: "This is a success subtitle test",
              },
            ],
          },
        };
        return validate(v, validationObj);
      },
      default: reactive({}),
    },
  },
  setup(props, ctx) {
    const color = ref(
      ColorHexCodes[
        props.config.themeColor
          ? store.getters.componentsDefaultColor
          : props.config.color
      ]
    );
    watch(
      () => props.config.themeColor,
      (newValue) => {
        color.value =
          ColorHexCodes[
            newValue ? store.getters.componentsDefaultColor : props.config.color
          ];
      }
    );
    watch(
      () => props.config.color,
      (newValue) => {
        color.value =
          ColorHexCodes[
            props.config.themeColor
              ? store.getters.componentsDefaultColor
              : newValue
          ];
      }
    );

    watch(
      () => store.getters.componentsDefaultColor,
      (newValue) => {
        color.value =
          ColorHexCodes[
            props.config.themeColor ? newValue : props.config.color
          ];
      }
    );

    /* FORM-WIZARD SETUP */
    const shape = computed(() => {
      switch (props.config.shape) {
        case "pills":
          return "tab";
        case "circle":
          return null;
        default:
          return "square";
      }
    });

    const wizardClass = computed(() => {
      return (
        props.config.shape + (props.config.type === "text" ? " text-only" : "")
      );
    });
    /* FORM-WIZARD SETUP */

    const stepSize = computed(() => {
      if (props.config.size === "xs") return props.config.size;
      switch (props.config.size) {
        case "l":
          return "lg";
        case "s":
          return "sm";
        default:
          return "md";
      }
    });
    const getCustomTitle = computed(function () {
      return (step) => {
        const type = props.config.type;
        return type === "text" || type === "textAndIcon"
          ? step.text
          : undefined;
      };
    });
    const getCustomSubtitle = computed(function () {
      return (step) => {
        const type = props.config.type;
        return type === "text" || type === "textAndIcon"
          ? step.subtitle
          : undefined;
      };
    });

    const getCustomIcon = computed(function () {
      return (step) => {
        const type = props.config.type;
        if (type !== "icon" && type !== "textAndIcon") return null;
        return getSvg({ name: step.icon });
      };
    });
    const value = ref(null);

    const emit = ctx.emit;
    const manageChange = (previous, next) => {
      emit("stepChanged", previous, next);
    };
    const manageComplete = () => {
      emit("wizardCompleted");
    };

    return {
      color,
      shape,
      wizardClass,
      getCustomTitle,
      getCustomIcon,
      getCustomSubtitle,
      stepSize,
      manageChange,
      manageComplete,
      value,
    };
  },
};
</script>

<style>
</style>