export default {
    plans(state) {
        return state.plans;
    },
    plan(state) {
        return state.plan;
    },
    services(state) {
        return state.services;
    },
    service(state) {
        return state.service;
    },
    transactions(state) {
        return state.transactions;
    },
    transaction(state) {
        return state.transaction;
    },
    transactionStatuses(state) {
        return state.transactionStatuses;
    },
}