<template>
  <div
    class="relative bg-white flex w-full items-center px-5 py-2.5 dark:bg-[#0e1726]"
  >
    <div
      class="horizontal-logo flex lg:hidden justify-between items-center ltr:mr-2 rtl:ml-2"
    >
      <router-link
        :to="$i18nRoute({ name: 'Transactions' })"
        class="main-logo flex items-center shrink-0"
      >
        <img
          class="w-8 ltr:-ml-1 rtl:-mr-1 inline"
          src="/assets/images/logo.svg"
          alt=""
        />
        <span
          class="text-2xl ltr:ml-1.5 rtl:mr-1.5 font-semibold align-middle hidden md:inline dark:text-white-light transition-all duration-300"
          >{{ logoText }}</span
        >
      </router-link>

      <a
        href="javascript:;"
        class="collapse-icon flex-none dark:text-[#d0d2d6] hover:text-primary dark:hover:text-primary flex lg:hidden ltr:ml-2 rtl:mr-2 p-2 rounded-full bg-white-light/40 dark:bg-dark/40 hover:bg-white-light/90 dark:hover:bg-dark/60"
        @click="toggleSidebar()"
      >
        <Icon type="svg" name="menuIcon" />
      </a>
    </div>
    <div
      class="flex-1 ltr:ml-0 ltr:ml-auto rtl:mr-0 rtl:mr-auto flex justify-center space-x-1.5 lg:space-x-2 rtl:space-x-reverse dark:text-[#d0d2d6]"
    >
      <span
        class="text-2xl ltr:ml-1.5 rtl:mr-1.5 font-semibold align-middle hidden md:inline dark:text-white-light transition-all duration-300"
        >{{ logoText }}</span
      >
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import store from "@/store";
import { i18n } from "@/i18n";
import SearchBar from "./topbarComponents/SearchBar.vue";

export default {
  components: { SearchBar },
  setup() {
    const logoText = computed(() => store.getters.logoText);
    const languageList = computed(() => store.getters.getLanguagesList);
    const rtlClass = computed(() => store.getters.getRtlClass);
    const theme = computed(() => store.getters.getTheme);
    const toggleTheme = (theme) => store.dispatch("toggleTheme", theme);
    const toggleSidebar = () => store.dispatch("toggleSidebar");

    return {
      rtlClass,
      languageList,
      theme,
      i18n,
      logoText,
      toggleSidebar,
      toggleTheme,
    };
  },
};
</script>

<style>
</style>