<template>
  <DialogPanel
    class="overflow-hidden w-full"
    :class="`${dialogClassFromModalType} ${widthClass} ${animationClass}`"
  >
    <CloseButton
      v-model:modal="modalValue"
      :modal-type="config.modalType"
      v-if="config.modalType !== 'standardCustom'"
    />
    <div
      v-if="hasTitle"
      class="text-lg ltr:pl-5 rtl:pr-5 ltr:pr-[50px] rtl:pl-[50px]"
      :class="titleClass"
    >
      {{ title }}
    </div>
    <div
      v-else-if="config.modalType === 'standardCustom'"
      class="flex items-center justify-center dark:text-white-dark/70 text-base font-medium text-[#1f2937] p-5"
    >
      <div
        class="flex items-center justify-center w-16 h-16 rounded-full bg-[#f1f2f3] dark:bg-white/10"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="w-7 h-7"
        >
          <path
            d="M19.0001 9.7041V9C19.0001 5.13401 15.8661 2 12.0001 2C8.13407 2 5.00006 5.13401 5.00006 9V9.7041C5.00006 10.5491 4.74995 11.3752 4.28123 12.0783L3.13263 13.8012C2.08349 15.3749 2.88442 17.5139 4.70913 18.0116C9.48258 19.3134 14.5175 19.3134 19.291 18.0116C21.1157 17.5139 21.9166 15.3749 20.8675 13.8012L19.7189 12.0783C19.2502 11.3752 19.0001 10.5491 19.0001 9.7041Z"
            stroke="currentColor"
            stroke-width="1.5"
          ></path>
          <path
            opacity="0.5"
            d="M7.5 19C8.15503 20.7478 9.92246 22 12 22C14.0775 22 15.845 20.7478 16.5 19"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
          ></path>
        </svg>
      </div>
    </div>
    <VideoPlayer
      :url="modalData.url"
      v-if="config.modalType === 'videoPlayer'"
    />
    <Login v-else-if="config.modalType === 'login'"></Login>
    <Registration v-else-if="config.modalType === 'register'"></Registration>
    <Slider
      v-else-if="config.modalType === 'slider'"
      :carousel-pics="modalData.carouselPics"
    ></Slider>
    <Profile v-else-if="config.modalType === 'profile'">
      <template v-slot:modalBody>
        <slot name="modalBody"></slot>
      </template>
      <template v-slot:modalButtons>
        <slot name="modalButtons"></slot>
      </template>
    </Profile>
    <template v-else>
      <div class="p-5">
        <div
          class="text-white-dark text-center text-sm"
          v-if="config.modalType === 'standardCustom'"
        >
          <slot name="modalBody"></slot>
        </div>
        <template v-else>
          <slot name="modalBody"></slot>
        </template>
        <div class="flex justify-end items-center mt-8">
          <slot name="modalButtons"></slot>
        </div>
      </div>
    </template>
  </DialogPanel>
</template>

<script>
import store from "@/store";
import { ref, computed } from "vue";
import { DialogPanel } from "@headlessui/vue";
import VideoPlayer from "./VideoPlayer.vue";
import Login from "./login/Login.vue";
import Registration from "./registration/Registration.vue";
import CloseButton from "./CloseButton.vue";
import Profile from "./Profile.vue";
import Slider from "./Slider.vue";
export default {
  components: {
    DialogPanel,
    VideoPlayer,
    Login,
    CloseButton,
    Profile,
    Registration,
    Slider,
  },
  props: {
    config: {
      type: Object,
      default: () => {
        return ref({
          static: {
            type: Boolean,
            default: false,
          },
          verticallyCentered: {
            type: Boolean,
            default: false,
          },
          size: {
            type: String,
            default: "Normal",
          },
          modalType: {
            // can be modal, videoPlayer, login, profile, register, slider, standardCustom
            type: String,
            default: "modal",
          },
          animation: {
            type: Object,
            default: {
              active: true,
              direction: "",
              fades: false,
              slides: false,
              rotates: false,
              zooms: false,
            },
          },
          widthClass: {
            type: String,
            default: "max-w-lg",
          },
        });
      },
    },
    modal: {
      type: Boolean,
      default: false,
    },
    modalData: {
      title: {
        type: String,
        default: "",
      },
      url: {
        type: String,
        default: "",
      },
      carouselPics: {
        type: Array,
        default: () => [],
      },
    },
  },
  setup(props, ctx) {
    const emit = ctx.emit;
    const dialogClassFromModalType = computed(() => {
      switch (props.config.modalType) {
        case "videoPlayer":
          return "relative py-8";
        case "register":
        case "login":
          return "panel border-0 px-4 py-1 rounded-lg text-black dark:text-white-dark";
        default: {
          let retClass =
            "panel border-0 p-0 rounded-lg text-black dark:text-white-dark";
          if (props.config.modalType === "profile")
            retClass += " bg-secondary dark:bg-secondary";
          return retClass;
        }
      }
    });
    const titleClass = computed(() => {
      switch (props.config.modalType) {
        case "register":
        case "slider":
        case "login":
          return "font-semibold py-5";
        default:
          return "font-bold bg-[#fbfbfb] dark:bg-[#121c2c] py-3";
      }
    });
    const title = computed(() => {
      switch (props.config.modalType) {
        case "videoPlayer":
          return "";
        case "login":
          return "Login";
        case "register":
          return "Register";
        default:
          return props.modalData.title;
      }
    });
    const rtlClass = computed(() => store.getters.getRtlClass);
    const widthClass = computed(() => props.config.widthClass);
    const modalValue = computed({
      get() {
        return props.modal;
      },
      set(value) {
        emit("update:modal", value);
      },
    });
    const modalType = computed(() => props.config.modalType);
    const hasTitle = computed(() => {
      return (
        modalType.value != "videoPlayer" &&
        modalType.value != "profile" &&
        modalType.value !== "standardCustom"
      );
    });
    const calculateDirection = (animationType, direction) => {
      if (direction === "") return "";
      switch (direction.toLowerCase()) {
        case "left": {
          if (animationType === "rotate")
            return rtlClass.value === "rtl" ? "DownRight" : "DownLeft";
          else return rtlClass.value === "rtl" ? "Right" : "Left";
        }
        case "right": {
          if (animationType === "rotate")
            return rtlClass.value === "rtl" ? "DownLeft" : "DownRight";
          else return rtlClass.value === "rtl" ? "Left" : "Right";
        }
        default: {
          return direction.charAt(0).toUpperCase() + direction.slice(1);
        }
      }
    };
    const animationClass = computed(() => {
      if (!props.config.animation.active) return "";
      if (
        !props.config.animation.fades &&
        !props.config.animation.slides &&
        !props.config.animation.rotates &&
        !props.config.animation.zooms
      )
        return "";
      let retClass = "animate__animated";
      let composedAnimationClass = "animate__";
      if (props.config.animation.fades) {
        composedAnimationClass +=
          "fadeIn" +
          calculateDirection("fade", props.config.animation.direction);
      } else if (props.config.animation.slides) {
        composedAnimationClass +=
          "slideIn" +
          calculateDirection("slide", props.config.animation.direction);
      } else if (props.config.animation.rotates) {
        composedAnimationClass +=
          "rotateIn" +
          calculateDirection("rotate", props.config.animation.direction);
      } else if (props.config.animation.zooms) {
        composedAnimationClass +=
          "zoomIn" +
          calculateDirection("zoom", props.config.animation.direction);
      }
      if (composedAnimationClass !== "animate__")
        composedAnimationClass = ` ${composedAnimationClass}`;
      return composedAnimationClass !== "animate__"
        ? `${retClass} ${composedAnimationClass}`
        : retClass;
    });
    /* animate__zoomInUp 
animate__slideInUp
animate__slideInDown
animate__fadeInUp
animate__fadeIn
animate__zoomInUp
======================= rotate in left =======================
rtlClass === 'rtl'
? 'animate__rotateInDownRight'
: 'animate__rotateInDownLeft'
======================= rotate in left =======================

======================= fade in left =======================
rtlClass === 'rtl'
? 'animate__fadeInRight'
: 'animate__fadeInLeft'
======================= fade in left =======================

======================= fade in right =======================
rtlClass === 'rtl'
? 'animate__fadeInLeft'
: 'animate__fadeInRight'
======================= fade in right ======================= */
    return {
      modalType,
      hasTitle,
      title,
      modalValue,
      widthClass,
      dialogClassFromModalType,
      titleClass,
      animationClass,
    };
  },
};
</script>

<style>
</style>