
export default {
    setUsers(state, payload) {
        state.users = payload.users
    },
    setUser(state, id) {
        if (id === '') state.user = {};
        else state.user = state.users.filter(user => {
            return user.id === id
        })[0]
    },
    setAssociatedLabkeyBuildings(state, associatedLabkeyBuildings) {
        state.associatedLabkeyBuildings = associatedLabkeyBuildings
    },
    addUser(state, newUser) {
        state.users.push(newUser);
    },
    editUser(state, user) {
        const id = user.id;
        state.users.forEach(innerUser => {
            if (innerUser.id === id) {
                innerUser = { ...user }
            }
        });
    },

    deleteUser(state, id) {
        state.users = state.users.filter(user => user.id !== id)
    },
    deleteUsers(state, users) {
        state.users = state.users.filter(user => !users.includes(user.id))
    }
}